const faqData = [
    {
      category: "Vihara.ai Account Basics",
      questions: [
        {
          question: "Why do I need an account? ",
          answer:
            "To bid on bank-owned properties and certain foreclosure sales, you need an account. Even if an account isn't required for all bids, having one is highly beneficial. It provides access to a dashboard where you can save properties and receive messages, access important due diligence documents, and get notifications on property status.",
        },
        {
          question: "How do I create an account? ",
          answer: `
                    <ul>
                        <li>
                        Click “Sign Up” from the navigation bar on the home page.
                        </li>
                        <li>
                        Enter your information in the form.

                        </li>
                        <li>
                        Check your email for the confirmation and confirm your new account.

                        </li>
                    </ul>
                    `,
        },
        {
          question: "Can I set up a joint account? ",
          answer: `
                    No, you must set up your own account with a unique login. This security measure allows us to track individual buyers effectively.

                    `,
        },
        {
          question: "I forgot my password. What should I do? ",
          answer: `
                    <ul>
                        <li>
                        Click the “Log In” tab from the navigation bar on the home page.

                        </li>
                        <li>
                        Click “Forgot Password?”
                        </li>
                        <li>
                        Enter your email address and submit.
                        </li>
                        <li>
                        Follow the instructions sent to your email.
                        </li>
                       
                    </ul>
                    `,
        },
        {
          question: "Can I cancel or deactivate my account?",
          answer:
            "Yes, you can deactivate your account by emailing trisha@vihara.ai. You can always create a new account in the future.",
        },
        {
          question: "Is there an app I can use? ",
          answer:
            "Yes, you can find us on the iTunes App Store and Google Play Store. Our app supports both iOS and Android.",
        },
        {
          question: "Why isn’t the Vihara.ai app working?",
          answer:
            "If your apps are no longer updating, you might have an old version and need to download the latest one. Please find our most recent version on the iTunes App Store or Google Play Store.",
        },
      ],
    },
    {
      category: "REO Registration Limits",
      questions: [
        {
          question:
            "Why is there a limit on the number of properties I can bid on?",
          answer:
            "At Vihara.ai, our aim is to foster a fair and prosperous marketplace for all participants, empowering users to bid confidently and effectively. Recognizing the potential for overwhelm during the bidding and closing phases, we've implemented limits for some customers to support their success. This includes restricting bids to a maximum of 3 assets at a time, including properties that have been successfully won and are progressing through the contract stage.",
        },
        {
          question: "Can I get approval to bid on more properties?",
          answer:
            "To gain an extra registration chance, you can establish a purchase profile. This will expedite the initial step of the closing process in case you win a bid on a property.",
        },
        {
          question:
            "Will there always be limits on the number of properties I can bid on?",
          answer:
            "No, once you successfully win and close a transaction on a property, your account will be updated to allow you to register for additional properties simultaneously.",
        },
        {
          question:
            "What should I do if I register for a property by mistake and it impacts my limit?",
          answer:
            "We recommend conducting thorough due diligence before registering to bid on any properties. It's essential to register only for assets you are fully prepared to buy. If you mistakenly register for a property, you can email our Buyer Experience Team at trisha@vihara.ai.",
        },
      ],
    },
    {
      category: "REO Match the Bid",
      questions: [
        {
          question:
            'What does it mean when a property is labeled "Match the Bid"?',
          answer: `For properties with a "Match the Bid" icon, the winning bidder has 6 hours to complete their contract information form and proceed with the closing process. During this period, other non-winning bidders can match the winning bid amount and be next in line to purchase the property if the winning bidder fails to meet their obligations within the 6-hour window.`,
        },
        {
          question:
            "Why do I have only 6 hours to complete my contract information form?",
          answer:
            "Time is critical in any real estate transaction, and Vihara.ai sets specific milestones to ensure auctioned properties move into the closing process swiftly. Completing the contract information form is the first step in generating the purchase and sale agreement.",
        },
        {
          question:
            "I'm concerned I might not be able to complete my contract information form within the required timeline. What should I do?",
          answer:
            "Completing the contract information form should take less than 15 minutes. However, you can expedite the process by completing your purchase profile before the auction. If your purchase profile is ready before you win the bid, you can simply select the relevant profile and click through to submit your answers, completing the form in 5 minutes or less.",
        },
        {
          question:
            "I wasn’t the highest bidder in the auction, but I'm still interested in purchasing this property. How can I Match the Bid?",
          answer:
            "For Match the Bid properties, there's still a chance for you to potentially acquire the property! To become a backup bidder after the auction ends, you must commit to matching the winning bid amount and upload the appropriate purchase profile you intend to use if you become the winning bidder. If you haven't yet created a purchase profile when matching a bid, you'll have the opportunity to do so before successfully submitting your Match the Bid request. The first person to complete both steps—agreeing to match the winning bid and selecting the relevant purchase profile—will be first in line to purchase the property if the original winner does not fulfill their obligations within the required timeframe.",
        },
        {
          question:
            "I didn't participate in bidding for this property in the auction, but can I still submit a Match the Bid request for consideration?",
          answer:
            "Unfortunately, no, you will not be eligible to participate in the Match the Bid period. Only participants who registered and bid on the property during the current auction will be invited to take part. We encourage you to always register and bid on properties you're interested in to ensure you have the opportunity to purchase them.",
        },
      ],
    },
    {
      category: "Buying a Bank-Owned Property Online",
      questions: [
        {
          question:
            "How can I participate in an online auction for bank-owned properties?",
          answer:
            "To explore bank-owned properties, click on “Auctions” in the navigation bar. Then choose the “REO Bank Owned” section.  It's advisable to create an account before browsing. This way, you can save properties to your dashboard and register to bid on them once you find one you like. Please note, registration requires having an account.",
        },
        {
          question: "How can I register for an online bank-owned auction? ",
          answer: `
                    To register for an online auction, do the following:
                    <ul>
                    <li>
                    Log in with your email and password.
                    </li>
                    <li>
                    Next, find your property and click on the property photo or link for more information.
                    </li>
                    <li>
                    Click the “Register to Bid” button.
                    </li>
                    <li>
                    Your name and email address will be auto-populated.
                    </li>
                    <li>
                    We will confirm your registration details after your registration is complete. Be sure to check your email to confirm the date and time of the auction.
                    </li>
                </ul>
                    `,
        },
        {
          question:
            "Why am I restricted from having more than one account linked to my phone number?",
          answer:
            "Each account must have a distinct verified phone number to place bids or offers. This measure upholds auction integrity and enhances the security of information exchanged between Vihara.ai and each responsible bidder. For those needing multiple vesting information options for purchases, we have conveniently included the Purchase Profiles tab in the Dashboard, enabling easy creation of multiple profiles.",
        },
        {
          question: "Why does my number need to be verified?",
          answer:
            "We verify your phone number once to safeguard the integrity of our auctions and ensure the authenticity of all our bidders.",
        },
        {
          question: "Will my phone number be used for other purposes?",
          answer:
            "No, your number will never be used for spam purposes, and we will only call if it's necessary to contact you.",
        },
        {
          question: "What is an auction event?",
          answer:
            "An auction event is a scheduled time period during which a group of similar properties are auctioned. To determine if properties belong to the same event, compare the Event ID number found on the Property Details Page. If the first six digits of the Event ID match those of other properties, they are part of the same event. Note that the remaining digits represent the Item number and may vary.",
        },
        {
          question: "What is Direct Offer? ",
          answer: `The Direct Offer is a feature that lets you directly make offers to sellers for certain bank-owned properties outside of an auction. You can find Direct Offer properties under the "Buying Type" tab on the Search Results Page.
                    `,
        },
        {
          question: "What should I do before I bid on a bank-owned property?",
          answer:
            "Before bidding on a bank-owned property, you should conduct due diligence by estimating the property's value and renovation costs, inspecting the physical property, obtaining a title report, and researching the surrounding neighborhood.",
        },
        {
          question: `A property is listed as a “Cash only purchase.” What does that mean?`,
          answer: `A “Cash only purchase” means the property must be paid in full immediately following the auction. The funds for the purchase must come from your bank accounts, securities, or other financial accounts. Some investors use private money from various sources, but regardless of the source, the funds need to be readily available to complete the purchase immediately.`,
        },
        {
          question: "What is the Reserve Price? ",
          answer: `The Reserve Price is the minimum amount a seller will accept. While most properties have a set Reserve Price, there are instances where a winning bidder may be declared even if the Reserve Price isn't met. This happens when a property is sold "subject to" the seller's approval. Occasionally, sellers may choose to sell a property for less than the Reserve Price.`,
        },
        {
          question: "What happens if the Reserve Price is not met?",
          answer: `If the Reserve is not met, the Seller can either:
          <ul>
        <li>
        Decline all bids and re-auction the property
        </li>
        <li>
        Sell the property to the highest bidder. These offers are typically considered “Subject to Seller Approval.”
        </li>
    </ul>
          `,
        },
        {
          question: "Why are the bid increments so high?",
          answer:
            "Bid increments are designed to keep the auction progressing efficiently toward an acceptable price. As the auction nears its end, bid increments decrease to allow bidders more flexibility in placing precise bids.",
        },
        {
          question: "What is counterbidding?",
          answer:
            "Counter bidding is a common practice among major auction houses. Vihara.ai will place counter bids on behalf of the seller to bring the price closer to the Reserve Price, the minimum amount the seller will accept.",
        },
        {
          question: "I won the auction. What should I do next?",
          answer:
            "If you are the highest bidder when an auction ends, you will receive a confirmation email. You must then complete the steps noted in the email to finalize your property purchase.",
        },
        {
          question: "What is a Purchase Profile?",
          answer:
            "A Purchase Profile is an online form that buyers fill out after winning a bank-owned property. It inquires about how they intend to use the property—such as for investment or as a primary residence—and includes a few other questions necessary to complete the purchase. You can create multiple profiles, but make sure to name them in an easily identifiable way for future use.",
        },
        {
          question: "Why does the bid increment change?",
          answer:
            "As the auction nears its end, the increments decrease to give bidders more flexibility in placing precise bids.",
        },
        {
          question: "What is Proxy Bidding and how does it work?",
          answer: `
          <ul>
        <li>
        Proxy bid is a feature for bank-owned properties that lets you place a bid before the auction begins. After you submit your bid from the Property Details Page, Vihara.ai will bid on your behalf during the live auction.
        </li>
        <li>
        You can set proxy bids on multiple properties in advance. Once the auctions conclude, we will notify you via email if you are the winner.
        </li>
        </ul>
    `,
        },
        {
          question: "Do Sellers accept bids that are below the Reserve Price?",
          answer:
            "Yes, the seller might consider bids below the Reserve Price, so we recommend placing your highest and best bid in each auction",
        },
        {
          question: "Is the Opening Bid also the Reserve?",
          answer:
            "The Opening Bid is the starting amount for bidding; it does not represent the Reserve and may not reflect the seller's acceptable offer price.",
        },
        {
          question: "Why is the auction time being extended?",
          answer:
            "Bids placed within the last 2 minutes extend the auction, ensuring fairness by allowing all bidders to place their final bids.",
        },
        {
          question: "Can I use a mortgage?",
          answer: `Typically, you are not allowed to use a mortgage. Most properties on Vihara.ai  are “Cash only” which means the property must be paid in full by the closing date.`,
        },
        {
          question: "Can I use financing to purchase the property?",
          answer:
            "Usually, no. Please review the property listing to confirm financing options. Most properties on Vihara.ai are cash-only, requiring full payment by the closing date",
        },
        {
          question:
            "Am I allowed to purchase a property with multiple separate entities?",
          answer:
            "Yes, you can enter up to three entities into your contract. To streamline the process, you can pre-enter your entities ahead of time, ensuring they are pre-populated into your contract when you win an auction in the future.",
        },
      ],
    },
    {
      category: "Buying a Foreclosure for Sale",
      questions: [
        {
          question: "How can I participate in a foreclosure auction?",
          answer: `
          On the Vihara.ai website, you can explore foreclosure properties by navigating to the “Auctions” tab in the top navigation bar and selecting “Foreclosure Sales” from the dropdown menu. Once you find a foreclosure property of interest, save it to your dashboard and follow these steps:
                <ul>
                    <li>
                    Conduct thorough due diligence.
                    </li>
                    <li>
                    Prepare your funds as properties at foreclosure sales often require full payment on the auction day. Check your state's specific requirements.
                    </li>
                    <li>
                    Research the auction venue to confirm location and timing.
                    </li>
                    </ul>

                    Through the mobile app, you may have the option to electronically bid on select properties before the auction or participate in real-time bidding. All remote bidders must prequalify beforehand. To do so:
                <ul>
                    <li>
                    Find a property and conduct your due diligence.
                    </li>
                    <li>On the property details page or in the app's Settings, click on the “Qualify for a Remote Bid” button.
                    </li>
                    <li>
                    Complete the Bidder Profile and Remote Bid agreements using DocuSign within the app.
                    </li>
                    <li>
                    Upon approval, you will receive an email with instructions on funding your escrow account.
                    </li>
                    <li>
                    To place a bid, ensure your funds are available in your account by 2 p.m. (EST) the day before the auction.
                    </li>
                </ul>

          `,
        },
        {
          question: "Do I have to pay the property in full at the auction? ",
          answer:
            "Typically, foreclosure sales require full payment on the auction day using cash or a cashier’s check. Verify your state’s guidelines to determine if partial payment at the auction with the remainder due within a specified period, like 30 days, is permitted.",
        },
        {
          question: "Are these properties free of liens? ",
          answer:
            "It varies. Certain properties may have existing liens, and specific state laws dictate whether these liens transfer to the new owner. It's essential to conduct thorough due diligence by obtaining a comprehensive title report for any property you intend to purchase.",
        },
        {
          question: "Why was this property sale canceled or postponed?",
          answer: `
          There are various reasons why a foreclosure sale might be delayed or canceled. When you save a property to your dashboard, you typically receive an email notification if the sale is postponed or canceled. You can also check the website before heading to the auction. Sales may be postponed or canceled due to reasons such as:
            <ul>
                <li>
                Agreement between the homeowner and lender to postpone the sale.
                </li>
                <li>
                Bankruptcy filed by the homeowner, which often results in cancellation until the debt is resolved or the lender obtains court approval to proceed.
                </li>
                <li>
                The lender is selling the property before the auction date.
                </li>
                </ul>
          `,
        },
        {
          question:
            "How do I know if a sale is canceled or postponed before I attend the auction?",
          answer:
            "As you browse properties, save the ones that interest you to your dashboard. You'll usually receive an email notification if there are any changes such as postponement or cancellation of the sale. It's also advisable to check the property status on the property details page before heading to the auction.",
        },
        {
          question:
            "Is there a calendar or upcoming auctions page that shows the dates and times of upcoming live auctions?",
          answer: `Certainly! There is indeed a calendar available. Here's how you can access it:
          <ul>
        <li>
        Navigate to the "Auctions calendar" tab located under the “Auctions” section on the homepage's navigation bar.
        </li>
        <li>
        Enter the desired auction dates, location, property type, and venue details. This will generate a list of upcoming auctions for you to view.
        </li>
   
    </ul>`,
        },
      ],
    },
    {
      category: "Using a Real Estate Agent for Auctions",
      questions: [
        {
          question:
            "Can I use a real estate agent for an auction and how does it work?",
          answer: `
          Yes, we encourage agent/broker involvement with our bank-owned properties. Here's how you can proceed:

          <ul>
                <li>
                Review the property details page to check if a commission is available. Even if not initially offered, agents may earn commissions through subsequent property flips or rentals.
                </li>
                <li>
                Ensure your agent or broker registers for the property at least 24 hours before the auction.
                </li>
                <li>
                Specify your broker or agent during your auction registration process.
                </li>
                <li>
                Agents and brokers must hold valid licenses in the state where the property is located.
                </li>
                <li>
                Submit a completed Participation Form and IRS Form W-9 as required.
                </li>
            </ul>
            `,
        },
        {
          question: "How much commission is offered on properties?",
          answer:
            "Commission rates differ for each bank-owned property. Please review the property details page for specific information. It's important to note that, by law, commissions are not permitted on foreclosure sales.",
        },
        {
          question:
            "What are some benefits of working with Vihara.ai on bank-owned properties as a buyer’s agent?",
          answer:
            "Certain bank-owned properties provide commission opportunities. Additionally, many of our listings Vihara.ai also streamlines the closing process, and our transparent platform ensures fair competition among buyers.",
        },
      ],
    },
    {
      category: "Winning the Auction",
      questions: [
        {
          question: "What happens after I win a bank-owned property auction? ",
          answer: `
          If you are the highest bidder at the end of an auction, here are your obligations after the auction:
            <ul>
                <li>
                <b>Contract Information:</b>
                You will receive an email confirming your highest bid. Log in to Vihara.ai and access your Saved Assets to fill out the Contract Information form by clicking on the blue “Submit Contract Information” button. Ensure all required details are provided within 1 business day.
                </li>
                <li>
                <b>Purchase Agreement:</b>

                Once verified, the Purchase Agreement will be generated. Sign and return the document for seller review and signature.
                </li>
                <li>
                <b>Proof of Funds:</b>
                Upload a copy of your Proof of Funds into your dashboard within 1 business day for approval before sending your contract to the seller.
                </li>
                <li>
                <b>Earnest Money Deposit:</b>
                Unless stated otherwise in your purchase agreement, send the Earnest Money Deposit to the closing company within 1 business day after the auction. Upload a copy of your receipt confirmation into your dashboard on the same day for approval before sending your contract to the seller.
                </li>
                </ul>
                <p>If the property is sold “subject to seller’s confirmation,” the seller must approve your bid before finalizing the sale. You will receive an email notification if applicable. Enter the escrow/closing process and work with a designated closing specialist to facilitate a smooth closing.</p>
            <p>Please note that specific requirements may vary for each auction. It is important to carefully review all communications from our team to ensure timely and accurate submission of required documents.</p>
                    `,
        },
        {
          question: "What happens if I win a live foreclosure auction? ",
          answer: `Here's what typically occurs after you win:
          <ul>
            <li>
            In most states, you are required to make full payment for the property immediately after the sale. Please verify your state's specific requirements. We recommend using a cashier’s check for payment.
            </li>
            <li>
            In many states, you will receive a Certificate of Sale once payment is made. Refer to your state’s procedures for more information.
            </li>
            <li>
            After acquiring the property, you are usually responsible for recording the deed with the county. This step is outside of the Vihara.ai process. Verify the recording requirements in your state for details.
            </li>
            <li>
            Do not attempt to access the property, even if it appears vacant, until you have legal authorization to do so. If the property is occupied, consider seeking advice from a real estate attorney for assistance.
            </li>
            </ul>
          `,
        },
        {
          question: "Am I allowed to purchase a property as a separate entity?",
          answer:
            "Certainly! You have the option to purchase the property under a separate entity, such as a Limited Liability Company or a Trust, but you will need to provide specific documentation.",
        },
        {
          question:
            "Do I receive a deed after I purchase a property at a live auction?",
          answer:
            "Once you acquire the property, it's typically your responsibility to record the deed with the county. This task must be handled independently, as it is not included in the Vihara.ai process. Please review your state's guidelines, as requirements may vary.",
        },
      ],
    },
  ];


export default faqData;