import preauctionHeroImage from "./assets/PreAuctionImages/preauctionheroImage.png"
import preimage1 from "./assets/PreAuctionImages/preauctionImage-1.png"
import preimage2 from "./assets/PreAuctionImages/preauctionImage-2.png"
import preimage3 from "./assets/PreAuctionImages/preauctionImage-3.png"
import preimage4 from "./assets/PreAuctionImages/preauctionImage-4.png";
/*--------------------*/
import auctiondayheroimage from "./assets/AuctionDayImages/heroImage.png"
import auctiondayimage1 from "./assets/AuctionDayImages/Image1.png";
import auctiondayimage2 from "./assets/AuctionDayImages/Image2.png";
import auctiondayimage3 from "./assets/AuctionDayImages/Image3.png";
import auctiondayimage4 from "./assets/AuctionDayImages/Image4.png";
/*---------------------*/
import postauctionheroimage from "./assets/PostAuctionImages/heroImage.png"
import postImage1 from "./assets/PostAuctionImages/Image1.png"
import postImage2 from "./assets/PostAuctionImages/Image2.png"
import postImage3 from "./assets/PostAuctionImages/Image3.png"
import postImage4 from "./assets/PostAuctionImages/Image4.png"
export const PreAuctionData = [
    {
        title: "The Pre-Auction Process",
        barText: [
            "Create Account",
            "Search Properties",
            "Conduct Research",
            "Gather Funds"
        ],
        heroImage: preauctionHeroImage
    },
    {
        imageSrc: preimage1,
        id: "Create Account",
        belowPara: `<p style="font-size:15px">With your free Vihara account, you’ll have a personalized dashboard to save your favorite properties, manage searches, and keep track of messages. Plus, you'll gain access to essential due diligence documents, custom alerts, and timely notifications when properties are ready for auction. <a href="/sign-up" style="text-decoration:none;">Sign up now</a>to streamline your property buying experience</p>`,
        title: "Create a free <br/> Vihara Account",
        description: "Before you begin your property search, create a free account with Vihara to unlock exclusive features."
    },
    {
        imageSrc: preimage2,
        id: "Search Properties",
        belowPara: `<ul style="list-style: none; padding: 0; color: #333; font-size: 15px; font-weight: 400; line-height: 26px;"><li><strong>1. Search Window:</strong> Start on our homepage and type your location into the search bar. Select “Foreclosure Sale” under “All Asset Types” to see available foreclosures in your area.</li><li><strong>2. Buy Tab:</strong> Click the “Auctions” tab in the navigation bar, then select “Foreclosure Sales.” Narrow your search by state, city, or zip code to find properties that meet your criteria.</li><li><strong>3. Auctions Calendar Tab:</strong> Click the “Auctions” tab in the navigation bar, then select “Auctions Calendar.” You can enter the date you want to attend an auction, and select your state under “Asset Type.” This will display all foreclosure sales scheduled for your chosen timeframe.</li></ul>`,
        title: "Search for your favorite properties",
        description: "Now you can start searching! With your free Vihara account, you gain access to thousands of foreclosure properties nationwide. Finding your perfect property is simple, with three easy ways to search:"
    },
    {
        imageSrc: preimage3,
        id: "Conduct Research",
        belowPara: `<ul style="list-style: none; padding: 0; color: #333; font-size: 15px; font-weight: 400; line-height: 26px;"><li><strong>1. Review Property Details:</strong> Check the auction time and location, and examine photos and details like beds, baths, and square footage. Consider financial info such as Est. Resale Value (ERV), Estimated Debt, and Opening Bid. Review documents like Title Information, Sale Terms, and IRS forms, noting sales prices, liens, and taxes.</li><li><strong>2. Estimate Value and Renovation Costs:</strong> Research current listings and recent sales in the area. Estimate renovation costs from a distance, assuming the home is occupied, and plan for upgrades like new floors, paint, and appliances.</li><li><strong>3. Order a Preliminary Title Report:</strong> Check the title condition and look for any second mortgages or liens by contacting a title company or real estate agent.</li></ul>`,
        title: "Conduct your Research",
        description: "Before attending an auction, you have to do your research to make an informed decision. Here’s what we recommend:"
    },
    {
        imageSrc: preimage4,
        id: "Gather Funds",
        belowPara: `<div style="padding-left: 0; color: #333; font-size: 15px; font-weight: 400; line-height: 26px;"><ul style="list-style: none;padding-left: 0;"><li><strong>a. Payment:</strong> Most foreclosures require full payment. Bring a cashier’s check instead of cash and allocate 5-10% more than your budget to avoid missing out on a great property due to a small shortfall.</li><li><strong>b. Details:</strong> Check the property details page to confirm the payee for the check.</li> <li><strong>c. </strong><a href="/mypdf.pdf" target="_blank" rel="noopener noreferrer" style="text-decoration:none">Click here </a> to read the list of entity requirements before you start bidding.</li></ul><p style="font-size:15px"></p></div>`,
        title: "Gather your Funds",
        description: "If you're ready to proceed after conducting your research, ensure you have sufficient funds to purchase the property. If buying under a separate entity like an LLC, be prepared to provide additional documentation after the auction. Here’s what you need to know:"
    }
]
export const AuctionDayData = [
    {
        title: "Auction Day Process",
        barText: [
            "Confirm Auction",
            "Check Property Status",
            "Register",
            "Bring Funds"

        ],
        heroImage: auctiondayheroimage
    },
    {
        imageSrc: auctiondayimage1,
        id: "Confirm Auction",
        belowPara: `<p style="font-size: 15px;"><strong>Before you head out:</strong></p><ul style="font-size: 15px; padding:10px"><li>Download or print a map with directions.</li><li>Check parking options and fees.</li><li>Verify if electronics like laptops, tablets, and smartphones are allowed.</li><li>Confirm the auction location—whether it's inside a hotel conference room or on the courthouse steps. If it's outdoors, dress appropriately.</li><li>Plan ahead to ensure a smooth auction experience!</li></ul>`,
        title: "Confirm the <br/> Auction Status",
        description: "Confirm the auction venue in advance by checking the event's location details. Auctions may be held by Vihara, a trustee, or the sheriff at the county courthouse."
    },
    {
        imageSrc: auctiondayimage2,
        id: "Check Property Status",
        belowPara: `<p>By saving the property to your dashboard, you’ll receive real-time alerts about any changes in its status, whether postponed, canceled, or cleared for sale.</p>`,
        title: "Check the <br/> Property Status",
        description: "Before heading to the auction, confirm the property's auction status, time, and location."
    },
    {
        imageSrc: auctiondayimage3,
        id: "Register",
        belowPara: `<p>Check in, register, and show Proof of Funds (if required) to receive your Auction Bidder Card (if applicable). Don’t forget to bring a driver's license, passport, or another government-issued ID. You’ll need a verification ID to participate in the auction.</p>`,
        title: "Register to Bid",
        description: "If the property you're eyeing is set to be auctioned, make sure to arrive at least 30 minutes early."
    },
    {
        imageSrc: auctiondayimage4,
        id: "Bring Funds",
        belowPara: `<p>If a deposit is needed with a deadline to pay the balance, be ready to show Proof of Funds. To avoid missing out on a great property, consider allocating an extra 5-10% above your budget. While cash is accepted, we recommend bringing cashier’s checks for faster and safer transactions.</p>`,
        title: "Bring your Funds",
        description: "Several states require full payment immediately after the auction, so check your state’s specific requirements."
    }
]
export const PostAuctionData = [
    {
        title: "Post-Auction Process",
        barText: [
            "Receipt of Sale",
            "Getting the Deed",
            "Gaining Possession",
            "Congratulations!"
        ],
        heroImage: postauctionheroimage
    },
    {
        imageSrc: postImage1,
        id: "Receipt of Sale",
        belowPara: `<p>In many states, you'll receive a Certificate of Sale receipt after payment. Note that some states, like North Carolina, have Upset Bidding, which extends the bidding period.</p><div style="display:flex;gap:5px;" >
        <svg  style="transform: translateY(10px);" class="bulb-icon" width="50" height="50" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
            <g id="Layer_24" data-name="Layer 24">
                <path d="M40.8,36.53a1,1,0,0,0-.3-1.37A15.27,15.27,0,1,0,23.47,35.8a1,1,0,0,0-.3,1.37L26.44,42a1,1,0,0,0,.86.5h9.4a1,1,0,0,0,.86-.5ZM26,40.93,23.52,36.9a13.27,13.27,0,1,1,16.89,0L38,40.93Z" fill="#F8D308"/>
                <rect x="27" y="42" width="10" height="4" rx="1" fill="#333"/>
                <rect x="28" y="48" width="8" height="4" rx="1" fill="#333"/>
                <path d="M30,52a2,2,0,0,0,4,0V50H30Z" fill="#333"/>
                <path d="M31,56a1,1,0,0,0,2,0V54H31Z" fill="#333"/>
                <path d="M36,0H28A1,1,0,0,0,28,2h8a1,1,0,0,0,0-2Z" fill="#333"/>
                <path d="M16.71,5.29a1,1,0,1,0-1.42,1.42L16.29,7.71a1,1,0,0,0,1.42-1.42Z" fill="#333"/>
                <path d="M54.41,6.71a1,1,0,0,0-1.42-1.42L52.71,6.29a1,1,0,0,0,1.42,1.42Z" fill="#333"/>
                <path d="M32,8a1,1,0,0,0-1,1V12a1,1,0,0,0,2,0V9A1,1,0,0,0,32,8Z" fill="#333"/>
                <path d="M56,28H52a1,1,0,0,0,0,2h4a1,1,0,0,0,0-2Z" fill="#333"/>
                <path d="M12,30a1,1,0,0,0-1-1H7a1,1,0,0,0,0,2h4A1,1,0,0,0,12,30Z" fill="#333"/>
                <path d="M46.66,48.66a1,1,0,1,0-1.42-1.42L44.29,48a1,1,0,0,0,1.42,1.42Z" fill="#333"/>
                <path d="M18.66,50.66a1,1,0,1,0-1.42-1.42L17.29,49a1,1,0,0,0,1.42,1.42Z" fill="#333"/>
                <path d="M50.71,38.29a1,1,0,0,0-1.42,1.42L50.71,40A1,1,0,1,0,52.13,38.59Z" fill="#333"/>
                <path d="M14.71,37.29a1,1,0,0,0-1.42,1.42L14.29,39a1,1,0,0,0,1.42-1.42Z" fill="#333"/>
            </g>
        </svg><p>Don’t forget to bring a Government ID, like a passport or Driver’s License.</p></div> `,
        title: "Receipt of Sale",
        description: "Congratulations on winning! In most states, you'll need to pay for the property in full, typically with a cashier’s check. Check your state’s specific requirements."
    },
    {
        imageSrc: postImage2,
        id: "Getting the Deed",
        belowPara: `<p>If you’re purchasing the property through an entity like an LLC, be prepared to provide the necessary documentation after the auction. Review the requirements beforehand to ensure a smooth process; otherwise, you may need to buy the property as an individual.</p>`,
        title: "Getting the Deed",
        description: "After purchasing the property, you’ll need to record the deed with the county yourself, as this is not part of the Vihara process."
    },
    {
        imageSrc: postImage3,
        id: "Gaining Possession",
        belowPara: `<p> If the property is occupied, consider consulting a real estate attorney for guidance. It is highly discouraged to enter the property without consulting a real estate attorney first.</p>`,
        title: "Gaining Possession",
        description: "Wait until you have the legal right before entering the property, even if it's vacant."
    },
    {
        imageSrc: postImage4,
        id: "Congratulations!",
        belowPara: `<p>Get your keys made, inspect the property, and begin any renovations. Soon, your property will be ready to flip, rent, or even move into!</p>`,
        title: "Congratulations on Closing",
        description: "Congratulations! You’ve purchased the property, recorded the deed, and handled any necessary occupant vacating. Now it’s time to enjoy the rewards."
    }
]