// Required imports
// import React from 'react'
import { useParams } from "react-router-dom";
import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import "./PropertyDetail.css";
import NavBackground from "../includes/navBackground";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faUser, faHeart, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Navbar from "./Navbar/Navbar";
import ImageCarousel from "./PropertySlide/ProprtyImageSlide";
import InvestementCalculator from "./InvestementCalculator";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import image1 from "./sampleAssets/image1.jpg"
import image2 from "./sampleAssets/image2.jpg"

import image3 from "./sampleAssets/image3.jpg"

import image4 from "./sampleAssets/image4.jpg"

import image5 from "./sampleAssets/image5.jpg"

import ProprtyImageSlide from "./PropertySlide/ProprtyImageSlide";
import PropTypes from 'prop-types';
import { toast } from "react-toastify";
import { AuthContext } from "../../ContextProvider/AuthContext";
// Property Address
const PropertyAddress = ({ product }) => {
  return (
    <>
      <div className="property-address-main-details">
        <div className="property-deatial-main-address-and-type">
          <div className="property-type-sale">
            <span className="circle-dot-boundary">
              <img className='foreclosure-image' src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNzIiIGhlaWdodD0iNzIiIHZpZXdCb3g9IjAgMCA3MiA3MiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgZmlsdGVyPSJ1cmwoI2ZpbHRlcjBfZF8yMDIyXzE3MTU3KSI+CjxjaXJjbGUgY3g9IjM2IiBjeT0iMzIiIHI9IjI0IiBmaWxsPSJ3aGl0ZSIvPgo8L2c+CjxjaXJjbGUgY3g9IjM2IiBjeT0iMzIiIHI9IjE4IiBmaWxsPSIjMDM4NGZiIi8+CjxkZWZzPgo8ZmlsdGVyIGlkPSJmaWx0ZXIwX2RfMjAyMl8xNzE1NyIgeD0iMCIgeT0iMCIgd2lkdGg9IjcyIiBoZWlnaHQ9IjcyIiBmaWx0ZXJVbml0cz0idXNlclNwYWNlT25Vc2UiIGNvbG9yLWludGVycG9sYXRpb24tZmlsdGVycz0ic1JHQiI+CjxmZUZsb29kIGZsb29kLW9wYWNpdHk9IjAiIHJlc3VsdD0iQmFja2dyb3VuZEltYWdlRml4Ii8+CjxmZUNvbG9yTWF0cml4IGluPSJTb3VyY2VBbHBoYSIgdHlwZT0ibWF0cml4IiB2YWx1ZXM9IjAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDEyNyAwIiByZXN1bHQ9ImhhcmRBbHBoYSIvPgo8ZmVPZmZzZXQgZHk9IjQiLz4KPGZlR2F1c3NpYW5CbHVyIHN0ZERldmlhdGlvbj0iNiIvPgo8ZmVDb2xvck1hdHJpeCB0eXBlPSJtYXRyaXgiIHZhbHVlcz0iMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMC4zIDAiLz4KPGZlQmxlbmQgbW9kZT0ibm9ybWFsIiBpbjI9IkJhY2tncm91bmRJbWFnZUZpeCIgcmVzdWx0PSJlZmZlY3QxX2Ryb3BTaGFkb3dfMjAyMl8xNzE1NyIvPgo8ZmVCbGVuZCBtb2RlPSJub3JtYWwiIGluPSJTb3VyY2VHcmFwaGljIiBpbjI9ImVmZmVjdDFfZHJvcFNoYWRvd18yMDIyXzE3MTU3IiByZXN1bHQ9InNoYXBlIi8+CjwvZmlsdGVyPgo8L2RlZnM+Cjwvc3ZnPgo=" alt="Updated SVG Image" />
            </span>
            <span className="type-of-property">{product.propertyType}</span>
            <FontAwesomeIcon className="info-icon" icon={faCircleInfo} />
          </div>
          <p className="property-street-address">{product.street}</p>
          <p className="property-couty-and-state">
            {product.state}, {product.county}
          </p>
        </div>
        <div className="property-deatial-main-save-and-details">
          <div className="user-and-save">
            <div className="property-views">
              <FontAwesomeIcon icon={faUser} />
              <span>2,988</span>
              <span>Views</span>
            </div>
            <div className="property-save">
              <FontAwesomeIcon icon={faHeart} />
              <span className="property-save-heart">Save</span>
            </div>
          </div>
          <div className="propetry-batroom-room-and-other">
            <span>{product.propertyType} Beds </span>
            <span className="bed-divider">|</span>
            <span>{product.propertyType} Baths</span>
            <span className="bed-divider">|</span>
            <span>{product.squareFootage} <span className="area-unit">Sq. Ft.</span></span>
          </div>
        </div>
      </div>
    </>
  );
};

//Property Basic Details
const BasicDetails = ({ product }) => {
  // const [showSlideshow, setShowSlideshow] = useState(false);

  // const handleShowSlideshow = () => {
  //   setShowSlideshow(true);
  // };

  // const handleCloseSlideshow = () => {
  //   setShowSlideshow(false);
  // };
  const images = [image1, image2, image3, image4, image5];

  return (
    <div className="propetry-image-slide-show">
      <ImageCarousel images={product?.otherImages} />
    </div>

  );
};

//Price Insites
const PriceInsites = ({ product }) => {
  return (
    <div className="property-detials-property-instites">
      <h2 className="price-insite-head">
        Price Insite
      </h2>

      <div className="property-realestate-vales">
        <div className="Est-price-value">
          Est. Resale Value
          <FontAwesomeIcon className="info-icon" icon={faCircleInfo} />

        </div>
        <div className="est-price-value-doller">
          $510,000

        </div>
        <div className="compare-est-price">
          <span className="see-comparable-sales">
            See Comparable Sales
          </span>
          <span className="property-price-source">
            Source: Collateral Analytics
          </span>

        </div>

      </div>
      <div className="open-bid-price-and-tag">
        <span className="Est-price-value">Opening Bid</span>
        <span className="est-price-value-doller" >{product.startBid}</span>
      </div>
      <div className="property-realestate-vales">
        <div className="Est-price-value">
          Rental Estimate <FontAwesomeIcon className="info-icon" icon={faCircleInfo} />

        </div>
        <div className="est-price-value-doller">
          $2,200

        </div>
        <div className="compare-est-price">
          <span className="see-comparable-sales">
            See Details
          </span>
          <span className="property-price-source">
            Source: Collateral Analytics
          </span>

        </div>

      </div>


    </div>
  );
};

const AuctionBidComponent = () => {
  const [timeRemaining, setTimeRemaining] = useState({
    days: 2,
    hours: 6,
    minutes: 12,
    seconds: 32,
  });

  const [highestBid, setHighestBid] = useState(364000);
  const [bidAmount, setBidAmount] = useState(highestBid + 2500);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining((prevTime) => {
        const seconds = prevTime.seconds - 1;
        const minutes = seconds < 0 ? prevTime.minutes - 1 : prevTime.minutes;
        const hours = minutes < 0 ? prevTime.hours - 1 : prevTime.hours;
        const days = hours < 0 ? prevTime.days - 1 : prevTime.days;

        return {
          days: days < 0 ? 0 : days,
          hours: hours < 0 ? 23 : hours,
          minutes: minutes < 0 ? 59 : minutes,
          seconds: seconds < 0 ? 59 : seconds,
        };
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const handleBidChange = (increment) => {
    setBidAmount((prevBid) => prevBid + increment);
  };

  const handlePlaceBid = () => {
    if (bidAmount > highestBid) {
      setHighestBid(bidAmount);
      alert(`Bid placed successfully: $${bidAmount.toLocaleString()}`);
    } else {
      alert('Bid amount must be higher than the current highest bid.');
    }
  };

  return (
    <div className="property-detail-auction-container">
      <div className="property-detail-auction-timer-container">
        <h3 className="property-detail-auction-title">AUCTION ENDS IN</h3>
        <div className="property-detail-auction-timer">
          <div>
            <h4>{String(timeRemaining.days).padStart(2, '0')}</h4>
            <span>Days</span>
          </div>
          <div className="two-dots">
            <h4>:</h4>
          </div>
          <div>
            <h4>{String(timeRemaining.hours).padStart(2, '0')}</h4>
            <span>Hours</span>
          </div>
          <div className="two-dots">
            <h4>:</h4>
          </div>
          <div>
            <h4>{String(timeRemaining.minutes).padStart(2, '0')}</h4>
            <span>Mins</span>
          </div>
          <div className="two-dots">
            <h4>:</h4>
          </div>
          <div>
            <h4>{String(timeRemaining.seconds).padStart(2, '0')}</h4>
            <span>Secs</span>
          </div>
        </div>
      </div>
      <div className="property-detail-auction-bid-info">
        <div>
          <h4>Current Highest Bid</h4>
          <a href="#" className="property-detail-view-bids">View All Bids: 1</a></div>
        <h2 className="property-detail-highest-bid">${highestBid.toLocaleString()}</h2>
      </div>
      <div className="property-detail-auction-details">
        <div>
          <span className="dotted-border">Bid Deposit</span>
          <span className="property-detail-auction-detail-value">Required</span>
        </div>
        <div>
          <span className="dotted-border">Cash Only</span>
          <span className="property-detail-auction-detail-value">Yes</span>
        </div>
      </div>
      <div className="input-place-bid-container">
        <div className="property-detail-bid-controls">
          <button onClick={() => handleBidChange(-2500)}>-</button>
          <input
            type="text"
            value={`$${bidAmount.toLocaleString('en-US')}`}
            readOnly
            className="property-detail-bid-input"
          />
          <button onClick={() => handleBidChange(2500)}>+</button>
        </div>
        <div className="property-detail-place-bid">
          <button onClick={handlePlaceBid} className="property-detail-place-bid-button">
            Place Bid
          </button>
        </div>
      </div>
      <div className="property-detail-dynamic-bidding-info">
        <a href="#" className="property-detail-dynamic-bidding-link">Learn about dynamic bidding</a>
      </div>
    </div>
  );
};
//PropertyDetails in brief
const PropertyDetails = ({ product }) => {
  return (
    <div className="propert-detail-card" >
      <h2 className="property-table-heading">
        Property Details
      </h2>
      <div className="property-detail-table">
        <div className="property-detail-table-grid">
          <div className="table-column-detail">
            <div className="propety-asset">
              <span className="property-asset-detail">
                Beds
              </span>
              <span className="property-asset-value">
                {product.beds}
              </span>
            </div>
            <div className="propety-asset">
              <span className="property-asset-detail">
                Baths
              </span>
              <span className="property-asset-value">
                {product.baths}
              </span>
            </div>
          </div>
          <div className="table-column-detail">
            <div className="propety-asset">
              <span className="property-asset-detail">
                Square Footage
              </span>
              <span className="property-asset-value">
                {product.squareFootage}
              </span>
            </div>
            <div className="propety-asset">
              <span className="property-asset-detail">
                Lot Size (Acres)
              </span>
              <span className="property-asset-value">
                {product.lotSize}
              </span>
            </div>
          </div>
          <div className="table-column-detail">
            <div className="propety-asset">
              <span className="property-asset-detail">
                Property Type
              </span>
              <span className="property-asset-value">
                {product.propertyType}
              </span>
            </div>
            <div className="propety-asset">
              <span className="property-asset-detail">

                Year Built
              </span>
              <span className="property-asset-value">
                {product.yearBuilt}
              </span>
            </div>
          </div>
          <div className="table-column-detail">
            <div className="propety-asset">
              <span className="property-asset-detail">
                Case/File No
              </span>
              <span className="property-asset-value">
                2296-004466
              </span>
            </div>
            <div className="propety-asset">
              <span className="property-asset-detail">
                APN
              </span>
              <span className="property-asset-value">
                {product.apn}
              </span>
            </div>
          </div>


        </div>

      </div>

    </div>
  );
};

//InpersonAuction card
import { useSelector } from "react-redux";
import { setUser } from "../../features/user/userSlice";
import { useDispatch } from "react-redux";
import { setProperties } from "../../features/property/propertySlice";

const InpersonAuction = ({ product }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const [isSaved, setIsSaved] = useState(false);
  const [auctionStatus, setAuctionStatus] = useState("");
  useEffect(() => {
    if (user.savedProperties.length > 0 && user.savedProperties.find(productObject => productObject._id === product._id)) {
      setIsSaved(true);
    }
  }, [user.savedProperties, product._id]);

  useEffect(() => {
    const checkAuctionStatus = () => {
      const currentDateTime = new Date();

      const auctionStartDate = new Date(product.auctionStartDate);
      const auctionEndDate = new Date(product.auctionEndDate);

      const auctionStartDateTime = new Date(auctionStartDate.setHours(...product.auctionStartTime.split(':')));
      const auctionEndDateTime = new Date(auctionEndDate.setHours(...product.auctionEndTime.split(':')));

      console.log(auctionStartDateTime, auctionEndDateTime);

      if (currentDateTime > auctionEndDateTime) {
        setAuctionStatus("Bid Ended");
      } else if (currentDateTime < auctionStartDateTime) {
        setAuctionStatus("Bid Will Start Soon");
      } else if (currentDateTime >= auctionStartDateTime && currentDateTime <= auctionEndDateTime) {
        setAuctionStatus("Bid Now");
      }
    };

    checkAuctionStatus();
    const interval = setInterval(checkAuctionStatus, 60000); // Check every 60 seconds

    return () => clearInterval(interval);
  }, [product.auctionStartDate, product.auctionStartTime, product.auctionEndDate, product.auctionEndTime]);

  const handleclickSaveUpdates = async () => {
    if (!user) {
      toast.error("Please login");
      return;
    }
    if (product.bidderEmails.includes(user.email) === false) {
      toast.error("Please Contact to admin");
      return;
    }
    if (isSaved) {
      toast.info("Property Already Saved");
      return;
    }
    try {
      const response = await axios.put("https://viharabackend.onrender.com/api/v1/user/save-property", {
        user_id: user._id,
        product_id: product._id
      });
      dispatch(setUser(response.data.user));
      if (response.data.user.savedProperties.length > 0 && response.data.user.savedProperties.find(productObject => productObject._id === product._id)) {
        setIsSaved(true);
      }
      toast.success("Property Saved Successfully");
    } catch (error) {
      console.log(error);
    }
  };
  const handleRemove = async () => {
    console.log("hi")
    try {
      const response = await axios.put("https://viharabackend.onrender.com/api/v1/user/rempve-property", {
        user_id: user._id,
        product_id: product._id
      });
      dispatch(setUser(response.data.user));
      setIsSaved(false);
      toast.success("Property Removed Successfully");
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <div className="inperson-auction-card">
      <div className="inperson-upper-section">
        <div className="inperson-heading">
          In-Person Auction
        </div>
        <div className="save-for-update-button">
          {isSaved ? <span onClick={handleRemove}>Remove</span> : <span onClick={handleclickSaveUpdates}>Save for Updates</span>}
        </div>
        <div className="why-save-tag">
          why save ?
        </div>
        <div className="save-for-update-button">
          {auctionStatus}
        </div>
      </div>

      <div className="auction-detail-section">

        <div className="date-section-bidding">
          <div className="propert-auction-date">
            <span className="property-asset-detail">Date</span>
            <span className="property-asset-value property-date-auction">{product.auctionStartDate.slice(0, 10)}</span>
          </div>
          <div className="calender"></div>
        </div>
        <div className="auction-start-time">
          <span className="property-asset-detail">Auction start time</span>
          <span className="property-asset-value">{product.auctionStartTime}</span>
        </div>
        <div className="auction-start-location">
          <span className="property-asset-detail loaction-border-set">Location</span>
        </div>
        <div className="property-auction-location-container">
          <b className="auction-location-detail">{product.propertyDescription}</b>
        </div>
        <div className="prepare-for-the-auction">
          Prepare for the auction
        </div>
        <div className="prepare-for-the-auction">
          Other Properties at this auction
        </div>
      </div>
    </div>
  );
};



// import './PropertiesData.css';

//
const PropertyCondition = () => {
  const [isOn, setIsOn] = useState(false);
  const handleToggle = () => {
    setIsOn(!isOn);
  };
  return (
    <div className="property-condition-container">
      <div className="property-detail-page-card">
        <div className="property-detail-page-status">
          <span className="property-detail-page-status-badge">BANK-OWNED</span>
        </div>
        <div className="property-detail-page-details">
          <div>
            <h2 className="property-detail-page-address">9415 NE RIVER POINT CIR</h2>
            <div className="property-detail-page-location">
              Portland, OR 97211
              <a href="#" className="property-detail-page-get-directions">Get Directions</a>
            </div>
          </div>
          <div className="property-detail-page-stats">
            <div className="property-detail-page-stat">
              <span className="property-detail-page-stat-value">4</span>
              <span className="property-detail-page-stat-label">Beds</span>
            </div>
            <div className="property-detail-page-stat">
              <span className="property-detail-page-stat-value">3</span>
              <span className="property-detail-page-stat-label">Baths</span>
            </div>
            <div className="property-detail-page-stat">
              <span className="property-detail-page-stat-value">2,890</span>
              <span className="property-detail-page-stat-label">Square Feet</span>
            </div>
          </div>
        </div>
        <div className="property-detail-page-description">
          <h4>Description</h4>
          <p>REO Occupied - the seller does not represent or guarantee occupancy status.
            NO VIEWINGS of this property. Please DO NOT DISTURB the occupant. "As is" cash
            only sale with no contingencies or inspections.
            Buyer will be responsible for obtaining possession of the property upon closing.</p>
        </div>
      </div>
      <div className="property-detail-page-alert-container">
        <div>
          <h4>Receive Alerts</h4>
          <p>Stay up-to-date on this property.</p>
        </div>
        <div className={`property-detail-page-toggle-switch ${isOn ? 'on' : 'off'}`} onClick={handleToggle}>
          <div className="property-detail-page-toggle-knob"></div>
        </div>
      </div>
    </div>
  );
};
const PropertyFullDetailContainer = () => {
  return (
    <div id="propertyDetails" className="property-detail-page-full-details-container">
      <div className="property-detail-page-full-details-content">
        <div className="property-detail-page-view-and-detail">
          <h2 className="property-detail-page-full-details-title">Property Details for 27 Juniper Dr</h2>
          <div className="property-detail-page-full-details-views">
            <span className="property-detail-page-full-details-eye-icon">👁️</span>
            <span className="property-detail-page-full-details-views-count">3,476 Views</span>
          </div>
        </div>
        <div className="property-detail-page-full-details-section">
          <h3 className="property-detail-page-full-details-subtitle">Interior Details</h3>
          <p><strong>Beds:</strong> 4 Beds</p>
          <p><strong>Full Baths:</strong> 2 Baths</p>
          <p><strong>Half Baths:</strong> 1 Bath</p>
          <p><strong>Air Conditioning:</strong> Yes</p>
        </div>

        <div className="property-detail-page-full-details-section">
          <h3 className="property-detail-page-full-details-subtitle">Land Info</h3>
          <p><strong>Lot Size:</strong> 6,578 Sq. Ft.</p>
          <p><strong>Size:</strong> 2,890 Sq. Ft.</p>
        </div>

        <div className="property-detail-page-full-details-section">
          <h3 className="property-detail-page-full-details-subtitle">Property Info</h3>
          <p><strong>Year Built:</strong> 2005</p>
          <p><strong>Type:</strong> Single Family</p>
          <p><strong>Property ID:</strong> 90016119588</p>
          <p><strong>County:</strong>  HILLSBOROUGH</p>
        </div>
        <div className="property-detail-page-full-details-section">
          <h3 className="property-detail-page-full-details-subtitle">Other Property Info</h3>
          <p><strong>Sub Division:</strong> </p>
          <p><strong>MLS ID:</strong> 4995132</p>
        </div>
        <div className="property-detail-page-full-details-section">
          <h3 className="property-detail-page-full-details-subtitle">Property Fees</h3>
          <ul>
            <li>Buyer's Premium: Greater of 5.0% of bid amount, or $1,000 (Buyer Pays at Closing).</li>
            <li>CASH - Earnest Money Deposit: Lesser of 3.0% of bid amount, or $15,000.</li>
            <li>Buyer's Agent Commission: Either 3.0% of the Purchase Price or $1,250, whichever is greater.(Seller Pays at Closing)</li>
            <li>All bids must be submitted through Hubzu.com. Bids sent via email and fax will not be considered.</li>
          </ul>
        </div>
      </div>
      <div className="property-detail-page-full-details-notices">
        <div className="property-detail-page-full-details-notice">
          <div className="property-detail-page-full-details-notice-icon property-detail-page-full-details-cash-icon">💰</div>
          <div>
            <strong>Cash Only Sale</strong>
            <p>Cash Purchase Only. Conventional financing is not available; financing contingency not offered.</p>
          </div>
        </div>

        <div className="property-detail-page-full-details-notice">
          <div className="property-detail-page-full-details-notice-icon property-detail-page-full-details-warning-icon">⚠️</div>
          <div>
            <strong>Special Servicing Conditions Apply</strong>
            <p>Bid review required. Sold subject to seller approval.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

const PropertyDetail = () => {
  const { id } = useParams();
  const products = useSelector((state) => state.property.Properties);
  console.log(products);
  const [product,setProduct]=useState(null);
  useEffect(()=>{
       setProduct(products.find((item)=>item._id===id));
  },[products])
  return (
    <>
    <div>
      <NavBackground />
      <Navbar />
      {products.length===0 ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '85vh',
          }}
        >
          <CircularProgress size={100} />
        </Box>
      ) : (
        <>
          <div id="overview" className="property-image-and-insites">
            <BasicDetails product={product} />
            {/* <PriceInsites product={product} /> */}
            <AuctionBidComponent />
          </div>
          <PropertyCondition />
          <PropertyFullDetailContainer />
          <section id="rentalEstimate">
            <InvestementCalculator />
          </section>
          {/* <div className="man-background-property-detail">
            <PropertyDetails product={product} />
            {user && <InpersonAuction product={product} />}
          </div> */}
        </>
      )}
    </div>
  </>  
  );
};

export default PropertyDetail;
