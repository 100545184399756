import React, { useState } from "react";
import "./faqs.css";
import NavBackground from "../../includes/navBackground";
import CommonHeroSection from "../../HomePage/CommonHeroSection/CommonHeroSection";
import faqData from "./faqData";
import CommonAcordianSection from "./CommonAcordianSection";
import faqImage from "./faq-image.jpg";
const FaqMainSearch = () => {
  return (
    <div className="faq-main-search-container">
      {/* <h2 className="faq-main-search-heading">How can we help you?</h2> */}
      <input
        type="text"
        className="faq-main-search-input"
        placeholder="Search for a topic"
      />
    </div>
  );
};
const Faqs = () => {
  window.scrollTo(0, 0);
  return (
    <div>
      <NavBackground />
      <div>
        <div className="faq-hero-container" id="terminology-top">
          <div className="faq-main-section-content">
            <h2>
              Still have questions?
            </h2>
            <p>
              Browse through our Frequently Asked Questions section below to see if your questions can be answered.
            </p>
            {/* <a href="#">SIGNUP</a> */}
          </div>
        </div>
      </div>
      {/* <CommonHeroSection  faqImage={faqImage} title="Still have questions?" description="Browse through our Frequently Asked Questions section below to see if your questions can be answered." videoSrc=""/> */}
      {/* <FaqMainSearch /> */}
      <CommonAcordianSection
        accData={faqData}
        CategoryFontSize={"24px"} QuestionFontSize={"40px"}
        QuestionBold={"bolder"}
        CategoryFontWeight={"450"}
      />
    </div>
  );
};

export default Faqs;
