import React from 'react';
import { Outlet } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
const AdminLayout = () => {
    const isScreenSmall = useMediaQuery("(max-width:840px)");
    return (
        <>
        <Outlet /> 
      </>
    )
};

export default AdminLayout;
