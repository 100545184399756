export const BankOwnedFaqs = [
    {
        category: "",
        questions: [
          {
            question:
              "How can I participate in an online auction for bank-owned properties?",
            answer:
              "To explore bank-owned properties, click on “Auctions” in the navigation bar. Then choose the “REO Bank Owned” section.  It's advisable to create an account before browsing. This way, you can save properties to your dashboard and register to bid on them once you find one you like. Please note, registration requires having an account.",
          },
          {
            question: "How can I register for an online bank-owned auction? ",
            answer: `
                      To register for an online auction, do the following:
                      <ul>
                      <li>
                      Log in with your email and password.
                      </li>
                      <li>
                      Next, find your property and click on the property photo or link for more information.
                      </li>
                      <li>
                      Click the “Register to Bid” button.
                      </li>
                      <li>
                      Your name and email address will be auto-populated.
                      </li>
                      <li>
                      We will confirm your registration details after your registration is complete. Be sure to check your email to confirm the date and time of the auction.
                      </li>
                  </ul>
                      `,
          },
          {
            question:
              "Why am I restricted from having more than one account linked to my phone number?",
            answer:
              "Each account must have a distinct verified phone number to place bids or offers. This measure upholds auction integrity and enhances the security of information exchanged between Vihara.ai and each responsible bidder. For those needing multiple vesting information options for purchases, we have conveniently included the Purchase Profiles tab in the Dashboard, enabling easy creation of multiple profiles.",
          },
          {
            question: "Why does my number need to be verified?",
            answer:
              "We verify your phone number once to safeguard the integrity of our auctions and ensure the authenticity of all our bidders.",
          },
          {
            question: "Will my phone number be used for other purposes?",
            answer:
              "No, your number will never be used for spam purposes, and we will only call if it's necessary to contact you.",
          },
          {
            question: "What is an auction event?",
            answer:
              "An auction event is a scheduled time period during which a group of similar properties are auctioned. To determine if properties belong to the same event, compare the Event ID number found on the Property Details Page. If the first six digits of the Event ID match those of other properties, they are part of the same event. Note that the remaining digits represent the Item number and may vary.",
          },
          {
            question: "What is Direct Offer? ",
            answer: `The Direct Offer is a feature that lets you directly make offers to sellers for certain bank-owned properties outside of an auction. You can find Direct Offer properties under the "Buying Type" tab on the Search Results Page.
                      `,
          },
          {
            question: "What should I do before I bid on a bank-owned property?",
            answer:
              "Before bidding on a bank-owned property, you should conduct due diligence by estimating the property's value and renovation costs, inspecting the physical property, obtaining a title report, and researching the surrounding neighborhood.",
          },
          {
            question: `A property is listed as a “Cash only purchase.” What does that mean?`,
            answer: `A “Cash only purchase” means the property must be paid in full immediately following the auction. The funds for the purchase must come from your bank accounts, securities, or other financial accounts. Some investors use private money from various sources, but regardless of the source, the funds need to be readily available to complete the purchase immediately.`,
          },
          {
            question: "What is the Reserve Price? ",
            answer: `The Reserve Price is the minimum amount a seller will accept. While most properties have a set Reserve Price, there are instances where a winning bidder may be declared even if the Reserve Price isn't met. This happens when a property is sold "subject to" the seller's approval. Occasionally, sellers may choose to sell a property for less than the Reserve Price.`,
          },
          {
            question: "What happens if the Reserve Price is not met?",
            answer: `If the Reserve is not met, the Seller can either:
            <ul>
          <li>
          Decline all bids and re-auction the property
          </li>
          <li>
          Sell the property to the highest bidder. These offers are typically considered “Subject to Seller Approval.”
          </li>
      </ul>
            `,
          },
          {
            question: "Why are the bid increments so high?",
            answer:
              "Bid increments are designed to keep the auction progressing efficiently toward an acceptable price. As the auction nears its end, bid increments decrease to allow bidders more flexibility in placing precise bids.",
          },
          {
            question: "What is counterbidding?",
            answer:
              "Counter bidding is a common practice among major auction houses. Vihara.ai will place counter bids on behalf of the seller to bring the price closer to the Reserve Price, the minimum amount the seller will accept.",
          },
          {
            question: "I won the auction. What should I do next?",
            answer:
              "If you are the highest bidder when an auction ends, you will receive a confirmation email. You must then complete the steps noted in the email to finalize your property purchase.",
          },
          {
            question: "What is a Purchase Profile?",
            answer:
              "A Purchase Profile is an online form that buyers fill out after winning a bank-owned property. It inquires about how they intend to use the property—such as for investment or as a primary residence—and includes a few other questions necessary to complete the purchase. You can create multiple profiles, but make sure to name them in an easily identifiable way for future use.",
          },
          {
            question: "Why does the bid increment change?",
            answer:
              "As the auction nears its end, the increments decrease to give bidders more flexibility in placing precise bids.",
          },
          {
            question: "What is Proxy Bidding and how does it work?",
            answer: `
            <ul>
          <li>
          Proxy bid is a feature for bank-owned properties that lets you place a bid before the auction begins. After you submit your bid from the Property Details Page, Vihara.ai will bid on your behalf during the live auction.
          </li>
          <li>
          You can set proxy bids on multiple properties in advance. Once the auctions conclude, we will notify you via email if you are the winner.
          </li>
          </ul>
      `,
          },
          {
            question: "Do Sellers accept bids that are below the Reserve Price?",
            answer:
              "Yes, the seller might consider bids below the Reserve Price, so we recommend placing your highest and best bid in each auction",
          },
          {
            question: "Is the Opening Bid also the Reserve?",
            answer:
              "The Opening Bid is the starting amount for bidding; it does not represent the Reserve and may not reflect the seller's acceptable offer price.",
          },
          {
            question: "Why is the auction time being extended?",
            answer:
              "Bids placed within the last 2 minutes extend the auction, ensuring fairness by allowing all bidders to place their final bids.",
          },
          {
            question: "Can I use a mortgage?",
            answer: `Typically, you are not allowed to use a mortgage. Most properties on Vihara.ai  are “Cash only” which means the property must be paid in full by the closing date.`,
          },
          {
            question: "Can I use financing to purchase the property?",
            answer:
              "Usually, no. Please review the property listing to confirm financing options. Most properties on Vihara.ai are cash-only, requiring full payment by the closing date",
          },
          {
            question:
              "Am I allowed to purchase a property with multiple separate entities?",
            answer:
              "Yes, you can enter up to three entities into your contract. To streamline the process, you can pre-enter your entities ahead of time, ensuring they are pre-populated into your contract when you win an auction in the future.",
          },
        ],
      },
]