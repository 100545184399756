import React from 'react';
import './BlogCard.css';

const BlogCard = ({ blogImage, writterImage,title, category, description, authorName, authorTitle }) => {
  return (
    <div className="author-card-container">
      <img src={blogImage} alt={title} className="author-card-image" />
      <div className="author-card-content">
        <span className="author-card-category">{category}</span>
        <h3 className="author-card-title">{title}</h3>
        <p className="author-card-description">{description}</p>
        <div className="author-card-author">
          <img src={writterImage} alt="AUthor Image" className="author-card-author-image" />
          <div className="author-card-author-info">
            <p className="author-card-author-name">{authorName}</p>
            <p className="author-card-author-title">{authorTitle}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
