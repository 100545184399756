import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faShareSquare } from '@fortawesome/free-regular-svg-icons';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import './Navbar.css'; // External CSS file

const Navbar = () => {
  const [activeTab, setActiveTab] = useState('overview');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    const section = document.getElementById(tab);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="property-detail-navbar">
      <div className="property-detail-navbar-left">
        <div
          className={`property-detail-navbar-item ${activeTab === 'overview' ? 'active' : ''}`}
          onClick={() => handleTabClick('overview')}
        >
          Overview
        </div>
        <div
          className={`property-detail-navbar-item ${activeTab === 'propertyDetails' ? 'active' : ''}`}
          onClick={() => handleTabClick('propertyDetails')}
        >
          Property Details
        </div>
        <div
          className={`property-detail-navbar-item ${activeTab === 'rentalEstimate' ? 'active' : ''}`}
          onClick={() => handleTabClick('rentalEstimate')}
        >
          Rental Estimate
        </div>
        <div
          className={`property-detail-navbar-item ${activeTab === 'propertyTax' ? 'active' : ''}`}
          onClick={() => handleTabClick('propertyTax')}
        >
          Property Tax
        </div>
      </div>
      <div className="property-detail-navbar-right">
        <FontAwesomeIcon icon={faHeart} className="property-detail-navbar-icon" />
        <FontAwesomeIcon icon={faShareSquare} className="property-detail-navbar-icon" />
        <FontAwesomeIcon icon={faPrint} className="property-detail-navbar-icon" />
      </div>
    </div>
  );
};

export default Navbar;
