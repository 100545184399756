import React, { useState, useEffect, useRef } from 'react';
import './CustomeFilter.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
const CustomFilter = ({ options, placeholder, Data, setData }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const dropdownRef = useRef(null);
    const [clicked, setClicked] = useState(false);
    const [checkedOptions, setCheckedOptions] = useState({});

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
        setClicked(true);
        setTimeout(() => setClicked(false), 300); // Reset after animation
    };

    const handleOptionSelect = (option) => {
        setCheckedOptions((prev) => ({
            ...prev,
            [option]: !prev[option], // Toggle the checked state
        }));
        setSearchTerm(option);
        // Perform any additional action when an option is selected
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        // setData(e.target.value);
    };

    return (
        <div className="job-filter" ref={dropdownRef}>
            <button
                onClick={toggleDropdown}
                className={`job-filter-input ${clicked ? 'clicked' : ''}`}
            >
                {placeholder}
            </button>
            <div>
                <FontAwesomeIcon icon={!isOpen ? faCaretDown : faCaretUp} className="dropdown-icon" />
            </div>
            {isOpen && (
                <div className="job-filter-menu">
                    {options.map((option) => (
                        <div
                            key={option.name}
                            onClick={() => handleOptionSelect(option.name)}
                            className="job-filter-item"
                        >
                            <input 
                                type="checkbox" 
                                checked={checkedOptions[option.name] || false} 
                                readOnly 
                            />  
                            {option.name} <span>({option.jobs})</span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default CustomFilter;
