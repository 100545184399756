import React from 'react';
import PropTypes from "prop-types";
import "./EventDetailsSmallerScreen.css"
const EventDetailsSmallerScreen = ({ event }) => {
  return (
    <div className="event-detail-container">
      <div className="event-detail-container-heading">Date-Type</div>
      <div className="event-detail-container-data">{event.dateRange}</div>
      
      <div className="event-detail-container-heading">Event Name</div>
      <div className="event-detail-container-data">{event.eventName}</div>
      
      <div className="event-detail-container-heading">Asset-Type-Total Assets</div>
      <div className="event-detail-container-data">{`${event.eventType} - ${event.assets}`}</div>
    </div>
  );
};
EventDetailsSmallerScreen.propTypes = {
    event: PropTypes.shape({
        dateRange: PropTypes.string.isRequired,
        eventName: PropTypes.string.isRequired,
        eventType: PropTypes.string.isRequired,
        assets: PropTypes.number.isRequired
    }).isRequired
};
export default EventDetailsSmallerScreen;
