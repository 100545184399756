import React, { useState, useEffect } from 'react';
import './Intutive.css';
import intutiveVideo from "../../../commonAssets/IntutiveVideo.mp4";
import  ecosystem from "../assets/ecosystem.webp"
import expansiveListing from "../assets/exapnsive listings.webp"
import remoteBidding from "../assets/remote bidding.webp"
import transparency from "../assets/transparency.webp"
function Intutive() {
  const sections = [
    {
      heading: 'Expansive Listings',
      text: 'Get access to a wide range of properties to find the perfect home or next investment.',
      image:expansiveListing
    },
    {
      heading: 'Remote Bidding',
      text: 'Utilize the remote bidding function to help buyers bid for properties anywhere in the world, at any time.',
      image:remoteBidding
    },
    {
      heading: 'Transparency',
      text: 'Get access to detailed property information and exclusive inventory for transparency.',
      image:transparency
    },
    {
      heading: 'Ecosystem',
      text: 'Participate in auctions from start to finish with seamless integrations on the platform.',
      image:ecosystem
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % sections.length);
    }, 10000);

    return () => clearInterval(interval);
  }, [sections.length]);

  return (
    <div className="intutive-container">
      <h2>Intuitive and easy to use.</h2>
      <div className='intutive-animation-and-video-container'>
      <div className="animation-container">
        {sections.map((section, index) => (
          <div key={index}>
            <h3 className='animation-container-header'
              style={{
                opacity: currentIndex === index ? "1" : ".5"
              }}
              onClick={() => setCurrentIndex(index)}
            >{section.heading}</h3>
            <div className="animated-line">
              <div
                style={{
                  width: currentIndex === index ? '100%' : '0%', // Line animation
                  transition: currentIndex === index ? 'width 10s linear' : 'none',
                }}
              ></div>
            </div>
            <p
              className={currentIndex === index ? 'visible' : 'hidden'} // Control paragraph visibility
            >
              {section.text}
            </p>
          </div>
        ))}
      </div>
      <div className='intutive-video-container'>
          <img src={sections[currentIndex].image}/>
      </div>
      </div>
    </div>
  );
}

export default Intutive;
