

const forcloseData  = [
    {
        category: "",
        questions: [
          {
            question: "How can I participate in a foreclosure auction?",
            answer: `
            On the Vihara.ai website, you can explore foreclosure properties by navigating to the “Auctions” tab in the top navigation bar and selecting “Foreclosure Sales” from the dropdown menu. Once you find a foreclosure property of interest, save it to your dashboard and follow these steps:
                  <ul>
                      <li>
                      Conduct thorough due diligence.
                      </li>
                      <li>
                      Prepare your funds as properties at foreclosure sales often require full payment on the auction day. Check your state's specific requirements.
                      </li>
                      <li>
                      Research the auction venue to confirm location and timing.
                      </li>
                      </ul>
  
                      Through the mobile app, you may have the option to electronically bid on select properties before the auction or participate in real-time bidding. All remote bidders must prequalify beforehand. To do so:
                  <ul>
                      <li>
                      Find a property and conduct your due diligence.
                      </li>
                      <li>On the property details page or in the app's Settings, click on the “Qualify for a Remote Bid” button.
                      </li>
                      <li>
                      Complete the Bidder Profile and Remote Bid agreements using DocuSign within the app.
                      </li>
                      <li>
                      Upon approval, you will receive an email with instructions on funding your escrow account.
                      </li>
                      <li>
                      To place a bid, ensure your funds are available in your account by 2 p.m. (EST) the day before the auction.
                      </li>
                  </ul>
  
            `,
          },
          {
            question: "Do I have to pay the property in full at the auction? ",
            answer:
              "Typically, foreclosure sales require full payment on the auction day using cash or a cashier’s check. Verify your state’s guidelines to determine if partial payment at the auction with the remainder due within a specified period, like 30 days, is permitted.",
          },
          {
            question: "Are these properties free of liens? ",
            answer:
              "It varies. Certain properties may have existing liens, and specific state laws dictate whether these liens transfer to the new owner. It's essential to conduct thorough due diligence by obtaining a comprehensive title report for any property you intend to purchase.",
          },
          {
            question: "Why was this property sale canceled or postponed?",
            answer: `
            There are various reasons why a foreclosure sale might be delayed or canceled. When you save a property to your dashboard, you typically receive an email notification if the sale is postponed or canceled. You can also check the website before heading to the auction. Sales may be postponed or canceled due to reasons such as:
              <ul>
                  <li>
                  Agreement between the homeowner and lender to postpone the sale.
                  </li>
                  <li>
                  Bankruptcy filed by the homeowner, which often results in cancellation until the debt is resolved or the lender obtains court approval to proceed.
                  </li>
                  <li>
                  The lender is selling the property before the auction date.
                  </li>
                  </ul>
            `,
          },
          {
            question:
              "How do I know if a sale is canceled or postponed before I attend the auction?",
            answer:
              "As you browse properties, save the ones that interest you to your dashboard. You'll usually receive an email notification if there are any changes such as postponement or cancellation of the sale. It's also advisable to check the property status on the property details page before heading to the auction.",
          },
          {
            question:
              "Is there a calendar or upcoming auctions page that shows the dates and times of upcoming live auctions?",
            answer: `Certainly! There is indeed a calendar available. Here's how you can access it:
            <ul>
          <li>
          Navigate to the "Auctions calendar" tab located under the “Auctions” section on the homepage's navigation bar.
          </li>
          <li>
          Enter the desired auction dates, location, property type, and venue details. This will generate a list of upcoming auctions for you to view.
          </li>
     
      </ul>`,
          },
        ],
      },

]

export default  forcloseData