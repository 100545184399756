import React, { useState } from 'react';
import './UserProfile.css'; // External CSS for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import Spinner from '../../Spinner/Spinner';
import { useMediaQuery } from '@mui/material';
import { useEffect } from 'react';
const usStates = [
  "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado",
  "Connecticut", "Delaware", "Florida", "Georgia", "Hawaii", "Idaho",
  "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine",
  "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi",
  "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire",
  "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota",
  "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island",
  "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah",
  "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin",
  "Wyoming"
];
const UserProfile = () => {
  const user = useSelector((state) => state.user.user);
  const isSmallScreen=useMediaQuery('(max-width:768px)');
  const [formData, setFormData] = useState(null);
  useEffect(() => {
    if (user) {
      setFormData({
        firstName: user?.name,
        lastName: user?.last_name,
        email: user?.email,
        state: user?.state,
        city: user?.city,
        zipCode: '90001',
        mobile: user?.businessPhone,
        userType: 'Buyer'
      })
    }
  }, [user])
  const [isOpen, setIsOpen] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData); // Handle form submission logic here
  };
  if (!user || !formData) {
    return <Spinner />
  }
  return (
    <div className="portal-profile-container">
      <div className='profile-header-container'><h4 className="portal-profile-header">Profile Information </h4><a href="#">Download Your Information</a></div>
      <div className='portal-horizontal-line'></div>
      <form onSubmit={handleSubmit} className="portal-profile-form">
        <div className='user-profile-input-row'>
          <div className="portal-profile-row">
            <div className='portal-profile-label-container' style={{ width: isSmallScreen?"23%":"18%" }}>
              <label>I am a </label></div>
            <div className='buyer-agent-container'>
              <div>
                <input
                  type="radio"
                  name="userType"
                  value="Buyer"
                  checked={formData?.userType === 'Buyer'}
                  onChange={handleChange}
                  style={{marginLeft:"0px"}}
                />
                <label>Buyer</label>
              </div>
              <div>
                <input
                  type="radio"
                  name="userType"
                  value="Agent"
                  checked={formData.userType === 'Agent'}
                  onChange={handleChange}
                />
                <label>Agent</label>
              </div>
            </div>
          </div>
        </div>
        <div className='user-profile-input-row'>
          <div className='portal-profile-row'>
            <div className='portal-profile-label-container'>  <label>First Name <span>*</span></label></div>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
          </div>
          <div className='portal-profile-row'>
            <div className='portal-profile-label-container'> <label>Last Name <span>*</span></label></div>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
          </div>
        </div>


        <div className='user-profile-input-row'>
          <div className="portal-profile-row">
            <div className='portal-profile-label-container'>  <label>Email <span>*</span></label></div>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className='portal-profile-row'>
            <div className='portal-profile-label-container' style={{ visibility: "hidden" }}>  <label>Email <span>*</span></label></div>
            <a href="#" className="portal-profile-change-email">
              Change Email
            </a>
          </div>
        </div>
        <div className='user-profile-input-row'>
          <div className="portal-profile-row">
            <div className='portal-profile-label-container'><label>State <span>*</span></label></div>
            <div className="profile-state-select">
              <button
                className={`profile-state-select-input`}
                onClick={toggleDropdown}
              >
                California
                <FontAwesomeIcon icon={!isOpen ? faCaretDown : faCaretUp} className="dropdown-icon" />
              </button>
              {isOpen && (
                <div className="profile-state-select-menu">
                  {usStates.map((option) => (
                    <div
                      key={option}
                      className="profile-state-select-item"
                    >
                      {option}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="portal-profile-row">
            <div className='portal-profile-label-container'><label>City <span>*</span></label></div>
            <input
              type="text"
              name="city"
              value={formData.city}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className='user-profile-input-row'>
          <div className="portal-profile-row">
            <div className='portal-profile-label-container'> <label>Zip Code <span>*</span></label></div>
            <input
              type="text"
              name="zipCode"
              value={formData.zipCode}
              onChange={handleChange}
              required
            />
          </div>
          <div className="portal-profile-row">
            <div className='portal-profile-label-container'> <label>Mobile <span>*</span></label></div>
            <input
              type="text"
              name="mobile"
              value={formData.mobile}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="user-profile-input-row">
            <div className="portal-profile-row" style={{marginTop:"0rem",marginBottom:"0rem"}}>
              <p className="portal-profile-disclaimer">
                By submitting this form you are agreeing to Vihara&apos;s{" "}
                <a href="/website-terms-of-use">terms of service</a> and{" "}
                <a href="/privacy-statement">privacy policy</a>. Contact{" "}
                <a href="mailto:trisha@vihara.ai" className="trisha-email-link">trisha@vihara.ai</a> with
                any questions.
              </p>
              <p className="portal-profile-disclaimer">
                I agree that Vihara may contact me about their products and
                services.
              </p>
            </div>
          </div>
        <div className="portal-profile-row">
          <div className='portal-profile-label-container'></div>
          <button type="submit" className="portal-profile-submit-btn">
            Submit
          </button>
        </div>
        <p className="portal-profile-get-support">
            Need support?{" "}
            <a href="mailto:trisha@vihara.ai">Go here instead.</a>
          </p>
      </form >
    </div >
  );
};

export default UserProfile;
