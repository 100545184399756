import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Menu,
  MenuItem,
  Checkbox,
  ListItemText,
  useMediaQuery,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import "../BuyNavbar.css"
const CustomDropdown = ({ label, options, selectedValues, onSelect }) => {
  const isScreenSmall = useMediaQuery('(max-width:1150px)');
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (option) => {
    const currentIndex = selectedValues.indexOf(option);
    const newSelectedValues = [...selectedValues];
    if (currentIndex === -1) {
      newSelectedValues.push(option);
    } else {
      newSelectedValues.splice(currentIndex, 1);
    }
    onSelect(newSelectedValues);
  };

  const customFontFamily = {
    fontFamily: '"Roboto", Helvetica, Arial, sans-serif',
  };

  return (
    <>
      <Button
        onClick={handleClick}
        variant="outlined"
        style={{
          padding: '4px 9px',
          fontSize: '16px',
          borderColor: '#000000DE',
          fontWeight: '300',
          color: '#000000DE',
          textTransform: "none",
          width: isScreenSmall ? '98%' : '130px',
          display: "flex",
          justifyContent: "space-between",
          ...customFontFamily,
        }}
      >
        <span style={{ minWidth: "90px",textAlign:"left" }}>{label}</span> <FontAwesomeIcon style={{ marginLeft: '5px', color: "#000000DE" }} icon={faCaretDown} />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{ marginTop: '10px', ...customFontFamily }}
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            onClick={() => handleMenuItemClick(option)}
            style={{ paddingTop: "0px", paddingBottom: "0px", ...customFontFamily, height: "35px", paddingLeft: "0px" }}
          >
            <Checkbox checked={selectedValues?.indexOf(option) > -1} />
            {/* {label === "Asset Type" && <div style={{ backgroundColor: option === "Bank Owned" ? "#0384FB" : option === "Foreclosure" ? "#8b0000" : "green" }} className='house-circle-icon' ></div>} */}
            <div className='more-filter-dropddown-below-text'><span>{option}</span></div>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

CustomDropdown.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedValues: PropTypes.arrayOf(PropTypes.string),
  onSelect: PropTypes.func.isRequired,
};

export default CustomDropdown;
