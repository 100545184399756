import React from 'react';
import './adminLogin.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useState } from 'react';
import { setadmin } from '../../features/admin/adminSlice';
import { useDispatch } from 'react-redux';
const AdminLogin = () => {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState()
    const dispatch=useDispatch();
    const navigate  = useNavigate();
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!email || !password) {
            return;
        }
        try {
            const response = await axios.post("https://viharabackend.onrender.com/api/v1/admin/login", {
                email,
                password
            })
            console.log("hi");
            dispatch(setadmin(response.data.user))
            navigate("/admin/dashboard");
        } catch (error) {
            console.log(error);
        }

    }
    return (
        <div className="admin-login-container">
            <div className="admin-login-box">
                <h2>Admin Login</h2>
                <form onSubmit={handleSubmit} >
                    <div className="admin-input-group">
                        <label htmlFor="email">Email Address</label>
                        <input type="email" id="email" name="email" required onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className="admin-input-group">
                        <label htmlFor="password">Password</label>
                        <input type="password" id="password" name="password" required onChange={(e) => setPassword(e.target.value)} />
                    </div>
                    <button type="submit" className="admin-login-button">Login</button>
                    <div className="admin-forgot-password">
                        <a href="#">Forgot Password</a>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AdminLogin;
