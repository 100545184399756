import { useEffect } from 'react';
import { useDispatch } from 'react-redux'
import { jwtDecode } from 'jwt-decode';
import { setUser } from '../../features/user/userSlice';
import { Box,CircularProgress } from '@mui/material';
const AuthSuccess = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');
    console.log(token);
    if (token) {
      // Decode the token to get user data
      localStorage.setItem("token", token);
      window.location.href = "/";
    } else {
      console.error('Authentication failed: No token found in the URL');
    }
  }, [dispatch, history]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '85vh',
      }}
    >
      <CircularProgress size={100} />
    </Box>
  );
};

export default AuthSuccess;
