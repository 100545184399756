import React from 'react'
import Buys from '../Buys'
function ShortSales() {
  return (
    <>
      <Buys/>
    </>
  )
}

export default ShortSales
