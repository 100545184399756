import React from 'react'
import contactusimage from "../assets/1.png";
import ContactUsModal from './ContactUsModal';
import "./ContactUs.css"
import { useState } from 'react';
function ContactUs() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => {
        setIsModalOpen(true);
      };
      const closeModal = () => {
        console.log("hi in close modal of footer contact");
        setIsModalOpen(false);
      };
  return (
    <>
    <div className="contact-us">
                <div className="contact-us-image-container">
                    <img src={contactusimage} alt="Contact Us" />
                    <div className="overlay"></div>
                </div>
                <div className="contact-us-inner-content">
                    <h1 className='context-text-white-color'>Ready to start bidding?</h1>
                    <p className='context-text-white-color context-text-para'>We’re here to help make bidding easier.</p>
                    <button className='contact-button' onClick={openModal}>Contact Us</button>
                </div>
      </div>
      <ContactUsModal isOpen={isModalOpen} setIsModalOpen={setIsModalOpen} closeModal={closeModal} />
      </>
  )
}

export default ContactUs
