import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import "./Jobs.css";
import NavBackground from '../../includes/navBackground';
import banner from "./assets/JobBanner.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import CustomFilter from './CustomFilter';
import { Drawer, Button, IconButton } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

const departments = [
    { name: 'Business Technology', jobs: 0 },
    { name: 'Customer Success', jobs: 0 },
    { name: 'Early Career', jobs: 0 },
    { name: 'Finance', jobs: 0 },
    { name: 'Growth', jobs: 0 },
    { name: 'Legal', jobs: 0 },
    { name: 'Marketing', jobs: 0 }
];

const locations = [
    { name: 'San Jose', jobs: 0 },
    { name: 'Delhi', jobs: 0 },
    { name: 'Remote', jobs: 0 },
];

function Jobs() {
    window.scrollTo(0, 0);
    return (
        <div className='jobs-page-container'>
            <Helmet>
                <title>Careers at Vihara AI - Join Our Real Estate AI Revolution</title>
                <meta
                    name="description"
                    content="Seeking a career in AI and real estate? Vihara AI offers opportunities to innovate in the real estate auction industry. Apply today."
                />
                <meta
                    name="keywords"
                    content="Vihara AI careers, job opportunities, real estate AI jobs, innovation careers"
                />
            </Helmet>
            <NavBackground />
            <div className='job-banner'>
                <img src={banner} alt="Job Banner" />
                <div className='job-banner-text'>
                    <h1>Open Positions</h1>
                    <p>At Vihara, our goal is to make bidding simpler. Join us to make a lasting impact.</p>
                </div>
            </div>
            <JobFilter />
            <p className='job-openings-detail'>
                Sorry, we don’t have any positions right now. But keep checking in for future opportunities.
            </p>
        </div>
    )
}

const JobFilter = () => {
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const isSmallScreen = useMediaQuery('(max-width: 938px)');

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    return (
        <div className="jobs-filter-container">
            <div className='jobs-search-bar'>
                <FontAwesomeIcon icon={faSearch} style={{ color: "rgb(3 132 251)" }} />
                <input type="text" placeholder='Search' />
            </div>
            {isSmallScreen ? (
                <>
                    <Button variant="contained" color="primary" onClick={toggleDrawer(true)}>
                        More Filters
                    </Button>
                    <Drawer
                        anchor="right"
                        open={isDrawerOpen}
                        onClose={toggleDrawer(false)}
                    >
                        <div className="jobs-drawer-content">
                            <CustomFilter options={departments} placeholder={"Departments"} />
                            <CustomFilter options={locations} placeholder={"Locations"} />
                            <CustomFilter options={[{ name: "Full-Time", jobs: "0" }, { name: "Intern", jobs: "0" }, { name: "Fixed Term", jobs: "0" }]} placeholder={"Types"} />
                        </div>
                    </Drawer>
                </>
            ) : (
                <div className='jobs-drawer-filters'>
                    <CustomFilter options={departments} placeholder={"Departments"} />
                    <CustomFilter options={locations} placeholder={"Locations"} />
                    <CustomFilter options={[{ name: "Full-Time", jobs: "0" }, { name: "Intern", jobs: "0" }, { name: "Fixed Term", jobs: "0" }]} placeholder={"Types"} />
                </div>
            )}
        </div>
    )
};

export default Jobs;
