import React from 'react';
import { Outlet } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import NavBar from '../components/layouts/Header/NavBar';
import Footer from '../components/layouts/Footer/Footer';
import SmallScreenNavbar from '../components/layouts/Header/SmallScreenNavbar';
const UserLayout = () => {
  const isScreenSmall = useMediaQuery("(max-width:1027px)");
  return (
    <>
      {isScreenSmall ? <SmallScreenNavbar /> : <NavBar />}
      <Outlet />
      <Footer />
    </>
  )
};

export default UserLayout;
