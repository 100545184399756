import React from 'react'
import "./confirm.css"
import { Images } from '../../../../constants/constant'
import NavBackground from '../../../includes/navBackground'
const ConformationRequest = () => {
  return (
    <>
      <NavBackground />
      <div className="password-reset-confirmation-container">
        <div>
          <img src={Images.logoOnDark} alt="Vihara Logo" className="logo" />
          <h2>Password reset request received!</h2>
        </div>
        <div className='conformation-para-part'>
          <p>
          We have received your password reset request. If an account 
          exists with the email address you provided in our system, we will send you an 
          email with instructions on how to reset your Account password.
           If you do not receive an email within thirty minutes, please contact Vihara’s <a href="mailto:trisha@vihara.ai">Technical Support</a> team for further assistance.
          </p>
          <a href="/login" className="return-button">Return to Sign In</a>
        </div>
      </div>

    </>
  )
}

export default ConformationRequest