import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
   faCaretUp,
  faCaretDown,
  faTimes,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import "./NavBar.css";
import { Images } from "../../../constants/constant";
import { useNavigate, useLocation } from "react-router-dom";
import { useFilterContext } from "../../../ContextProvider/Context";
import AlertDrawer from "../../AlertDrawer/AlertDrawer";
import { useDispatch } from "react-redux";
import { clearUser } from "../../../features/user/userSlice";
import { useSelector } from "react-redux";
const NavBar = () => {
  const { setAssetType } = useFilterContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [scrolled, setScrolled] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [activeMenu, setActiveMenu] = useState("");
  const [navbarDropdownOpen, setNavbarDropdownOpen] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const user = useSelector((state) => state.user.user);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const openDrawer = () => {
    setDrawerOpen(true);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  const truncateName = (name) => {
    return name.length > 6 ? name.slice(0, 6) + "..." : name;
  };

  const toggleMenu = () => {
    handleMenuClick("user-menu");
    setActiveMenu("")
    setNavbarDropdownOpen(!navbarDropdownOpen);
    setShowMenu(!showMenu);
  };

  const handleClose = (tab) => {
    // navigate("/portal",{state:{tab:tab}});
     window.location.href=`/portal/${tab}`;
  };

  const [hoveredOnIcon, setHoveredOnIcon] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
    8: false,
    9: false,
    10: false,
    11: false,
  });

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handleMenuClick = (menu) => {
    setActiveMenu((prevMenu) => (prevMenu === menu ? "" : menu));
  };

  const handleCloseClick = () => {
    setActiveMenu("");
  };

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 50;
      setScrolled(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleClick = (asset) => {
    if (asset !== "explore-more") {
      setAssetType([asset]);
    }
    if (asset === "Bank Owned") {
      navigate("/Bank-Owned");
    }
    if (asset === "Short Sales") {
      navigate("/Short-Sales");
    }
    if (asset === "Foreclosure") {
      navigate("/Foreclosure");
    }
    if (asset === "explore-more") {
      navigate("/all");
    }
  };
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(clearUser());
    localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <nav
      className="nav-hovered"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="wrapper">
        <div className="logo">
          <a onClick={() => navigate("/")}>
            <img
              className="logo-small"
              src={
                Images.logoOnDark
              }
              alt="Vihara Logo"
            />
          </a>
        </div>
        <ul className="navbar-menu">
          <li
            className={`navbar-item ${activeMenu === "auctions" ? "active" : ""
              }`}
            role="button"
            tabIndex={0}
            onClick={() => handleMenuClick("auctions")}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                handleMenuClick("auctions");
              }
            }}
          >
            <span className="nav-bar-menu-header">
              Auctions{" "}
              <FontAwesomeIcon
                icon={activeMenu === "auctions" ? faCaretUp: faCaretDown}
                className="icon-margin"
              />
            </span>
          </li>
          <li
            className={`navbar-item ${activeMenu === "resources" ? "active" : ""
              }`}
            role="button"
            tabIndex={0}
            onClick={() => handleMenuClick("resources")}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                handleMenuClick("resources");
              }
            }}
          >
            <span className="nav-bar-menu-header">
              Resources{" "}
              <FontAwesomeIcon
                icon={activeMenu === "resources" ?  faCaretUp : faCaretDown}
                className="icon-margin"
              />
            </span>
          </li>
          <li
            className={`navbar-item ${activeMenu === "about" ? "active" : ""}`}
            role="button"
            tabIndex={0}
            onClick={() => handleMenuClick("about")}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                handleMenuClick("about");
              }
            }}
          >
            <span className="nav-bar-menu-header">
              About{" "}
              <FontAwesomeIcon
                icon={activeMenu === "about" ?  faCaretUp : faCaretDown}
                className="icon-margin"
              />
            </span>
          </li>
          <li
            className={`navbar-item ${activeMenu === "sell" ? "active" : ""}`}
            role="button"
            tabIndex={0}
            onClick={() => handleMenuClick("sell")}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") { handleMenuClick("sell"); }
              {
                handleMenuClick("sell");
              }
            }}
          >
            <span className="nav-bar-menu-header">
              <a className="nav-sell-tag">Sell{" "}
                <FontAwesomeIcon
                  icon={activeMenu === "sell" ?  faCaretUp : faCaretDown}
                  className="icon-margin"
                />
              </a>
            </span>
          </li>
        </ul>
        <div className="login-signUp">
          {location.pathname !== "/glossary" && (
            <>
              {user ? (
                <div className="navbar-dropdown" style={{paddingLeft:"100px"}}>
                  <button
                    onClick={toggleMenu}
                    className="navbar-dropdown-button"
                  >
                   {truncateName(user.name)}
                    <FontAwesomeIcon
                      icon={
                        navbarDropdownOpen === false
                          ? faCaretDown
                          :  faCaretUp
                      }
                      className="icon-margin"
                    />
                  </button>
                  <div
                    className={`navbar-dropdown-content ${showMenu ? "show" : ""
                      }`}
                  >
                    <a  onClick={()=>handleClose("profile")}>
                     My Profile              </a>
                    <a  onClick={()=>handleClose("bids")}>
                      My Bids
                    </a>
                    <a  onClick={()=>handleClose("purchase")}>
                      My Purchase Profiles
                    </a>
                    <a  onClick={()=>handleClose("offers")}>
                    My Offers
                    </a>
                    <a  onClick={()=>handleClose("savedSearches")}>
                       Saved Searches
                    </a>
                    <a  onClick={()=>handleClose("favorites")}>
                      Favorites
                    </a>
                    <a  onClick={()=>handleClose("settings")}>
                      Account Settings
                    </a>
                    <a onClick={openDrawer}>
                      Alerts
                    </a>
                    <a onClick={handleLogout}>
                       Sign Out
                    </a>
                  </div>
                </div>
              ) : (
                <>
                  <a
                    onClick={() => navigate("/login")}
                    className={scrolled ? "nav-scrolled login" : "login"}
                  >
                    LOGIN
                  </a>
                  <a onClick={() => navigate("/sign-up")} className="Sign-Up">
                    SIGN UP
                  </a>
                </>
              )}
            </>
          )}
        </div>

        {activeMenu && (
          <div className="dropdown-menu">
            <button
              className="close-button"
              onClick={handleCloseClick}
              aria-label="Close dropdown"
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
            {activeMenu === "auctions" && (
              <div className="dropdown-content">
                <div className="type-of-auctions">
                  <div className="nav-drop-header">Types of Auctions</div>
                  <div className="nav-drop-items">
                    <a
                      href="/Bank-Owned"
                      onMouseOver={() => setHoveredOnIcon({ 1: true })}
                      onMouseLeave={() => setHoveredOnIcon({ 1: false })}
                      onFocus={() => setHoveredOnIcon({ 1: true })}
                      onBlur={() => setHoveredOnIcon({ 1: false })}
                      className="nav-drop-item"
                    >
                      REO Bank Owned{" "}
                      <FontAwesomeIcon
                        className="resources-my-icons"
                        icon={hoveredOnIcon[1] ? faArrowRight : " "}
                      />
                    </a>
                    <a
                      onClick={() => navigate("/Short-Sales")}
                      onMouseOver={() => setHoveredOnIcon({ 2: true })}
                      onMouseLeave={() => setHoveredOnIcon({ 2: false })}
                      onFocus={() => setHoveredOnIcon({ 2: true })}
                      onBlur={() => setHoveredOnIcon({ 2: false })}
                      className="nav-drop-item"
                    >
                      Short Sales{" "}
                      <FontAwesomeIcon
                        className="resources-my-icons"
                        icon={hoveredOnIcon[2] ? faArrowRight : " "}
                      />
                    </a>
                    <a
                      onClick={() => navigate("/Foreclosure")}
                      onMouseOver={() => setHoveredOnIcon({ 3: true })}
                      onMouseLeave={() => setHoveredOnIcon({ 3: false })}
                      onFocus={() => setHoveredOnIcon({ 3: true })}
                      onBlur={() => setHoveredOnIcon({ 3: false })}
                      className="nav-drop-item"
                    >
                      Foreclosure Sales{" "}
                      <FontAwesomeIcon
                        className="resources-my-icons"
                        icon={hoveredOnIcon[3] ? faArrowRight : " "}
                      />
                    </a>
                    <a
                      onClick={() => navigate("/Foreclosure")}
                      onMouseOver={() => setHoveredOnIcon({ 4: true })}
                      onMouseLeave={() => setHoveredOnIcon({ 4: false })}
                      onFocus={() => setHoveredOnIcon({ 4: true })}
                      onBlur={() => setHoveredOnIcon({ 4: false })}
                      className="nav-drop-item"
                    >
                      Explore all Properties{" "}
                      <FontAwesomeIcon
                        className="resources-my-icons"
                        icon={hoveredOnIcon[4] ? faArrowRight : " "}
                      />
                    </a>
                  </div>
                </div>
                <div className="type-of-auctions">
                  <div className="nav-drop-header">Calendar</div>
                  <div className="nav-drop-items">
                    <a
                      href="/event-calender"
                      onMouseOver={() => setHoveredOnIcon({ 5: true })}
                      onMouseLeave={() => setHoveredOnIcon({ 5: false })}
                      onFocus={() => setHoveredOnIcon({ 5: true })}
                      onBlur={() => setHoveredOnIcon({ 5: false })}
                      className="nav-drop-item"
                    >
                      Auctions Calendar{" "}
                      <FontAwesomeIcon
                        className="resources-my-icons"
                        icon={hoveredOnIcon[5] ? faArrowRight : " "}
                      />
                    </a>
                  </div>
                </div>
              </div>
            )}
            {activeMenu === "resources" && (
              <div className="dropdown-content">
                <div className="type-of-auctions">
                  <div className="nav-drop-header">Resources</div>
                  <div className="nav-drop-items">
                    <a
                      onClick={() => navigate("/blog")}
                      onMouseOver={() => setHoveredOnIcon({ 6: true })}
                      onMouseLeave={() => setHoveredOnIcon({ 6: false })}
                      onFocus={() => setHoveredOnIcon({ 6: true })}
                      onBlur={() => setHoveredOnIcon({ 6: false })}
                      className="nav-drop-item"
                    >
                      Blog{" "}
                      <FontAwesomeIcon
                        className="resources-my-icons"
                        icon={hoveredOnIcon[6] ? faArrowRight : " "}
                      />
                    </a>

                    <a
                      onClick={() => navigate("/glossary")}
                      onMouseOver={() => setHoveredOnIcon({ 7: true })}
                      onMouseLeave={() => setHoveredOnIcon({ 7: false })}
                      onFocus={() => setHoveredOnIcon({ 7: true })}
                      onBlur={() => setHoveredOnIcon({ 7: false })}
                      className="nav-drop-item"
                    >
                      Glossary{" "}
                      <FontAwesomeIcon
                        className="resources-my-icons"
                        icon={hoveredOnIcon[7] ? faArrowRight : " "}
                      />
                    </a>

                    {/* <a
                      href="/guide"
                      onMouseOver={() => setHoveredOnIcon({ 8: true })}
                      onMouseLeave={() => setHoveredOnIcon({ 8: false })}
                      onFocus={() => setHoveredOnIcon({ 8: true })}
                      onBlur={() => setHoveredOnIcon({ 8: false })}
                      className="nav-drop-item"
                    >
                      How-to Guides{" "}
                      <FontAwesomeIcon
                        className="resources-my-icons"
                        icon={hoveredOnIcon[8] ? faArrowRight : " "}
                      />
                    </a> */}
                    <a
                      onClick={() => navigate("/faqs")}
                      onMouseOver={() => setHoveredOnIcon({ 9: true })}
                      onMouseLeave={() => setHoveredOnIcon({ 9: false })}
                      onFocus={() => setHoveredOnIcon({ 9: true })}
                      onBlur={() => setHoveredOnIcon({ 9: false })}
                      className="nav-drop-item"
                    >
                      FAQs{" "}
                      <FontAwesomeIcon
                        className="resources-my-icons"
                        icon={hoveredOnIcon[9] ? faArrowRight : " "}
                      />
                    </a>
                    <a
                      href="/buying_a_forclosure"
                      onMouseOver={() => setHoveredOnIcon({ 16: true })}
                      onMouseLeave={() => setHoveredOnIcon({ 16: false })}
                      onFocus={() => setHoveredOnIcon({ 16: true })}
                      onBlur={() => setHoveredOnIcon({ 16: false })}
                      className="nav-drop-item"
                    >
                      Buying a Foreclosure{" "}
                      <FontAwesomeIcon
                        className="resources-my-icons"
                        icon={hoveredOnIcon[16] ? faArrowRight : " "}
                      />
                    </a>
                    <a
                      href="/buying_a_bank_owned"
                      onMouseOver={() => setHoveredOnIcon({ 17: true })}
                      onMouseLeave={() => setHoveredOnIcon({ 17: false })}
                      onFocus={() => setHoveredOnIcon({ 17: true })}
                      onBlur={() => setHoveredOnIcon({ 17: false })}
                      className="nav-drop-item"
                    >
                      Buying a Bank-Owned{" "}
                      <FontAwesomeIcon
                        className="resources-my-icons"
                        icon={hoveredOnIcon[17] ? faArrowRight : " "}
                      />
                    </a>
                  </div>
                </div>
                <div className="type-of-auctions">
                  <div className="nav-drop-header">Support</div>
                  <div className="nav-drop-items">
                    <a
                      href="/contact-us"
                      onMouseOver={() => setHoveredOnIcon({ 10: true })}
                      onMouseLeave={() => setHoveredOnIcon({ 10: false })}
                      onFocus={() => setHoveredOnIcon({ 10: true })}
                      onBlur={() => setHoveredOnIcon({ 10: false })}
                      className="nav-drop-item"
                    >
                      Contact Us{" "}
                      <FontAwesomeIcon
                        className="resources-my-icons"
                        icon={hoveredOnIcon[10] ? faArrowRight : " "}
                      />
                    </a>

                    {/* <a
                      href="/#"
                      onMouseOver={() => setHoveredOnIcon({ 11: true })}
                      onMouseLeave={() => setHoveredOnIcon({ 11: false })}
                      onFocus={() => setHoveredOnIcon({ 11: true })}
                      onBlur={() => setHoveredOnIcon({ 11: false })}
                      className="nav-drop-item"
                    >
                      Seller Portal{" "}
                      <FontAwesomeIcon
                        className="resources-my-icons"
                        icon={hoveredOnIcon[11] ? faArrowRight : " "}
                      />
                    </a> */}

                    {/* <a
                      href="/#"
                      onMouseOver={() => setHoveredOnIcon({ 12: true })}
                      onMouseLeave={() => setHoveredOnIcon({ 12: false })}
                      onFocus={() => setHoveredOnIcon({ 12: true })}
                      onBlur={() => setHoveredOnIcon({ 12: false })}
                      className="nav-drop-item"
                    >
                      Buyer Portal{" "}
                      <FontAwesomeIcon
                        className="resources-my-icons"
                        icon={hoveredOnIcon[12] ? faArrowRight : " "}
                      />
                    </a> */}
                  </div>
                </div>
              </div>
            )}
            {activeMenu === "about" && (
              <div className="dropdown-content">
                <div className="type-of-auctions">
                  <div className="nav-drop-header">Company</div>
                  <div className="nav-drop-items">
                    <a
                      href="/about-us"
                      onMouseOver={() => setHoveredOnIcon({ 13: true })}
                      onMouseLeave={() => setHoveredOnIcon({ 13: false })}
                      onFocus={() => setHoveredOnIcon({ 13: true })}
                      onBlur={() => setHoveredOnIcon({ 13: false })}
                      className="nav-drop-item"
                    >
                      About Us{" "}
                      <FontAwesomeIcon
                        className="resources-my-icons"
                        icon={hoveredOnIcon[13] ? faArrowRight : " "}
                      />
                    </a>

                    <a
                      href="/diversity"
                      onMouseOver={() => setHoveredOnIcon({ 14: true })}
                      onMouseLeave={() => setHoveredOnIcon({ 14: false })}
                      onFocus={() => setHoveredOnIcon({ 14: true })}
                      onBlur={() => setHoveredOnIcon({ 14: false })}
                      className="nav-drop-item"
                    >
                      Diversity{" "}
                      <FontAwesomeIcon
                        className="resources-my-icons"
                        icon={hoveredOnIcon[14] ? faArrowRight : " "}
                      />
                    </a>

                    <a
                      href="/Privacy"
                      onMouseOver={() => setHoveredOnIcon({ 18: true })}
                      onMouseLeave={() => setHoveredOnIcon({ 18: false })}
                      onFocus={() => setHoveredOnIcon({ 18: true })}
                      onBlur={() => setHoveredOnIcon({ 18: false })}
                      className="nav-drop-item"
                    >
                      Privacy{" "}
                      <FontAwesomeIcon
                        className="resources-my-icons"
                        icon={hoveredOnIcon[18] ? faArrowRight : " "}
                      />
                    </a>

                  </div>
                </div>
                <div className="type-of-auctions">
                  <div className="nav-drop-header">Careers</div>
                  <div className="nav-drop-items">
                    <a
                      href="/career"
                      onMouseOver={() => setHoveredOnIcon({ 15: true })}
                      onMouseLeave={() => setHoveredOnIcon({ 15: false })}
                      onFocus={() => setHoveredOnIcon({ 15: true })}
                      onBlur={() => setHoveredOnIcon({ 15: false })}
                      className="nav-drop-item"
                    >
                      Vihara Jobs{" "}
                      <FontAwesomeIcon
                        className="resources-my-icons"
                        icon={hoveredOnIcon[15] ? faArrowRight : " "}
                      />
                    </a>
                  </div>
                </div>
              </div>
            )}

            {activeMenu === "sell" && (
              <div className="dropdown-content">
                <div className="type-of-auctions">
                  <div className="nav-drop-header">Sell</div>
                  <div className="nav-drop-items">
                    <a
                      onClick={() => window.location.href = "/sell_properties"}
                      onMouseOver={() => setHoveredOnIcon({ 16: true })}
                      onMouseLeave={() => setHoveredOnIcon({ 16: false })}
                      onFocus={() => setHoveredOnIcon({ 16: true })}
                      onBlur={() => setHoveredOnIcon({ 16: false })}
                      className="nav-drop-item"
                    >
                      Sell Your Property{" "}
                      <FontAwesomeIcon
                        className="resources-my-icons"
                        icon={hoveredOnIcon[16] ? faArrowRight : " "}
                      />
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <AlertDrawer isDrawerOpen={isDrawerOpen} closeDrawer={closeDrawer} />
    </nav>
  );
};

export default NavBar;
