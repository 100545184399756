import React from 'react'
import HeroSection from './HeroSection/HeroSection'
import NavBackground from '../includes/navBackground'
import LeadershipMembers from './LeadershipMembers/LeadershipMembers'
function LeaderShip() {
    return (
        <div>
            <NavBackground />
            <HeroSection />
            <LeadershipMembers/>
        </div>
    )
}
export default LeaderShip
