import logoOnDark from "../assets/images/logo/vihara-new-logo.jpeg"
import logoOnlight from "../assets/images/logo/vihara-logo-light.png"
import VinayakGupta from "../assets/images/vihara/image74.png";
import RishabGupta from "../assets/images/vihara/image60.png";
import TrishaSoin from "../assets/images/vihara/image99AS.png";
import Architect from "../assets/images/vihara/image4.png"
import Bhanu from '../assets/images/vihara/sakfaji.png'
import TrishaBlog from "../assets/images/user/trisha.png"

export const Images ={
    logoOnDark,
    logoOnlight,
    VinayakGupta,
    RishabGupta,
    TrishaSoin,
    Architect,
    Bhanu,
    TrishaBlog,
}