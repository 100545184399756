import React, { useState } from 'react';
import './LeadershipMember.css';
import rishabhImage from "../assets/rishab image.png"
import trisha from "../assets/trisha image.png"
import vinayak from "../assets/vinayak image .png"
import neeraj from "../assets/neeraj image.png"
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
const LeadershipMembers = () => {
    const [activeTab, setActiveTab] = useState('management');
    const [selectedMember, setSelectedMember] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = (member) => {
        setSelectedMember(member);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedMember(null);
    };

    const managementMembers = [
        {
            name: 'Vinayak Gupta',
            title: 'CEO & Co-Founder',
            image: vinayak,
            description: `
            <p>Vinayak Gupta is the CEO and co-founder of Vihara, an online auctioneering platform that allows buyers and sellers to harness AI-driven technology to make actionable insights when participating in distressed property auctions. He co-founded the company with the mission to redefine the efficiency, transparency, and innovation of the operations that power distressed property auctions. The Vihara platform is a leading marketplace for auctions and supports tens of thousands of customers. Vihara has been recognized as a leader in conducting online auctions for distressed properties in the United States.</p>
            <p>Prior to Vihara, Vinayak held key positions at leading technology companies. Vinayak was a seasoned sales and product management professional as a Senior Sales Engineer at Schneider Electric, where he leveraged his extensive technical and business acumen to drive significant revenue growth and expand market share. At Samsara Inc., Vinayak led a large technical sales team, capturing customer requirements and driving the implementation of Samsara’s hardware and software solutions, resulting in significant revenue impact. His earlier experience includes managing mission control operations for Project Loon at Google X, where he coordinated complex launch and landing activities for a global fleet of internet balloons.</p>
            <p>Vinayak's academic background includes a Master of Science in Technology Management from Columbia University and a Bachelor of Science in Aerospace Engineering from Purdue University. Vinayak resides with his family in San Jose, California, and is passionate about leveraging technology to create impactful solutions.</p>
        `
        },
        {
            name: 'Trisha Soin',
            title: 'COO & Co-Founder',
            image: trisha,
            description: `
                 <p>Trisha is the COO and Co-Founder of Vihara, an online auctioneering platform that allows buyers and sellers to harness AI-driven technology to make actionable insights when participating in distressed property auctions. She co-founded the company with the mission to redefine the efficiency, transparency, and innovation of the operations that power distressed property auctions. She is responsible for Vihara’s disruptive technology that makes participating in auctions simpler and easier.</p>
                  <p>Prior to co-founding Vihara, Trisha was a Program Manager, leading pivotal programs at Hill Physicians Medical Group. With a focus on harnessing data-driven insights to optimize patient care, Trisha significantly enhanced program efficiency and launched innovative digital health tools, improving accessibility to healthcare for patients. At Abbott Diabetes Care, Trisha focused on developing marketing education programs that elevated brand positioning and improved product adoption of a Class 2 medical device for diabetes.</p>
                  <p>Trisha holds a Master of Science in Public Health from the University of Pennsylvania and a Bachelor of Science in Nursing from the University of San Francisco. Her skills in product strategy, systems analysis, and regulatory compliance management position her as a leader in driving impactful solutions. Trisha resides in San Jose, California with her family and is driven to make bidding simpler and easier.</p>
            `
        },
        {
            name: 'Rishab Gupta',
            title: 'COO & Co-Founder',
            image: rishabhImage,
            description: `
                        <p>Rishab is the CTO of Vihara, an online auctioneering platform that allows buyers and sellers to harness AI-driven technology to make actionable insights when participating in distressed property auctions. He is responsible for Vihara’s disruptive technology that makes participating in auctions simpler and easier.</p>

<p>Prior to working at Vihara, Rishab was a Product Manager at Advantage Capital, with a proven track record of leading innovative software solutions. He led the end-to-end lifecycle of a full-stack software solution, digitizing manual operations. Prior to this role, Rishab was a Data Analyst Intern at Impact Venture Capital, a leading AI-investing VC firm.</p>

<p>Rishab holds a Master of Science in Design from Harvard University and a Bachelor of Science in Computer Science and Statistics from UC Davis. He currently resides in San Jose, CA with his family.</p>

            `
        },
        {
            name: 'Neeraj Gupta',
            title: 'CFO',
            image: neeraj,
            description: `
              <p>Neeraj is the Chief Financial Officer at Vihara and leads worldwide financial operations, including financial planning, accounting, financial analysis, procurement, treasury, corporate development, tax, legal, IT, and real estate. Previously, Neeraj was the Head of Finance at RangiLalls where he led several finance functions, including financial planning, treasury, tax, real estate, and corporate development.</p>
             <p>Neeraj holds an MBA from the University of Akron and a Bachelor of Science in Business Administration from the University of Delhi.</p>

            `
        }
    ];

    const boardMembers = [
        {
            name: 'Vinayak Gupta',
            title: 'CEO & Co-Founder',
            image: vinayak,
            description: `
            <p>Vinayak Gupta is the CEO and co-founder of Vihara, an online auctioneering platform that allows buyers and sellers to harness AI-driven technology to make actionable insights when participating in distressed property auctions. He co-founded the company with the mission to redefine the efficiency, transparency, and innovation of the operations that power distressed property auctions. The Vihara platform is a leading marketplace for auctions and supports tens of thousands of customers. Vihara has been recognized as a leader in conducting online auctions for distressed properties in the United States.</p>
            <p>Prior to Vihara, Vinayak held key positions at leading technology companies. Vinayak was a seasoned sales and product management professional as a Senior Sales Engineer at Schneider Electric, where he leveraged his extensive technical and business acumen to drive significant revenue growth and expand market share. At Samsara Inc., Vinayak led a large technical sales team, capturing customer requirements and driving the implementation of Samsara’s hardware and software solutions, resulting in significant revenue impact. His earlier experience includes managing mission control operations for Project Loon at Google X, where he coordinated complex launch and landing activities for a global fleet of internet balloons.</p>
            <p>Vinayak's academic background includes a Master of Science in Technology Management from Columbia University and a Bachelor of Science in Aerospace Engineering from Purdue University. Vinayak resides with his family in San Jose, California, and is passionate about leveraging technology to create impactful solutions.</p>
        `
        },
        {
            name: 'Trisha Soin',
            title: 'COO & Co-Founder',
            image: trisha,
            description: `
                 <p>Trisha is the COO and Co-Founder of Vihara, an online auctioneering platform that allows buyers and sellers to harness AI-driven technology to make actionable insights when participating in distressed property auctions. She co-founded the company with the mission to redefine the efficiency, transparency, and innovation of the operations that power distressed property auctions. She is responsible for Vihara’s disruptive technology that makes participating in auctions simpler and easier.</p>
                  <p>Prior to co-founding Vihara, Trisha was a Program Manager, leading pivotal programs at Hill Physicians Medical Group. With a focus on harnessing data-driven insights to optimize patient care, Trisha significantly enhanced program efficiency and launched innovative digital health tools, improving accessibility to healthcare for patients. At Abbott Diabetes Care, Trisha focused on developing marketing education programs that elevated brand positioning and improved product adoption of a Class 2 medical device for diabetes.</p>
                  <p>Trisha holds a Master of Science in Public Health from the University of Pennsylvania and a Bachelor of Science in Nursing from the University of San Francisco. Her skills in product strategy, systems analysis, and regulatory compliance management position her as a leader in driving impactful solutions. Trisha resides in San Jose, California with her family and is driven to make bidding simpler and easier.</p>
            `
        },
        {
            name: 'Rishab Gupta',
            title: 'COO & Co-Founder',
            image: rishabhImage,
            description: `
                        <p>Rishab is the CTO of Vihara, an online auctioneering platform that allows buyers and sellers to harness AI-driven technology to make actionable insights when participating in distressed property auctions. He is responsible for Vihara’s disruptive technology that makes participating in auctions simpler and easier.</p>

<p>Prior to working at Vihara, Rishab was a Product Manager at Advantage Capital, with a proven track record of leading innovative software solutions. He led the end-to-end lifecycle of a full-stack software solution, digitizing manual operations. Prior to this role, Rishab was a Data Analyst Intern at Impact Venture Capital, a leading AI-investing VC firm.</p>

<p>Rishab holds a Master of Science in Design from Harvard University and a Bachelor of Science in Computer Science and Statistics from UC Davis. He currently resides in San Jose, CA with his family.</p>

            `
        },
    ];

    const members = activeTab === 'management' ? managementMembers : boardMembers;

    return (
        <div className="leadership-members-container">
            <div className="leadership-members-tabs">
                <button onClick={() => setActiveTab('management')} className={activeTab === 'management' ? 'active' : ''}>Management</button>
                <button onClick={() => setActiveTab('board')} className={activeTab === 'board' ? 'active' : ''}>Board</button>
            </div>
            <div className="leadership-members-list">
                {members.map((member, index) => (
                    <div key={index} className="leadership-members-member">
                        <img src={member.image} alt={member.name} className="leadership-members-image" onClick={() => handleOpenModal(member)} />
                        <h3 className="leadership-members-name">{member.name}</h3>
                        <p className="leadership-members-title">{member.title}</p>
                    </div>
                ))}
            </div>
            {selectedMember && (
                <Modal open={openModal} onClose={handleCloseModal}>
                    <Box className="leadership-members-modal">
                        <div className='membership-modal-close-button'>
                            <IconButton
                                onClick={handleCloseModal}
                                className="leadership-members-modal-close-button"
                                aria-label="close"
                                size="large"
                            >
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <div className='modal-image-heading-leadership'>
                            <img src={selectedMember.image} alt={selectedMember.name} className="leadership-members-modal-image" />
                            <div>
                                <h2 className="leadership-members-modal-name">{selectedMember.name}</h2>
                                <p className="leadership-members-modal-title">{selectedMember.title}</p>
                            </div>
                        </div>
                        <div className="leadership-members-modal-description" dangerouslySetInnerHTML={{ __html: selectedMember.description }}></div>
                    </Box>
                </Modal>
            )}
        </div>
    );
};

export default LeadershipMembers;
