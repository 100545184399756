import React, { useState } from 'react';
import './AccountSettings.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
const usStates = [
  "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado",
  "Connecticut", "Delaware", "Florida", "Georgia", "Hawaii", "Idaho",
  "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine",
  "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi",
  "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire",
  "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota",
  "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island",
  "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah",
  "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin",
  "Wyoming"
];
const AccountSettings = () => {
  const [oldPassword,setPassword]=useState("");
  const [newPassword,setNewPassword]=useState("");
  const [confirmPassword,setConfirmPassword]=useState("");
  const user=useSelector((state)=>state.user.user);
  const [formData, setFormData] = useState({
    firstName: user.name,
    lastName: user.last_name,
    email: user.email,
    state: 'CA',
    city: 'San Francisco',
    zipCode: '90001',
    mobile: '6006591873',
    userType: 'Buyer'
  });
  const [isOpen, setIsOpen] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData); // Handle form submission logic here
  };

  return (
    <div className="portal-account-settings-container">
      <div className='account-settings-header-container'><h4 className="portal-account-settings-header">Account Settings </h4></div>
       <div className='portal-horizontal-line'></div>
      <form onSubmit={handleSubmit} className="portal-account-settings-form">
        <div className="portal-account-settings-row">
          <div className='label-container'>  <label>Change Password</label></div>
           <div></div>
        </div>

        <div className="portal-account-settings-row">
          <div className='label-container'> <label>Current Password</label></div>
          <input
            type="Password"
            name="Current Password"
            placeholder='Current Password'
            onChange={handleChange}
            required
          />
        </div>

        <div className="portal-account-settings-row">
          <div className='label-container'>  <label>New Password *</label></div>
          <input
            type="Password"
            name="New Password"
            placeholder='New Password'
            onChange={handleChange}
            required
          />
        </div>
        <div className="portal-account-settings-row">
          <div className='label-container'><label>Confirm Password *</label></div>
          <input
            type="text"
            name="password"
            placeholder='Confirm Password'
            onChange={handleChange}
            required
          />
        </div>
        <div className="portal-account-settings-row">
          <div className='label-container'></div>
          <button type="submit" className="portal-account-settings-submit-btn">
           Update Password
          </button>
        </div>
      </form >
    </div >
  );
};

export default AccountSettings;
