const randomData=[
    {
      'P-ID1': {
        'Auction-Type': 'Short Sales',
        'Property-type': 'Multiplex',
        'Auction Start date': '2023-11-12',
        'Start Time': '12:19 AM',
        'Auction End Date': '2023-5-11',
        'End Time': '6:15 PM',
        'Opening Bid': 136348,
        'Real Eawsted Astimated Price': 227621,
        Address: '1231 Example St',
        County: 'County1',
        State: 'NY',
        'Zip Code': '75111',
        'Bed Rooms': 2,
        Bathrooms: 2,
        'Total Area': 3196,
        'Carpet Area': 1945,
        'Lot Size': 6,
        'Year Built': 1974,
        'Event-ID': 'EVT1',
        Images: [Array]
      }
    },
    {
      'P-ID2': {
        'Auction-Type': 'Private',
        'Property-type': 'Shop',
        'Auction Start date': '2023-10-12',
        'Start Time': '5:30 AM',
        'Auction End Date': '2023-12-4',
        'End Time': '1:37 AM',
        'Opening Bid': 152134,
        'Real Eawsted Astimated Price': 432343,
        Address: '1232 Example St',
        County: 'County2',
        State: 'FL',
        'Zip Code': '69717',
        'Bed Rooms': 4,
        Bathrooms: 2,
        'Total Area': 4797,
        'Carpet Area': 2805,
        'Lot Size': 9,
        'Year Built': 1999,
        'Event-ID': 'EVT2',
        Images: [Array]
      }
    },
    {
      'P-ID3': {
        'Auction-Type': 'Short Sales',
        'Property-type': 'Multiplex',
        'Auction Start date': '2023-5-16',
        'Start Time': '5:56 PM',
        'Auction End Date': '2023-2-8',
        'End Time': '12:30 AM',
        'Opening Bid': 82778,
        'Real Eawsted Astimated Price': 407629,
        Address: '1233 Example St',
        County: 'County3',
        State: 'FL',
        'Zip Code': '61022',
        'Bed Rooms': 3,
        Bathrooms: 2,
        'Total Area': 2583,
        'Carpet Area': 3473,
        'Lot Size': 2,
        'Year Built': 1985,
        'Event-ID': 'EVT3',
        Images: [Array]
      }
    },
    {
      'P-ID4': {
        'Auction-Type': 'Private',
        'Property-type': 'Duplex',
        'Auction Start date': '2023-11-25',
        'Start Time': '4:55 AM',
        'Auction End Date': '2023-10-19',
        'End Time': '1:35 AM',
        'Opening Bid': 226325,
        'Real Eawsted Astimated Price': 275101,
        Address: '1234 Example St',
        County: 'County4',
        State: 'NY',
        'Zip Code': '46093',
        'Bed Rooms': 1,
        Bathrooms: 3,
        'Total Area': 4391,
        'Carpet Area': 1660,
        'Lot Size': 3,
        'Year Built': 1989,
        'Event-ID': 'EVT4',
        Images: [Array]
      }
    },
    {
      'P-ID5': {
        'Auction-Type': 'Bank Owned',
        'Property-type': 'Multiplex',
        'Auction Start date': '2023-8-19',
        'Start Time': '2:14 PM',
        'Auction End Date': '2023-10-21',
        'End Time': '7:29 PM',
        'Opening Bid': 184871,
        'Real Eawsted Astimated Price': 300286,
        Address: '1235 Example St',
        County: 'County5',
        State: 'TX',
        'Zip Code': '58660',
        'Bed Rooms': 2,
        Bathrooms: 2,
        'Total Area': 1395,
        'Carpet Area': 949,
        'Lot Size': 8,
        'Year Built': 1973,
        'Event-ID': 'EVT5',
        Images: [Array]
      }
    },
    {
      'P-ID6': {
        'Auction-Type': 'Private',
        'Property-type': 'Multiplex',
        'Auction Start date': '2023-11-23',
        'Start Time': '3:40 AM',
        'Auction End Date': '2023-3-5',
        'End Time': '12:25 AM',
        'Opening Bid': 231870,
        'Real Eawsted Astimated Price': 222597,
        Address: '1236 Example St',
        County: 'County6',
        State: 'CA',
        'Zip Code': '25922',
        'Bed Rooms': 4,
        Bathrooms: 1,
        'Total Area': 1972,
        'Carpet Area': 964,
        'Lot Size': 4,
        'Year Built': 1969,
        'Event-ID': 'EVT6',
        Images: [Array]
      }
    },
    {
      'P-ID7': {
        'Auction-Type': 'Bank Owned',
        'Property-type': 'Duplex',
        'Auction Start date': '2023-1-19',
        'Start Time': '8:34 PM',
        'Auction End Date': '2023-1-23',
        'End Time': '12:26 AM',
        'Opening Bid': 101003,
        'Real Eawsted Astimated Price': 327656,
        Address: '1237 Example St',
        County: 'County7',
        State: 'CA',
        'Zip Code': '71388',
        'Bed Rooms': 5,
        Bathrooms: 2,
        'Total Area': 1498,
        'Carpet Area': 2591,
        'Lot Size': 4,
        'Year Built': 1998,
        'Event-ID': 'EVT7',
        Images: [Array]
      }
    },
    {
      'P-ID8': {
        'Auction-Type': 'Foreclosure',
        'Property-type': 'Multiplex',
        'Auction Start date': '2023-4-17',
        'Start Time': '3:39 PM',
        'Auction End Date': '2023-8-21',
        'End Time': '9:16 AM',
        'Opening Bid': 91868,
        'Real Eawsted Astimated Price': 175996,
        Address: '1238 Example St',
        County: 'County8',
        State: 'TX',
        'Zip Code': '44935',
        'Bed Rooms': 5,
        Bathrooms: 3,
        'Total Area': 1853,
        'Carpet Area': 1566,
        'Lot Size': 10,
        'Year Built': 1986,
        'Event-ID': 'EVT8',
        Images: [Array]
      }
    },
    {
      'P-ID9': {
        'Auction-Type': 'Short Sales',
        'Property-type': 'Multiplex',
        'Auction Start date': '2023-8-28',
        'Start Time': '12:16 AM',
        'Auction End Date': '2023-5-22',
        'End Time': '11:22 AM',
        'Opening Bid': 210571,
        'Real Eawsted Astimated Price': 383846,
        Address: '1239 Example St',
        County: 'County9',
        State: 'NY',
        'Zip Code': '36165',
        'Bed Rooms': 1,
        Bathrooms: 1,
        'Total Area': 3597,
        'Carpet Area': 2211,
        'Lot Size': 6,
        'Year Built': 1992,
        'Event-ID': 'EVT9',
        Images: [Array]
      }
    },
    {
      'P-ID10': {
        'Auction-Type': 'Short Sales',
        'Property-type': 'Single Family Home',
        'Auction Start date': '2023-6-11',
        'Start Time': '2:34 PM',
        'Auction End Date': '2023-1-16',
        'End Time': '8:17 AM',
        'Opening Bid': 176118,
        'Real Eawsted Astimated Price': 356989,
        Address: '12310 Example St',
        County: 'County10',
        State: 'TX',
        'Zip Code': '96471',
        'Bed Rooms': 2,
        Bathrooms: 1,
        'Total Area': 3811,
        'Carpet Area': 1106,
        'Lot Size': 3,
        'Year Built': 2006,
        'Event-ID': 'EVT10',
        Images: [Array]
      }
    },
    {
      'P-ID11': {
        'Auction-Type': 'Private',
        'Property-type': 'Single Family Home',
        'Auction Start date': '2023-7-20',
        'Start Time': '10:40 PM',
        'Auction End Date': '2023-7-2',
        'End Time': '12:12 AM',
        'Opening Bid': 75176,
        'Real Eawsted Astimated Price': 365371,
        Address: '12311 Example St',
        County: 'County11',
        State: 'TX',
        'Zip Code': '47739',
        'Bed Rooms': 3,
        Bathrooms: 2,
        'Total Area': 3969,
        'Carpet Area': 1230,
        'Lot Size': 8,
        'Year Built': 2009,
        'Event-ID': 'EVT11',
        Images: [Array]
      }
    },
    {
      'P-ID12': {
        'Auction-Type': 'Bank Owned',
        'Property-type': 'Multiplex',
        'Auction Start date': '2023-12-22',
        'Start Time': '12:22 AM',
        'Auction End Date': '2023-12-8',
        'End Time': '11:18 AM',
        'Opening Bid': 149693,
        'Real Eawsted Astimated Price': 434515,
        Address: '12312 Example St',
        County: 'County12',
        State: 'NY',
        'Zip Code': '41907',
        'Bed Rooms': 3,
        Bathrooms: 2,
        'Total Area': 1912,
        'Carpet Area': 1345,
        'Lot Size': 10,
        'Year Built': 1986,
        'Event-ID': 'EVT12',
        Images: [Array]
      }
    },
    {
      'P-ID13': {
        'Auction-Type': 'Short Sales',
        'Property-type': 'Multiplex',
        'Auction Start date': '2023-12-18',
        'Start Time': '11:39 PM',
        'Auction End Date': '2023-1-27',
        'End Time': '8:27 AM',
        'Opening Bid': 269764,
        'Real Eawsted Astimated Price': 76347,
        Address: '12313 Example St',
        County: 'County13',
        State: 'NY',
        'Zip Code': '48313',
        'Bed Rooms': 2,
        Bathrooms: 3,
        'Total Area': 3096,
        'Carpet Area': 3183,
        'Lot Size': 4,
        'Year Built': 2010,
        'Event-ID': 'EVT13',
        Images: [Array]
      }
    },
    {
      'P-ID14': {
        'Auction-Type': 'Short Sales',
        'Property-type': 'Single Family Home',
        'Auction Start date': '2023-7-14',
        'Start Time': '8:17 AM',
        'Auction End Date': '2023-8-12',
        'End Time': '1:26 AM',
        'Opening Bid': 214327,
        'Real Eawsted Astimated Price': 254070,
        Address: '12314 Example St',
        County: 'County14',
        State: 'CA',
        'Zip Code': '30582',
        'Bed Rooms': 3,
        Bathrooms: 2,
        'Total Area': 2350,
        'Carpet Area': 1875,
        'Lot Size': 5,
        'Year Built': 1988,
        'Event-ID': 'EVT14',
        Images: [Array]
      }
    },
    {
      'P-ID15': {
        'Auction-Type': 'Bank Owned',
        'Property-type': 'Multiplex',
        'Auction Start date': '2023-3-16',
        'Start Time': '9:36 AM',
        'Auction End Date': '2023-9-13',
        'End Time': '7:59 PM',
        'Opening Bid': 71205,
        'Real Eawsted Astimated Price': 408678,
        Address: '12315 Example St',
        County: 'County15',
        State: 'FL',
        'Zip Code': '59951',
        'Bed Rooms': 1,
        Bathrooms: 2,
        'Total Area': 4912,
        'Carpet Area': 2827,
        'Lot Size': 8,
        'Year Built': 1992,
        'Event-ID': 'EVT15',
        Images: [Array]
      }
    },
    {
      'P-ID16': {
        'Auction-Type': 'Short Sales',
        'Property-type': 'Shop',
        'Auction Start date': '2023-8-5',
        'Start Time': '1:17 AM',
        'Auction End Date': '2023-5-18',
        'End Time': '9:21 PM',
        'Opening Bid': 289813,
        'Real Eawsted Astimated Price': 314787,
        Address: '12316 Example St',
        County: 'County16',
        State: 'FL',
        'Zip Code': '10748',
        'Bed Rooms': 3,
        Bathrooms: 1,
        'Total Area': 4350,
        'Carpet Area': 3214,
        'Lot Size': 9,
        'Year Built': 1958,
        'Event-ID': 'EVT16',
        Images: [Array]
      }
    },
    {
      'P-ID17': {
        'Auction-Type': 'Private',
        'Property-type': 'Duplex',
        'Auction Start date': '2023-7-9',
        'Start Time': '9:36 AM',
        'Auction End Date': '2023-11-10',
        'End Time': '2:58 PM',
        'Opening Bid': 129817,
        'Real Eawsted Astimated Price': 186392,
        Address: '12317 Example St',
        County: 'County17',
        State: 'NY',
        'Zip Code': '21737',
        'Bed Rooms': 1,
        Bathrooms: 2,
        'Total Area': 2708,
        'Carpet Area': 2387,
        'Lot Size': 8,
        'Year Built': 2009,
        'Event-ID': 'EVT17',
        Images: [Array]
      }
    },
    {
      'P-ID18': {
        'Auction-Type': 'Private',
        'Property-type': 'Single Family Home',
        'Auction Start date': '2023-4-27',
        'Start Time': '11:20 PM',
        'Auction End Date': '2023-12-11',
        'End Time': '11:19 PM',
        'Opening Bid': 161218,
        'Real Eawsted Astimated Price': 450293,
        Address: '12318 Example St',
        County: 'County18',
        State: 'CA',
        'Zip Code': '33513',
        'Bed Rooms': 5,
        Bathrooms: 1,
        'Total Area': 4748,
        'Carpet Area': 4272,
        'Lot Size': 2,
        'Year Built': 2013,
        'Event-ID': 'EVT18',
        Images: [Array]
      }
    },
    {
      'P-ID19': {
        'Auction-Type': 'Private',
        'Property-type': 'Duplex',
        'Auction Start date': '2023-4-11',
        'Start Time': '1:48 PM',
        'Auction End Date': '2023-1-1',
        'End Time': '6:34 PM',
        'Opening Bid': 132640,
        'Real Eawsted Astimated Price': 466315,
        Address: '12319 Example St',
        County: 'County19',
        State: 'CA',
        'Zip Code': '73710',
        'Bed Rooms': 1,
        Bathrooms: 1,
        'Total Area': 3997,
        'Carpet Area': 1401,
        'Lot Size': 4,
        'Year Built': 1952,
        'Event-ID': 'EVT19',
        Images: [Array]
      }
    },
    {
      'P-ID20': {
        'Auction-Type': 'Foreclosure',
        'Property-type': 'Single Family Home',
        'Auction Start date': '2023-7-28',
        'Start Time': '6:50 AM',
        'Auction End Date': '2023-7-17',
        'End Time': '3:29 AM',
        'Opening Bid': 254963,
        'Real Eawsted Astimated Price': 383025,
        Address: '12320 Example St',
        County: 'County20',
        State: 'FL',
        'Zip Code': '31106',
        'Bed Rooms': 1,
        Bathrooms: 2,
        'Total Area': 3522,
        'Carpet Area': 3334,
        'Lot Size': 8,
        'Year Built': 1960,
        'Event-ID': 'EVT20',
        Images: [Array]
      }
    },
    {
      'P-ID21': {
        'Auction-Type': 'Private',
        'Property-type': 'Shop',
        'Auction Start date': '2023-5-22',
        'Start Time': '10:51 AM',
        'Auction End Date': '2023-8-23',
        'End Time': '11:28 PM',
        'Opening Bid': 274068,
        'Real Eawsted Astimated Price': 349187,
        Address: '12321 Example St',
        County: 'County21',
        State: 'TX',
        'Zip Code': '88016',
        'Bed Rooms': 2,
        Bathrooms: 3,
        'Total Area': 1684,
        'Carpet Area': 3625,
        'Lot Size': 10,
        'Year Built': 1987,
        'Event-ID': 'EVT21',
        Images: [Array]
      }
    },
    {
      'P-ID22': {
        'Auction-Type': 'Private',
        'Property-type': 'Shop',
        'Auction Start date': '2023-10-2',
        'Start Time': '1:32 AM',
        'Auction End Date': '2023-9-20',
        'End Time': '4:51 AM',
        'Opening Bid': 82467,
        'Real Eawsted Astimated Price': 98513,
        Address: '12322 Example St',
        County: 'County22',
        State: 'TX',
        'Zip Code': '31396',
        'Bed Rooms': 4,
        Bathrooms: 1,
        'Total Area': 3215,
        'Carpet Area': 4071,
        'Lot Size': 10,
        'Year Built': 1986,
        'Event-ID': 'EVT22',
        Images: [Array]
      }
    },
    {
      'P-ID23': {
        'Auction-Type': 'Bank Owned',
        'Property-type': 'Duplex',
        'Auction Start date': '2023-8-8',
        'Start Time': '1:55 AM',
        'Auction End Date': '2023-4-12',
        'End Time': '4:47 AM',
        'Opening Bid': 255803,
        'Real Eawsted Astimated Price': 245074,
        Address: '12323 Example St',
        County: 'County23',
        State: 'CA',
        'Zip Code': '97177',
        'Bed Rooms': 4,
        Bathrooms: 2,
        'Total Area': 3452,
        'Carpet Area': 834,
        'Lot Size': 10,
        'Year Built': 1967,
        'Event-ID': 'EVT23',
        Images: [Array]
      }
    },
    {
      'P-ID24': {
        'Auction-Type': 'Private',
        'Property-type': 'Multiplex',
        'Auction Start date': '2023-12-25',
        'Start Time': '3:29 AM',
        'Auction End Date': '2023-3-6',
        'End Time': '2:29 PM',
        'Opening Bid': 117707,
        'Real Eawsted Astimated Price': 130622,
        Address: '12324 Example St',
        County: 'County24',
        State: 'TX',
        'Zip Code': '98211',
        'Bed Rooms': 4,
        Bathrooms: 1,
        'Total Area': 1439,
        'Carpet Area': 1752,
        'Lot Size': 10,
        'Year Built': 2016,
        'Event-ID': 'EVT24',
        Images: [Array]
      }
    },
    {
      'P-ID25': {
        'Auction-Type': 'Private',
        'Property-type': 'Multiplex',
        'Auction Start date': '2023-4-26',
        'Start Time': '12:30 PM',
        'Auction End Date': '2023-10-22',
        'End Time': '9:28 PM',
        'Opening Bid': 141364,
        'Real Eawsted Astimated Price': 417202,
        Address: '12325 Example St',
        County: 'County25',
         State: 'FL',
        'Zip Code': '42012',
        'Bed Rooms': 3,
        Bathrooms: 1,
        'Total Area': 3018,
        'Carpet Area': 962,
        'Lot Size': 5,
        'Year Built': 1999,
        'Event-ID': 'EVT25',
        Images: [Array]
      }
    }
  ]
  export default randomData;