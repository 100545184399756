import React, { useEffect, useRef } from 'react';
import './CardSlider.css';
import BlogCard from './BlogCard';
import { blogArray } from './blogs/blog-data';

const CardSlider = ({ title, from }) => {
    const sliderRef = useRef(null);
    const buttonRef = useRef(null);

    const updateButtonWidth = () => {
        if (sliderRef.current && buttonRef.current) {
            const sliderWidth = sliderRef.current.offsetWidth;
            buttonRef.current.style.width = `${sliderWidth}px`;
        }
    };

    useEffect(() => {
        // Set the initial width
        updateButtonWidth();

        // Update the width on window resize
        window.addEventListener('resize', updateButtonWidth);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', updateButtonWidth);
        };
    }, [sliderRef.current]);


    return (
        <div className="author-card-slider-container">
            <div
                ref={buttonRef}
                className='author-card-sliding-button'
            >
                {from === "HomePageBlog" ? (
                    <div className='read-more-container' style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                        <div className='read-more-icon'>
                            <svg className="related__svg mr-1/2 inline" width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg" data-v-3951e331="">
                                <rect y="16" width="27" height="27" fill="#00263E" data-v-3951e331=""></rect>
                                <rect x="6" y="22" width="15" height="15" rx="7.5" fill="#0384FB" data-v-3951e331=""></rect>
                                <rect x="27" y="1" width="15" height="15" fill="#A9E1FF" data-v-3951e331=""></rect>
                            </svg>
                        </div>
                        <div className='read-more-text-horizontal-line'>
                            <h1>Read More</h1>
                            <div className='read-more-horizontal-line'></div>
                        </div>
                    </div>
                ) : (
                    <h1>{title}</h1>
                )}
                <div className='autor-slider-horizontal-line'></div>
                <button className="author-card-arrow author-card-arrow-left">
                    <svg width="50" height="51" viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg" data-v-4ed05faf="">
                        <circle cx="30" cy="30.5" r="30" transform="rotate(-180 30 30.5)" fill="#0384FB" data-v-4ed05faf=""></circle>
                        <path d="M27.9308 40.8447L17.5859 30.4999L27.9308 20.1551" stroke="white" strokeWidth="5" data-v-4ed05faf=""></path>
                        <path d="M17.5856 30.5L45.5166 30.5" stroke="white" strokeWidth="5" data-v-4ed05faf=""></path>
                    </svg>
                </button>
                <button className="author-card-arrow author-card-arrow-right">
                    <svg width="50" height="51" viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg" data-v-4ed05faf="">
                        <circle cx="30" cy="30.5" r="30" fill="#0384FB" data-v-4ed05faf=""></circle>
                        <path d="M32.0691 20.1553L42.4139 30.5001L32.0691 40.8449" stroke="white" strokeWidth="5" data-v-4ed05faf=""></path>
                        <path d="M42.4143 30.5H14.4833" stroke="white" strokeWidth="5" data-v-4ed05faf=""></path>
                    </svg>
                </button>
            </div>
            <div className="author-card-slider-wrapper">
                <div
                    ref={sliderRef}
                    className="author-card-slider"
                >
                    {blogArray.slice(0, 3).map((blog) => (
                        <BlogCard
                            key={blog.id}
                            blogImage={blog.blogIimage}
                            writterImage={blog.writterImage}
                            title={blog.blogTitle}
                            category={"Efficiency"}
                            description={blog.description}
                            authorName={blog.writterName}
                            authorTitle={blog.authorTittle}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CardSlider;
