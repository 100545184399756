import React from 'react'
import "./CommonBuy.css"
import NavBackground from '../includes/navBackground'
import BuyNavbar from './BuyNavbar/BuyNavbar'
import PropertySample from './PropertySample/PropertySample'
import randomData from "./randomData"
import { useFilterContext } from '../../ContextProvider/Context'
import BuyNavbarSmallerScreen from './BuyNavbar/BuyNavbarSmallerScreen'
import { useMediaQuery } from '@mui/material'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
function Buys({ }) {
  window.scrollTo(0, 0);
  const isScreenSmall = useMediaQuery('(max-width:1150px)');
  const [showMap, setShowMap] = useState(true);
  const location = useLocation();
  const filteredProducts = location.state?.filteredProducts;
  const { assetType, propertyType, beds, baths, minSquareFeet, maxSquareFeet } = useFilterContext();
  const extractedData = randomData.map(item => {
    const key = Object.keys(item)[0];
    if ((assetType.length === 0 || assetType.includes(item[key]['Auction-Type']))
      && (propertyType.length === 0 || propertyType.includes(item[key]['Property-type'])) &&
      (beds === "Any" || item[key]['Bed Rooms'] === beds) &&
      (baths === "Any" || item[key]['Bathrooms'] === baths) &&
      (minSquareFeet === "" || minSquareFeet <= item[key]['Total Area']) &&
      (maxSquareFeet === "" || maxSquareFeet >= item[key]['Total Area'])) {
      return item[key];
    }
  });
  const handleShowMap = () => {
    const mapWidthController = document.querySelector('.property-location');
    const propertyCardsContainer = document.querySelector('.property-cards');
    const propertyCardController = document.getElementsByClassName('property-card');
    if (showMap === true) {
      mapWidthController.style.width = "0%"
      propertyCardsContainer.style.width = "100%"
      propertyCardsContainer.style.display = "flex"
      propertyCardsContainer.style.flexDirection = "row"
      propertyCardsContainer.style.alignItems = "start"
      propertyCardsContainer.style.justifyContent = "center"
      Array.from(propertyCardController).forEach((card) => {
        card.style.flexDirection = "column";
        card.style.width = "300px";
        card.style.height = "400px";
      });
      setShowMap(false);
    }
    else {
      if (window.innerWidth >= 1220) {
        mapWidthController.style.width = "40%"
        propertyCardsContainer.style.width = "65%"
        propertyCardsContainer.style.display = "flex"
        propertyCardsContainer.style.flexDirection = "row"
        propertyCardsContainer.style.justifyContent = "center"
        propertyCardsContainer.style.alignItems = "flex-start"
        propertyCardsContainer.style.flexWrap = "wrap";
        Array.from(propertyCardController).forEach((card) => {
          card.style.flexDirection = "row";
          card.style.width = "96%";
          card.style.height = "212px";
        });
      }
      else {
        mapWidthController.style.width = "100%"
        propertyCardsContainer.style.width = "0px"
        propertyCardsContainer.style.display = "none"
      }
      setShowMap(true);
    }
  }
  return (
    <div className='common-buy-container'>
      <NavBackground />
      {isScreenSmall ? <BuyNavbarSmallerScreen showMap={showMap} handleShowMap={handleShowMap} /> : <BuyNavbar showMap={showMap} handleShowMap={handleShowMap} />}
      <PropertySample showMap={showMap} handleShowMap={handleShowMap} extractedData={extractedData} filteredProducts={filteredProducts} />
    </div>
  )
}

export default Buys
