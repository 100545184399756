import React from 'react';
import { Drawer } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import "./EventCalender.css"
import CustomDropdown from './CustomDropDown';
import states from './utils/States'; // Ensure the path is correct
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
const FilterDrawer = ({ isOpen, onClose, selectedDate, setSelectedDate, selectedState, setSelectedState, selectedAssetType, setSelectedAssetType, hidePastEvents, setHidePastEvents }) => {
    const stateNames = states.map((state) => state.name);
    return (
        <Drawer anchor="right" open={isOpen} onClose={onClose}>
            <div className='event-calender-header-main'>
                <div className="date-picker-wrapper">
                    <FontAwesomeIcon icon={faCalendar} className="calendar-icon" />
                    <DatePicker
                        className="event-calendar-date-picker"
                        selected={selectedDate}
                        onChange={date => setSelectedDate(date)}
                        dateFormat="MM/dd/yyyy"
                        placeholderText="MM/DD/YYYY"
                    />
                </div>

                <CustomDropdown options={stateNames} placeholder={"Select State"} Data={selectedState} setData={setSelectedState} />
                <CustomDropdown options={["Foreclosure", "REO Bank Owned", "Short Sales"]} Data={selectedAssetType} setData={setSelectedAssetType} placeholder={"Asset Type"} />
                <CustomDropdown options={["In Person", "Online", "Direct Offer"]} Data={selectedAssetType} setData={setSelectedAssetType} placeholder={"Buying Type"} />

                <label className='event-calender-label-hide-past-event'>
                    <input
                        type="checkbox"
                        checked={hidePastEvents}
                        onChange={(e) => setHidePastEvents(e.target.checked)}
                    /> Hide past events
                </label>
            </div>
        </Drawer>
    );
};
FilterDrawer.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    selectedDate: PropTypes.string.isRequired,
    setSelectedDate: PropTypes.func.isRequired,
    selectedState: PropTypes.string.isRequired,
    setSelectedState: PropTypes.func.isRequired,
    selectedAssetType: PropTypes.string.isRequired,
    setSelectedAssetType: PropTypes.func.isRequired,
    hidePastEvents: PropTypes.bool.isRequired,
    setHidePastEvents: PropTypes.func.isRequired,
};
export default FilterDrawer;
