import React from "react";
import "./ContactSupport.css";
import "../CommonConditions.css";
import NavBackground from "../../../includes/navBackground";
import AdditionalLinks from "../AdditionalLinks/AdditionalLinks";



const ContactSupport = () => {
    return (
        <div className="conditions-background">
            <NavBackground />
            <div className="privacy-term-main-container">

                <div className="privacy-term-main-container">
                    <div className="privacy-content-updated-date">
                        Updated on 2nd May 2024
                    </div>
                    <div className="privacy-content-main-heading">Website Terms of Use</div>
                    <div className="privacy-content-main-section">
                        <AdditionalLinks />
                        <div className="privacy-content-main-content-section">
                        </div>



                    </div>


                </div>

            </div>
        </div>
    )
}

            export default ContactSupport