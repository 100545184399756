import React from 'react'
import "./HeroSection.css"
import founded from "../../../assets/images/vihara/image8.webp"
import { useMediaQuery } from '@mui/material'
function HeroSection() {
    const isSmallScreen = useMediaQuery('(max-width:768px)');

    return (
        <div className="leadership-hero-section">
            <div className="leadership-hero-image">
                <img src={founded} alt="Leadership Team" />
            </div>
            <div className="leadership-hero-text">
                <h2>Meet our <br style={{ display: isSmallScreen ? "none" : "block" }} /> Leadership</h2>
                <p>
                    Vihara prides itself not only on its innovative technology, but also on the talent of its team. The company grows under the guidance of some of the most talented people
                </p>
            </div>
        </div>
    )
}

export default HeroSection
