import React from 'react'
import "./BuyingBankOwned.css"
import NavBackground from '../../includes/navBackground'
import CommonHeroSection from '../../HomePage/CommonHeroSection/CommonHeroSection'
import { ForclosureCustomerResource, ForeclosureBiddingProcess, ForeclosureExperienceSection, ForeclosureFAQs, ForeclosureSectionOne } from '../BuyingAForclosure/BuyingAForclosure'
import { BankOwnedFaqs } from './bankOwnedFaqData'
import bankownedimage from "../../../assets/images/vihara/136.webp"

const bankOwnedSteps = [
    {
        number: 1,
        title: 'Create Vihara Account',
        description: 'When you create an account, you can search for properties, gain access to property information, and receive alerts on homes you’re interested in.'
    },
    {
        number: 2,
        title: 'Search Properties',
        description: 'Browse for properties near you on Vihara, and save the ones that you’re interested in to receive real-time alerts and notifications.'
    },
    {
        number: 3,
        title: 'Register for Auction',
        description: 'Register for the auction; it only takes a few steps.'
    },
    {
        number: 4,
        title: 'Attend Auction',
        description: 'Attend the auction and bid on the property. Make sure you check-in on the auction to see if the bidding has gone into overtime.'
    },
    {
        number: 5,
        title: 'Provide Documents',
        description: 'If you’re the highest bidder, you’ve won the auction! Now you need to sign the purchase agreement and provide proof of funds.'
    },
    {
        number: 6,
        title: 'Closing, Escrow & Title',
        description: 'Work with closing representatives and contract representatives who will walk you through the process.'
    },
    {
        number: 7,
        title: 'Gain Possession',
        description: 'Congrats, you have ownership of the property! Now you can renovate and update your property to increase your investment.'
    }
];


const BuyingBankOwned = () => {
  return (
    <div>
        <NavBackground/>
        <CommonHeroSection
        title='Buying a Bank-Owned'
        description='Learn how to bid on bank-owned property auctions and win on your investments.'
        videoSrc=''
        faqImage={bankownedimage}
        />
        <ForeclosureSectionOne/>
        <ForeclosureBiddingProcess
        steps={bankOwnedSteps}
        typeTitle="The Bank Owned Bidding Process"
        typeDesc="Learn how to bid for bank owned properties step-by-step."
        />
        <ForeclosureFAQs
        faqButtontext="Go to FAQs"
        faqDesc="Explore our FAQs below to see if we can help answer any questions you may have about bank-owned auctions."
        faqTitle="Still have questions?"
        forclosefaqData={BankOwnedFaqs}
        />
        <ForclosureCustomerResource/>
        <ForeclosureExperienceSection
        expButtonText="Browse Homes"
        expTitle="Experience the future of real estate auctions."
        expDesc="We make bidding on bank owned property auctions simpler and easier for you."
        link="Bank-Owned"
        />
    </div>
  )
}

export default BuyingBankOwned