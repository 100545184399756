// src/features/properties/propertiesSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  Properties: [],
};

const propertiesSlice = createSlice({
  name: 'properties',
  initialState,
  reducers: {
    setProperties: (state, action) => {
      state.Properties = action.payload;
    },
  },
});

export const { setProperties } = propertiesSlice.actions;

export default propertiesSlice.reducer;
