import React, {useState} from 'react'
import "./guide.css"
import NavBackground from '../../includes/navBackground';

const guides = [
    {
        title: "How to Reduce Distracted Driving for Your Fleet",
        description: "Distracted driving can not only increase accident frequency and costs, but can put your drivers' safety at risk. Read on to learn the t...",
        image: "./path/to/image1.png", // Adjust the path as needed
        logo: "./path/to/logo.png", // Adjust the path as needed
        category: "How to Reduce Distracted Driving for Your Fleet"
    },
    {
        title: "What is Mobile Device Management (MDM)?",
        description: "Mobile technology is critical in keeping distributed teams connected, but managing mobile devices at scale is complex. In this guide, l...",
        image: "./path/to/image2.png", // Adjust the path as needed
        logo: "./path/to/logo.png", // Adjust the path as needed
        category: "What is Mobile Device Management (MDM)?"
    },
    {
        title: "Following Distance: How to Coach CMV Drivers and Decrease Tailgating",
        description: "Tailgating is the leading cause of rear-end collisions in the United States, causing more than 1,700 deaths and 500,000 injuries per ye...",
        image: "./path/to/image3.png", // Adjust the path as needed
        logo: "./path/to/logo.png", // Adjust the path as needed
        category: "How to Coach CMV Drivers on Following Distance"
    },{
        title: "How to Reduce Distracted Driving for Your Fleet",
        description: "Distracted driving can not only increase accident frequency and costs, but can put your drivers' safety at risk. Read on to learn the t...",
        image: "./path/to/image1.png", // Adjust the path as needed
        logo: "./path/to/logo.png", // Adjust the path as needed
        category: "How to Reduce Distracted Driving for Your Fleet"
    },
    {
        title: "What is Mobile Device Management (MDM)?",
        description: "Mobile technology is critical in keeping distributed teams connected, but managing mobile devices at scale is complex. In this guide, l...",
        image: "./path/to/image2.png", // Adjust the path as needed
        logo: "./path/to/logo.png", // Adjust the path as needed
        category: "What is Mobile Device Management (MDM)?"
    },
    {
        title: "Following Distance: How to Coach CMV Drivers and Decrease Tailgating",
        description: "Tailgating is the leading cause of rear-end collisions in the United States, causing more than 1,700 deaths and 500,000 injuries per ye...",
        image: "./path/to/image3.png", // Adjust the path as needed
        logo: "./path/to/logo.png", // Adjust the path as needed
        category: "How to Coach CMV Drivers on Following Distance"
    },{
        title: "How to Reduce Distracted Driving for Your Fleet",
        description: "Distracted driving can not only increase accident frequency and costs, but can put your drivers' safety at risk. Read on to learn the t...",
        image: "./path/to/image1.png", // Adjust the path as needed
        logo: "./path/to/logo.png", // Adjust the path as needed
        category: "How to Reduce Distracted Driving for Your Fleet"
    },
    {
        title: "What is Mobile Device Management (MDM)?",
        description: "Mobile technology is critical in keeping distributed teams connected, but managing mobile devices at scale is complex. In this guide, l...",
        image: "./path/to/image2.png", // Adjust the path as needed
        logo: "./path/to/logo.png", // Adjust the path as needed
        category: "What is Mobile Device Management (MDM)?"
    },
    {
        title: "Following Distance: How to Coach CMV Drivers and Decrease Tailgating",
        description: "Tailgating is the leading cause of rear-end collisions in the United States, causing more than 1,700 deaths and 500,000 injuries per ye...",
        image: "./path/to/image3.png", // Adjust the path as needed
        logo: "./path/to/logo.png", // Adjust the path as needed
        category: "How to Coach CMV Drivers on Following Distance"
    },{
        title: "How to Reduce Distracted Driving for Your Fleet",
        description: "Distracted driving can not only increase accident frequency and costs, but can put your drivers' safety at risk. Read on to learn the t...",
        image: "./path/to/image1.png", // Adjust the path as needed
        logo: "./path/to/logo.png", // Adjust the path as needed
        category: "How to Reduce Distracted Driving for Your Fleet"
    },
    {
        title: "What is Mobile Device Management (MDM)?",
        description: "Mobile technology is critical in keeping distributed teams connected, but managing mobile devices at scale is complex. In this guide, l...",
        image: "./path/to/image2.png", // Adjust the path as needed
        logo: "./path/to/logo.png", // Adjust the path as needed
        category: "What is Mobile Device Management (MDM)?"
    },
    {
        title: "Following Distance: How to Coach CMV Drivers and Decrease Tailgating",
        description: "Tailgating is the leading cause of rear-end collisions in the United States, causing more than 1,700 deaths and 500,000 injuries per ye...",
        image: "./path/to/image3.png", // Adjust the path as needed
        logo: "./path/to/logo.png", // Adjust the path as needed
        category: "How to Coach CMV Drivers on Following Distance"
    }
];

const GuideContent = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const guidesPerPage = 3;

    // Calculate the guides to be displayed on the current page
    const indexOfLastGuide = currentPage * guidesPerPage;
    const indexOfFirstGuide = indexOfLastGuide - guidesPerPage;
    const currentGuides = guides.slice(indexOfFirstGuide, indexOfLastGuide);

    // Calculate the total number of pages
    const totalPages = Math.ceil(guides.length / guidesPerPage);

    const handleClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div className="guide-content-container">
            <h2 className="guide-content-header">SAFETY</h2>
            <div className="guide-content-cards">
                {currentGuides.map((guide, index) => (
                    <div key={index} className="guide-content-card">
                        <div className="guide-content-card-header">
                            <img src={guide.logo} alt="Logo" className="guide-content-card-logo" />
                            <span>{guide.category}</span>
                        </div>
                        {/* <img src={guide.image} alt={guide.title} className="guide-content-card-image" /> */}
                        <h3 className="guide-content-card-title">{guide.title}</h3>
                        <p className="guide-content-card-description">{guide.description}</p>
                    </div>
                ))}
            </div>
            <div className="guide-content-pagination">
                {[...Array(totalPages)].map((_, index) => (
                    <button
                        key={index}
                        className={`guide-content-page-button ${currentPage === index + 1 ? 'active' : ''}`}
                        onClick={() => handleClick(index + 1)}
                    >
                        {index + 1}
                    </button>
                ))}
                <button
                    className="guide-content-next-button"
                    onClick={() => handleClick(currentPage + 1)}
                    disabled={currentPage === totalPages}
                >
                    Next
                </button>
            </div>
        </div>
    );
};



const GuideHeroSection = () => {
    return (
        <div className="guide-hero-section">
            <div className="guide-hero-section-content">
                <h1>Guides</h1>
                <p>Browse our collection of guides for helpful information on how to improve the safety, efficiency, and sustainability of your business.</p>
                <button>Check Our Prices</button>
            </div>
        </div>
    );
};


const Guide = () => {
  return (
    <div>
        <NavBackground/>
        <GuideHeroSection/>
        <GuideContent/>
        <GuideContent/>
        <GuideContent/>
        <GuideContent/>
    </div>
  )
}

export default Guide