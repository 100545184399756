import React from 'react';
import './AddUser.css';
function AddUser() {
  return (
    <div className="admin-panel-user-form-container">
      <div className='admin-panel-user-container'>
      <h2>Create User</h2>
      <form>
        <div className="admin-panel-user-form-row">
          <div className="admin-panel-user-form-group">
            <label>First Name</label>
            <input type="text" placeholder="First Name" />
          </div>
          <div className="admin-panel-user-form-group">
            <label>User Last Name</label>
            <input type="text" placeholder="Last Name" />
          </div>
        </div>
        <div className="admin-panel-user-form-row">
          <div className="admin-panel-user-form-group">
            <label>Email</label>
            <input type="email" placeholder="Email" />
          </div>
          <div className="admin-panel-user-form-group">
            <label>Password</label>
            <input type="password" placeholder="Password" />
          </div>
        </div>
        <div className="admin-panel-user-form-row">
          <div className="admin-panel-user-form-group">
            <label>Confirm Password</label>
            <input type="password" placeholder="Confirm Password" />
          </div>
          <div className="admin-panel-user-form-group">
            <label>Business Phone (Optional)</label>
            <input type="text" placeholder="Business Phone (Optional)" />
          </div>
        </div>
        <div className="admin-panel-user-form-row">
          <div className="admin-panel-user-form-group">
            <label>Address (Optional)</label>
            <input type="text" placeholder="Address (Optional)" />
          </div>
          <div className="admin-panel-user-form-group">
            <label>City (Optional)</label>
            <input type="text" placeholder="City (Optional)" />
          </div>
        </div>
        <div className="admin-panel-user-form-row">
          <div className="admin-panel-user-form-group">
            <label>State (Optional)</label>
            <input type="text" placeholder="State" />
          </div>
          <div className="admin-panel-user-form-group">
            <label>ZIP (Optional)</label>
            <input type="text" placeholder="Zip" />
          </div>
        </div>
        <div className="admin-panel-user-form-row">
          <div className="admin-panel-user-form-group">
            <label>Country</label>
            <input type="text" placeholder="Country" />
          </div>
        </div>
        <div className='admin-panel-create-user-button'><button type="submit">Create User</button></div>
      </form>
      </div>
    </div>
  );
}

export default AddUser;
