import React from 'react';
import './MyOffers.css';
const MyOffers = () => {
  return (
    <div className="portal-my-offers-container">
      <div className="portal-my-offers-header">
        <h2 className='portal-profile-header'>My Offers</h2>
        <a href="#" className="portal-my-offers-history-link">History</a>
      </div>
      <div className="portal-my-offers-content">
      <div className="portal-my-offers-last-updated">
          Last Updated: Sep 7, 2024 16:46:44 ET
        </div>
        <p>Currently you do not have active offers on any of the properties</p>
      </div>
    </div>
  );
};

export default MyOffers;
