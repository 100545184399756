import React from 'react'
import AdminPanelHeader from '../adminPanelHeader/adminPanelHeader'
import AdminPanelAuctionList from '../adminPanelAuctionList/adminPanelAuctionList'
import AdminPanelSidebar from '../adminPanelSidebar/adminPanelSidebar'
import AddUser from '../addUser/AddUser'
import ManageUser from '../manageUser/manageUser'
import { useState } from 'react'
import AddAuction from '../addAcution/AddAuction'
import { useNavigate } from 'react-router-dom'
import { useContext } from 'react'
import { AuthContext } from '../../ContextProvider/AuthContext'
import { useEffect } from 'react'
import "./adminPanel.css"
import { useSelector } from 'react-redux'
function AdminPanel() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [mainContent,setMainContent]=useState("manangeAuction");
    const navigate=useNavigate();
    const toggleSidebar = (value) => {
        setIsSidebarOpen(value);
    };
    const handleMainContent=(value)=>{
        setMainContent(value);
    }
    const isAuthenticated=useSelector((state)=>state.admin.isAuthenticated);
    // if(isAuthenticated===false){
    //     return navigate("/admin/login");
    // }
    return (
        <div className="admin-panel-container">
            <div className={`admin-panel-sidebar-content ${isSidebarOpen ? 'open' : ''}`}> <AdminPanelSidebar toggleSidebar={toggleSidebar} handleMainContent={handleMainContent} /> </div>
            <div className="admin-panel-main-content">
                <AdminPanelHeader toggleSidebar={toggleSidebar} />
                {mainContent==="manangeAuction"&&<AdminPanelAuctionList />}
                {mainContent==="addUser"&&<AddUser/>}
                {mainContent==="manageUser"&&<ManageUser/>}
                {mainContent==="addAuction"&&<AddAuction/>}
            </div>
        </div>
    )
}

export default AdminPanel
