

import { Images } from '../../../constants/constant'

import BlogFive from '../../../assets/images/vihara/blog-image-5.png'
import BlogOne from '../../../assets/images/vihara/blog-image-1.png'
import BlogTwo from '../../../assets/images/vihara/blog-image-2.png'
import BlogThree from '../../../assets/images/vihara/blog-image-3.png'
import BlogFour from '../../../assets/images/vihara/blog-image-4.png'
import BlogSix from '../../../assets/images/vihara/blog-image-6.png'
import Divya from '../../../assets/images/vihara/profile/divyarastogi.png'



export const blogArray = [

    {
        id: 1,
        blogTitle: "The Ins and Outs of Fix and Flip Foreclosures and Bank-Owned Homes",
        writterImage: Images.TrishaBlog,
        writterName:"TRISHA SOIN",
        blogIimage: BlogOne,
        authorTittle:"Chief Operating Officer, Vihara",
        blogContent:``
    },
    {
        id: 2,
        blogTitle: "10 Ways to Remodel Your Foreclosure Property for Maximum Value",
        writterImage: Images.TrishaBlog,
        writterName: "TRISHA SOIN",
        blogIimage: BlogTwo,
        authorTittle:"Chief Operating Officer, Vihara",
        blogContent:``
    },
    {
        id: 3,
        blogTitle: "Navigating the Landscape of Foreclosure Houses Near You",
        writterImage: Images.TrishaBlog,
        writterName: "TRISHA SOIN",
        blogIimage: BlogThree,
        authorTittle:"Chief Operating Officer, Vihara",
        blogContent:``
    },
    {
        id: 4,
        blogTitle: "An Update on the Future of Work at Vihara",
        writterImage: Images.TrishaBlog,
        writterName: "TRISHA SOIN",
        blogIimage: BlogFour,
        authorTittle:"Chief Operating Officer, Vihara",
        blogContent:``
    }, {
        id: 5,
        blogTitle: "Vihara Partners with Serviceful to help with Sales and Marketing Strategies",
        writterImage:Images.TrishaBlog,
        writterName: "TRISHA SOIN",
        blogIimage: BlogFive,
        authorTittle:"Chief Operating Officer, Vihara",
        blogContent:``
    },
    {
        id: 6,
        blogTitle: "Buying a Home through Auction: A Step-by-Step Guide",
        writterImage:Images.TrishaBlog,
        writterName: "TRISHA SOIN",
        blogIimage: BlogSix,
        authorTittle:"Chief Operating Officer, Vihara",
        blogContent:``
    }
]