import React from 'react'
import "./Investors.css"
import { useState } from 'react'
import { BiddingEasierImageSection } from '../../../../HomeComponents/MakingBiddingEasier/MakingBiddingEasier';


function Investors() {
    const [hoveredOnIcon,setHoveredOnIcon]=useState({1:false,2:false,3:false,4:false,5:false,6:false,7:false,8:false});
    return (
        <div className="making-bidding-easier">
            <h1 style={{fontWeight:"450"}}>Learn how you can bid in auctions.</h1>
            {/* <button>READ MORE CUSTOMER STORIES</button> */}
            <BiddingEasierImageSection/>
        </div>
    )
}



export default Investors
