import React from 'react';
import './PurchaseProfile.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCircleCheck } from '@fortawesome/free-solid-svg-icons';

const MyPurchaseProfiles = () => {
  return (
    <div className="purchase-profile-container">
      <div className="purchase-profile-header">
        <h4 className='portal-purchase-header'>My Purchase Profiles</h4>
      </div>

      <div className="purchase-profile-add">
        <div className="purchase-profile-add-box">
          <span className="purchase-profile-add-icon"><FontAwesomeIcon style={{ color: "#d6e1ec", fontSize: "24px" }} icon={faPlus} /></span>
          <span className="purchase-profile-add-text">Add New Profile</span>
        </div>
      </div>

      <div className="purchase-profile-save-time">
        <h3>Save Time, Bid Faster!</h3>
        <p>
          Use the information on this buyer information form to create a purchase profile. Using a purchase profile will make bidding on another property easier and faster.
        </p>
        <div className="purchase-profile-input">
          <label htmlFor="profile-name">Purchase Profile Name</label>
          <div className='purchase-profile-character-limit-container'>
            <input
              type="text"
              id="profile-name"
              maxLength="16"
              className="purchase-profile-input-field"
            />
            <span className="purchase-profile-char-limit">Character limit: 16</span>
          </div>
        </div>
      </div>
      <div className="purchase-profile-section-title">
        <FontAwesomeIcon className='purchase-profile-buyer-icon' icon={faCircleCheck} /> <h4>Buyer/ Agent Information</h4>
      </div>
      <div className="purchase-profile-agent-info">
        <div className="purchase-profile-radio-group">
          <label>What describes you best? *</label>
          <div className='purchase-radio-input-container'>
            <div>
              <input type="radio" id="buyer" name="role" />
              <label htmlFor="buyer">Buyer</label>
            </div>
            <div>
              <input type="radio" id="agent" name="role" />
              <label htmlFor="agent">Agent</label>
            </div>
          </div>
        </div>
        <div className="purchase-profile-radio-group">
          <label>Are you buying this property for yourself? *</label>
          <div className='purchase-radio-input-container'>
            <div>
              <input type="radio" id="yes" name="selfPurchase" />
              <label htmlFor="yes">Yes</label>
            </div>
            <div>
              <input type="radio" id="no" name="selfPurchase" />
              <label htmlFor="no">No</label>
            </div>
          </div>
        </div>
        <button className="purchase-profile-continue-btn">Continue</button>
      </div>

      <div style={{marginBottom:"0px",borderBottom:"none"}} className="purchase-profile-section-title">
      <FontAwesomeIcon className='purchase-profile-buyer-icon' icon={faCircleCheck} /> <h4>Buyer's Information</h4>
      </div>

      <div style={{marginBottom:"0px",borderBottom:"none"}} className="purchase-profile-section-title">
      <FontAwesomeIcon className='purchase-profile-buyer-icon' icon={faCircleCheck} /> <h4>Agent's Information</h4>
      </div>
    </div>
  );
};

export default MyPurchaseProfiles;
