import React, { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';

// Create a context
const FilterContext = createContext();

// Create a custom hook to use the context
export const useFilterContext = () => useContext(FilterContext);

// Create a context provider
export const FilterProvider = ({ children }) => {
    const [propertyType, setPropertyType] = useState([]);
    const [assetType, setAssetType] = useState([]);
    const [buyingType, setBuyingType] = useState([]);
    const [condition, setCondition] = useState([]);
    const [featured, setFeatured] = useState([]);
    const [moreFilters, setMoreFilters] = useState([]);
    const [beds, setBeds] = useState("Any"); // State for beds input
    const [baths, setBaths] = useState("Any"); // State for baths input
    const [minSquareFeet, setMinSquareFeet] = useState(""); // State for min square feet input
    const [maxSquareFeet, setMaxSquareFeet] = useState(""); // State for max square feet input
    const handleClearFilters = () => {
        setPropertyType([]);
        setAssetType([]);
        setBuyingType([]);
        setCondition([]);
        setFeatured([]);
        setMoreFilters([]);
        setMinSquareFeet("");
        setMaxSquareFeet("");
        setBeds("Any");
        setBaths("Any");
    };
    const handleSelectChange = (setter) => (newValues) => {
        setter(newValues);
    };
    return (
        <FilterContext.Provider
            value={{
                propertyType,
                setPropertyType,
                assetType,
                setAssetType,
                buyingType,
                setBuyingType,
                condition,
                setCondition,
                featured,
                setFeatured,
                moreFilters,
                setMoreFilters,
                beds,
                setBeds,
                baths,
                setBaths,
                minSquareFeet,
                setMinSquareFeet,
                maxSquareFeet,
                setMaxSquareFeet,
                handleClearFilters,
                handleSelectChange
            }}
        >
            {children}
        </FilterContext.Provider>
    );
};
FilterProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
