import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@mui/material';
import MapIcon from '@mui/icons-material/Map';
import ListIcon from '@mui/icons-material/List';
import CustomDropdown from './DropDowns/CustomDropDown';
import { useFilterContext } from '../../../ContextProvider/Context';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ConditionDropDown from './DropDowns/ConditionDropDown';
const BuyNavbarSmallerScreen = ({ showMap, handleShowMap }) => {
  const {
    propertyType,
    setPropertyType,
    assetType,
    setAssetType,
    buyingType,
    setBuyingType,
    condition,
    setCondition,
    featured,
    setFeatured,
    moreFilters,
    setMoreFilters,
    handleClearFilters,
    handleSelectChange
  } = useFilterContext();

  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <>
      <AppBar position="static" style={{ backgroundColor: "#f8f9fa" }}>
        <Toolbar>
          <div style={{ flexGrow: 1, display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <IconButton onClick={toggleDrawer}>
              <FontAwesomeIcon icon={faFilter} style={{ color: "#007bff", border: "2px solid #036eb5", padding: "4px", borderRadius: "5px" }} />
            </IconButton>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <Button variant="contained" sx={{ backgroundColor: '#007bff', color: 'white', fontWeight: 'bold' }}>Save Search</Button>
            <IconButton style={{ border: "1px solid #118BF4", borderRadius: "8px", height: "38px" }} color="inherit" onClick={handleShowMap}>
            <ListIcon style={{ color: showMap ? "#dde0e0" : "#007bff", marginRight: "5px", borderRadius: "5px" }} />  <MapIcon style={{ color: showMap ? "#007bff" : "#dde0e0", borderRadius: "5px" }} />
          </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer anchor="top" open={drawerOpen} onClose={toggleDrawer}>
        <List>
          <ListItem>
            <ListItemText primary="Filters" />
          </ListItem>
          <Divider />
          <ListItem>
            <CustomDropdown
              width={"100%"}
              label="Property"
              options={['Multiplex', 'Duplex', 'Shop', 'Land', 'Single Family Home']}
              selectedValues={propertyType}
              onSelect={handleSelectChange(setPropertyType)}
            />
          </ListItem>
          <ListItem>
            <CustomDropdown
              width={"100%"}
              label="Asset Type"
              options={['Bank Owned', 'Foreclosure', 'Private']}
              selectedValues={assetType}
              onSelect={handleSelectChange(setAssetType)}
            />
          </ListItem>
          <ListItem>
            <CustomDropdown
              width={"100%"}
              label="Buying Type"
              options={['Online Auction', 'In Person Auction', 'Remote Bid', 'Direct Offer']}
              selectedValues={buyingType}
              onSelect={handleSelectChange(setBuyingType)}
            />
          </ListItem>
          <ListItem>
            <ConditionDropDown
              width={"100%"}
              label="Condition"
              options={['Vacant', 'Interior Access Available', 'Eligible for Financing']}
              selectedValues={condition}
              onSelect={handleSelectChange(setCondition)}
            />
          </ListItem>
          <ListItem>
            <CustomDropdown
              width={"100%"}
              label="Featured"
              options={['Foreclosure Predict', 'Hot Foreclosure', 'Hot Bank Owned', 'Price Drop', '$1 Opening Bid', 'Now Vacant']}
              selectedValues={featured}
              onSelect={handleSelectChange(setFeatured)}
            />
          </ListItem>
          <ListItem>
            <CustomDropdown
              width={"100%"}
              label="More Filters"
              options={['Hide Which Aution ends soon', 'Sold,Pending, or Canceled', 'Broker Co-op', 'Reserve Disclosed', 'No Buyer`s', 'First Look']}
              selectedValues={moreFilters}
              onSelect={handleSelectChange(setMoreFilters)}
            />
          </ListItem>
          <Divider />
          <ListItem>
            <Button onClick={handleClearFilters} variant="text" color="primary">Clear Filters</Button>
          </ListItem>
        </List>
      </Drawer>
    </>
  );
};

export default BuyNavbarSmallerScreen;
