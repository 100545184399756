import React, { useEffect, useState } from 'react';
import './SavedUpdates.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faDollar, faCircleInfo, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import BidHistoryModal from './BidHistoryModal';
import PropertyCard from '../Buy/PropertyCard/PropertyCard';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { setUser } from '../../features/user/userSlice';
import { toast } from 'react-toastify';
import { setProperties } from '../../features/property/propertySlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import NavBackground from '../includes/navBackground';
const SavedUpdates = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentBid, setCurrentBid] = useState(0);
    const [minIncrement, setMinIncrement] = useState(0);
    const navigate = useNavigate();
    const [nextBid, setNextBid] = useState([]);
    const user = useSelector((state) => state.user.user);
    const bids = [
        { amount: 450001, you: true },
        { amount: 425000, you: true },
        { amount: 400000, you: false }
    ];
    useEffect(() => {
        if (user && user.savedProperties) {
            const temp = user.savedProperties.map(property => {
                return property.startBid + property.minIncrement;
            });
            setNextBid(temp);
        }
    }, [user]);
    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    const totalPurchase = (index) => {
        const numericNextBid = Number(nextBid[index]); // Ensure nextBid is a number
        return numericNextBid + (numericNextBid * 5) / 100;
    };
    const earnestMoney = (index) => {
        const numericNextBid = Number(nextBid[index]); // Ensure nextBid is a number
        return (numericNextBid + (numericNextBid * 5) / 100) * 5 / 100;
    }
    const BidValue = (index, newValue) => {
        const newArray = [...nextBid];
        newArray[index] = newValue;
        setNextBid(newArray);
    }
    if (!user) {
        return navigate("/login")
    }
    if (user.savedProperties.length === 0) {
        return <div className='nothing-to-show-image'>
            <img src="https://img.freepik.com/premium-vector/nothing-here-flat-illustration_418302-77.jpg" />
        </div>
    }
    return (
        
            <div className='save-properties-whole-container'>
                <NavBackground />
                {user?.savedProperties ? user?.savedProperties.map((product, index) => (

                    <div className='saved-assets-container' key={index}>
                        <PropertyCard product={product} />
                        <div className="auction-bid-container">
                            <div className='saved-updates-outer-container'>
                                <div className="auction-details">
                                    <div>
                                        {/* <p className="auction-duration">Auction Duration: <span className='auction-date-header'>{product.auctionStartDate}, {product.auctionStartTime} - {product.auctionEndDate}, {product.auctionEndTime}</span></p> */}
                                        <div className="outbid-notice">
                                            <p className="outbid-text"><FontAwesomeIcon icon={faCircleExclamation} /> You were outbid.</p>
                                            <p className="bid-again">Bid again.</p>
                                        </div>
                                        <div className="bid-info">
                                            <div className="info-item">
                                                <p className="label">Current Bid</p>
                                                <p className="value">${product.startBid}</p>
                                            </div>
                                            <div className="info-item">
                                                <p className="label">Bid Increment</p>
                                                <p className="value">${product.minIncrement}</p>
                                            </div>
                                            <div className="info-item">
                                                <p className="label">Reserve <FontAwesomeIcon icon={faCircleInfo} /></p>
                                                <p className="value" id="not-met">Not Met</p>
                                            </div>
                                        </div>
                                    </div>
                                    <a href="#" className="faq-link">See FAQs</a>
                                </div>
                                <div className="bid-section">
                                    <div className="bidding-ends">
                                        <p className="label">Bidding Ends In</p>
                                        <p className="time">8h 29m</p>
                                    </div>
                                    <div className="next-bid">
                                        <p className="label">Your Next Bid</p>
                                        <input type="number" value={nextBid[index]} onChange={(e) => BidValue(index, e.target.value)} placeholder={nextBid} id="next-bid-input" min={nextBid[index]} />

                                    </div>
                                    <div className="purchase-info">
                                        <div className="info-item auction-buyers-premium purchase-details">
                                            <p className="label">Buyer&apos;s Premium</p>
                                            <p className="value">${(nextBid[index] * 5 / 100).toFixed(2)}</p>
                                        </div>
                                        <div className="info-item purchase-details" id="total-purchase-price">
                                            <p className="label">Total Purchase Price</p>
                                            <p className="value">${totalPurchase(index).toFixed(2)}</p>
                                        </div>
                                        <div className="info-item" id="earnest-money-deposit">
                                            <p className="label">Earnest Money Deposit</p>
                                            <p className="value">${earnestMoney(index).toFixed(2)}</p>
                                        </div>
                                    </div>
                                    <button className="place-bid-button">Place Bid</button>
                                    <div className="additional-links">
                                        <div href="#" className="link" onClick={handleOpenModal}><FontAwesomeIcon icon={faClock} /> Bid History</div>
                                        <div href="#" className="link"><FontAwesomeIcon icon={faDollar} />Cost Breakdown</div>
                                    </div>
                                </div>
                            </div>
                            {isModalOpen && <BidHistoryModal onClose={handleCloseModal} bids={bids} />}
                        </div>
                    </div>
                )) : <>Loading...</>}
            </div>
    );
};

export default SavedUpdates;
