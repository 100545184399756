import React from 'react'
import HeroSectionNews from './heroSection/heroSectionNews'
import NavBackground from '../includes/navBackground'
import NewsUpdates from './NewsUpdate/NewsUpdates'
import NewsBottom from './NewsBottom/NewsBottom'
import "./News.css"
function News() {
    window.scrollTo(0, 0)
    return (
        <>
            <NavBackground />
            <div className='new-page-container'>
                <h1 className='news-page-title'>Newsroom</h1>
                <HeroSectionNews />
                <NewsUpdates />
            </div>
            <NewsBottom/>
        </>
    )
}

export default News
