import React from 'react';
import './MyBids.css';

const MyBids = () => {
  return (
    <div className="portal-my-bids-container">
      <div className="portal-my-bids-header">
        <h2 className='portal-profile-header'>My Bids</h2>
        <a href="#" className="portal-my-bids-history-link">History</a>
      </div>
      <div className="portal-my-bids-content">
      <div className="portal-my-bids-last-updated">
          Last Updated: Sep 7, 2024 16:46:44 ET
        </div>
        <p>Currently you do not have active bids on any of the properties</p>
      </div>
    </div>
  );
};

export default MyBids;
