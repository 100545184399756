import { configureStore } from '@reduxjs/toolkit';
import userReducer from "./features/user/userSlice"
import adminReducer from "./features/admin/adminSlice";
import propertyReducer from "./features/property/propertySlice";
const store = configureStore({
  reducer: {
    user: userReducer,
    admin:adminReducer,
    property:propertyReducer
  },
});
export default store;