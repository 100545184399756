import React from 'react'
import './Main.css'
// import NavBackground from '../../../includes/navBackground'
import myvideo from "../../../../assets/video/landing_page_video.mp4"
const MainAbout = ({ image, title }) => {
    return (

        <>
           {!image&&<div className='dark-overlay-about-us'></div>} 
            {image ? <img src={image} className="about-us-background-image" /> :
             <video autoPlay loop muted className="about-us-background-video">
                <source src={myvideo} type="video/mp4" />
                Your browser does not support the video tag.
            </video>}
            <div className="about-main-container">
                <p>
                  {title?title:"Experience the future of real estate auctions"}  
                </p>
                {/* <a href="">WATCH VIDEO</a> */}
            </div>
        </>
    )
}

export default MainAbout