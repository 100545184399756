// src/features/admin/adminSlice.js
import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
const initialState = {
  admin: null,
  isAuthenticated: false,
};

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setadmin: (state, action) => {
      state.admin = action.payload;
      state.isAuthenticated = true;
    },
    clearadmin: (state) => {
        state.admin = null;
        state.isAuthenticated = false;
        Cookies.remove('token');
    },
  },
});

export const { setadmin, clearadmin } = adminSlice.actions;

export default adminSlice.reducer;
