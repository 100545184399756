import React, { useState } from 'react';
import './SetNewPassword.css';
// import logo from './assets/vihara-logo.png'; // Adjust the path as necessary
import { Images } from '../../../../constants/constant';
import NavBackground from '../../../includes/navBackground';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
const SetNewPassword = () => {
    const { token } = useParams();
    const [passwordError, setPasswordError] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const navigate=useNavigate();
    const handleConfirmPasswordChange = (e) => {
        const newConfirmPassword = e.target.value;
        setConfirmPassword(newConfirmPassword);

        if (newConfirmPassword && newConfirmPassword !== password) {
            setConfirmPasswordError('Passwords do not match.');
        } else {
            setConfirmPasswordError('');
        }
    };
    const toggleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };
    const validatePassword = (password) => {
        const passwordCriteria = /^(?=.*[A-Z])(?=.*\d)(?=.*[!#@%$;^])[A-Za-z\d!#@%$;^]{8,}$/;
        return passwordCriteria.test(password);
    };
    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        if (newPassword === "") {
            setPasswordError('');
            return;
        }
        if (!validatePassword(newPassword)) {
            setPasswordError('Password should be 8 or more in length with at least one number, special character (! # @ % $ ; ^), and uppercase letter.');
        } else {
            setPasswordError('');
        }
    };
    const handleClick = async (e) => {
        e.preventDefault();
        if (!password) {
            setPasswordError("Password is required");
            return;
        }
        if (!confirmPassword) {
            setConfirmPasswordError("Confirm Password is required");
            return;
        }
        if (password !== confirmPassword) {
            setConfirmPasswordError("Passwords do not match");
            return;
        }
        try {
            const response = await axios.post(`https://viharabackend.onrender.com/api/v1/user/password/reset/${token}`, {
                password,
            })
            console.log(response);
            toast.success("Password Changed Successfully");
            navigate("/login");
        } catch (err) {
            console.log(err);
            toast.error("Failed to change password");
        }
    }
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };
    return (
        <>
            <NavBackground />
            <div className="new-password-container">
                <img src={Images.logoOnDark} alt="Vihara Logo" className="logo" />
                <h2>Reset Password</h2>
                <form onSubmit={handleClick}>
                    <div style={{ flexDirection: "column" }} className='sign-up-input-group'>
                        <div style={{ marginBottom: "0px" }}>
                            <label className='sign-up-input-label'>
                                <span>Password:</span> <span>*</span>
                            </label>
                            <div style={{ marginBottom: "0px" }} className={`password-container ${passwordError ? 'input-error' : ''}`}>
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    value={password}
                                    onChange={handlePasswordChange}
                                />
                                <FontAwesomeIcon
                                    icon={showPassword ? faEye : faEyeSlash}
                                    className="password-icon"
                                    onClick={toggleShowPassword}
                                />
                            </div>
                        </div>
                        {passwordError && <div style={{ fontSize: "13px", color: 'red', fontWeight: "300" }}>{passwordError}</div>}
                        <div style={{ marginBottom: "0px" }}>
                            <label className='sign-up-input-label'>
                                <span>Confirm Password:</span> <span>*</span>
                            </label>
                            <div style={{ marginBottom: "0px" }} className={`password-container ${confirmPasswordError ? 'input-error' : ''}`}>
                                <input
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    value={confirmPassword}
                                    onChange={handleConfirmPasswordChange}
                                    disabled={!validatePassword(password)}
                                />
                                <FontAwesomeIcon
                                    icon={showConfirmPassword ? faEye : faEyeSlash}
                                    className="password-icon"
                                    onClick={toggleShowConfirmPassword}
                                />
                            </div>
                        </div>
                        {confirmPasswordError && <div style={{ fontSize: "13px", color: 'red', fontWeight: "300" }}>{confirmPasswordError}</div>}
                    </div>
                    <button type='submit' className="set-new-password-button">
                        Set New Password
                    </button>
                </form>
            </div>
        </>
    );
};

export default SetNewPassword;
