import React, { useState } from "react";
import "./PrepearingOnlineAuction.css";
import NavBackground from "../../../includes/navBackground";
import PropTypes from "prop-types";
// import biddingImage from "../../assets/bidding.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faArrowDown, faCopy, } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';

import ImageOne from "../assets/1.png";
import ImageTwo from "../assets/2.png";
import ImageThree from "../assets/3.png";
import ImageFour from "../assets/4.png";
import ImageFive from "../assets/5.png";
import BudgetImage from "../assets/bidgetImage.png"
import PropertyInspectionImage from "../assets/PropertyInscpection.png"
import LegislativeImage from "../assets/Legislative.png"
import researchImage from "../../assets/research2.png";
import StretegicBidding from "../assets/StretegicBidding.png"


const PreparinHeroSection = () => {
  return (
    <div>
      <div className="preparing-main-hero-section">
        <div className="preparing-main-hero-section-left">
          <p className="preparing-main-hero-section-left-head">
            Bidding in Auctions
          </p>
          <p className="preparing-main-hero-section-left-heading">
            Learn how to prepare for an auction
          </p>
          <p className="preparing-main-hero-section-left-para">
            Whether you&apos;re a seasoned property investor or a first-time
            participant in an auction, Vihara is here to help. This page equips
            you with the strategies necessary to navigate the dynamic process of
            online property auctions successfully.
          </p>
          <div className="bidding-card-container">
            <div className="bidding-in-auction-card">
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRDQJfLjm7eBgcsZAfyjoWizaMdpI_PgCZKqw&s"
                alt="bidding image"
                className="bidding-in-auction-card-logo"
              />
              <div className="bidding-in-auction-card-text">
                <h3>About 56% of foreclosed homes</h3>
                <p>in the United States have been sold through auctions.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="preparing-main-hero-section-right">
          <img
            src={researchImage}
            alt=""
            className="preparing-main-hero-section-right-image"
          />
        </div>
      </div>
    </div>
  );
};

// StrategicSection

const PlusSign = () => {
  return (
    <svg
      className="prepare-online-plus-sign"
      width="22"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-v-3cbe5a62=""
    >
      <path
        d="M10 0V22"
        stroke="#0384FB"
        strokeWidth="2"
        data-v-3cbe5a62=""
      ></path>
      <path
        d="M21 11L8.34465e-07 11"
        stroke="#0384FB"
        strokeWidth="2"
        data-v-3cbe5a62=""
      ></path>
    </svg>
  );
};



const StrategicSection = () => {
  const [hoveredOnIcon, setHoveredOnIcon] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
  });

  return (
    <div className="strategic-section-outer-container">
      <div className="strategic-section-main-container">
        <p className="strategic-section-heading">
          Make bidding easier on the Vihara platform.
        </p>

        <div className="strategic-section-other-component">
          <div className="strategic-section-other-component-inner-elements">
            <img
              src={ImageOne}
              alt=""
              className="strategy-section-inner-image"
            />
            <a href="#strategic-bidding"
              className="strategy-section-inner-para"
              onMouseOver={() => setHoveredOnIcon({ 1: true })}
              onMouseLeave={() => setHoveredOnIcon({ 1: false })}
            >
              Strategic Bidding{" "}
              
            </a>
            <FontAwesomeIcon
                className="strategy-my-icon"
                icon={hoveredOnIcon[1] ? faArrowDown : faAngleDown}
              />
          </div>
          <PlusSign />

          <div className="strategic-section-other-component-inner-elements">
            <img
              src={ImageTwo}
              alt=""
              className="strategy-section-inner-image"
            />
            <a href="#budget-setting"
              className="strategy-section-inner-para"
              onMouseOver={() => setHoveredOnIcon({ 2: true })}
              onMouseLeave={() => setHoveredOnIcon({ 2: false })}
            >
              Budget Setting{" "}
              
            </a>
            <FontAwesomeIcon
                className="strategy-my-icon"
                icon={hoveredOnIcon[1] ? faArrowDown : faAngleDown}
              />
          </div>
          <PlusSign />

          <div className="strategic-section-other-component-inner-elements">
            <img
              src={ImageThree}
              alt=""
              className="strategy-section-inner-image"
            />
            <a href="#financial-readiness"
              className="strategy-section-inner-para"
              onMouseOver={() => setHoveredOnIcon({ 3: true })}
              onMouseLeave={() => setHoveredOnIcon({ 3: false })}
            >
              Financial Readiness{" "}
              
            </a>
            <FontAwesomeIcon
                className="strategy-my-icon"
                icon={hoveredOnIcon[1] ? faArrowDown : faAngleDown}
              />
          </div>
          <PlusSign />

          <div className="strategic-section-other-component-inner-elements">
            <img
              src={ImageFour}
              alt=""
              className="strategy-section-inner-image"
            />
            <a href="#property-inspection"
              className="strategy-section-inner-para"
              onMouseOver={() => setHoveredOnIcon({ 4: true })}
              onMouseLeave={() => setHoveredOnIcon({ 4: false })}
            >
              Property Inspection{" "}
              
            </a>
            <FontAwesomeIcon
                className="strategy-my-icon"
                icon={hoveredOnIcon[1] ? faArrowDown : faAngleDown}
              />
          </div>
          <PlusSign />

          <div className="strategic-section-other-component-inner-elements">
            <img
              src={ImageFive}
              alt=""
              className="strategy-section-inner-image"
            />
            <a href="#legal-due"
              className="strategy-section-inner-para"
              onMouseOver={() => setHoveredOnIcon({ 5: true })}
              onMouseLeave={() => setHoveredOnIcon({ 5: false })}
            >
              Legal Due Diligence
              
            </a>
            <FontAwesomeIcon
                className="strategy-my-icon"
                icon={hoveredOnIcon[1] ? faArrowDown : faAngleDown}
              />
          </div>
        </div>
      </div>
    </div>
  );
};

// OnlineBiddingProcess
// ======================
const OnlineBiddingProcess = () => {
  return (
    <div className="online-bidding-process-outer-section">
      <div className="online-bidding-process-left-section">
        <h2 className="online-bidding-main-heading">
          Simplifying the Bidding Process
        </h2>
        <div className="online-bidding-left-section-blocks">
          <div className="online-bidding-left-section-block">
           <span> 300,000+ </span>Foreclosure Homes
          </div>
          <div className="online-bidding-left-section-block">
           <span> 30,000+</span> Auctions per Month
          </div>
          <div className="online-bidding-left-section-block">
           <span> 20% (up arrow) </span>in online auctions
          </div>
        </div>
      </div>
      <div className="online-bidding-process-right-section">
        <p className="onlinebidding-right-head">The Challenge</p>
        <h3 className="onlinebidding-right-heading">
          Researching Properties Effectively and developing bidding strategies
        </h3>
        <div className="online-bidding-right-para">
          <p>
            With thousands of options, researching properties online for an
            auction can be tough. From limited detailed information to
            incomplete descriptions and outdated data about the property’s
            condition, verifying the accuracy of the property is hard without
            in-person inspections or reliable sources.
          </p>
          <p>
            Before Vihara, real estate investors had to visit properties
            in-person and verify property information through third-party
            vendors or other reliable sources. To perform in an auction, they
            needed one integrated platform that could hold accurate property
            information with an easy-to-use interface.
          </p>
        </div>
      </div>
    </div>
  );
};

// Quote Content
// =======================


const QuoteBig = () => {
  return (
    <svg
      width="80"
      height="69"
      viewBox="0 0 91 79"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ height: "55px", width: "100%", minWidth: "42px" }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.4274 15.2995V0.7994C13.4757 2.83452 0 17.0802 0 43.5367V78.2607H40.4274V37.813H22.502C22.502 24.8391 28.4771 17.3347 40.4274 15.2995ZM91 15.2995V0.7994C64.0483 2.83452 50.5726 17.0802 50.5726 43.5367V78.2607H91V37.813H73.0747C73.0747 24.8391 79.0497 17.3347 91 15.2995Z"
        fill="#0384FB"
      ></path>
    </svg>
  );
};

const PreparingQuoteContent = ({
  bgColor,
  textColor,
  para,
  author,
  designation,
}) => {
  return (
    <div
      className="quotes-main-section-outer"
      style={{ backgroundColor: bgColor }}
    >
      <div className="quote-main-inner-section" style={{ color: textColor }}>
        <div className="quote-image-section-left">
          <QuoteBig className="qoute-big-to-small" />
        </div>
        <div className="quote-right-section-container">
          <p className="quoted-para-of-quote-section" style={{ color: textColor }}>{para}</p>
          <p className="quote-author-name" style={{ color: textColor }}>{author}</p>
          <p className="quote-author-designation" style={{ color: textColor }}>{designation}</p>
        </div>
      </div>
    </div>
  );
};

PreparingQuoteContent.propTypes = {
  bgColor: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  para: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  designation: PropTypes.string.isRequired,
};

// An integrated platform to do your due diligence
// =============================================
// =============================================
// ===============================================
const BasicImageSection = ({ imageLocation, mainHeading, mainPara,width }) => {
  return (
    <div className="integrated-platform-main-section" style={{width:width?width:""}}>
      <div className="platform-section-main-image">
        <img
          src={imageLocation}
          alt={imageLocation}
          className="platform-section-main-img"
        />
      </div>
      <div className="integrated-section-main-right-section">
        <p className="integrated-platform-main-heading">{mainHeading}</p>
        <p className="integrated-platform-main-para">{mainPara}</p>
        <a className="integrated-platform-learnmore-button" href="">
          Learn More <FontAwesomeIcon icon={faCopy} />
        </a>
      </div>
    </div>
  );
};
BasicImageSection.propTypes = {
  imageLocation: PropTypes.node.isRequired, // Assuming imageLocation is a React node (could be an image element)
  mainHeading: PropTypes.string.isRequired,
  mainPara: PropTypes.string.isRequired,
};


const BasicImageSectionInverted = ({ imageLocation, mainHeading, mainPara }) => {
  return (
    <div className="integrated-platform-main-section-inverted">

      <div className="integrated-section-main-right-section-inverted">
        <p className="integrated-platform-main-heading-inverted">{mainHeading}</p>
        <p className="integrated-platform-main-para-inverted">{mainPara}</p>
        <a className="integrated-platform-learnmore-button-inverted" href="">
          Learn More <FontAwesomeIcon icon={faCopy} />
        </a>
      </div>
      <div className="platform-section-main-image">
        <img
          src={imageLocation}
          alt={imageLocation}
          className="platform-section-main-img"
        />
      </div>
    </div>
  );
};
BasicImageSectionInverted.propTypes = {
  imageLocation: PropTypes.node.isRequired, // Assuming imageLocation is a React node (could be an image element)
  mainHeading: PropTypes.string.isRequired,
  mainPara: PropTypes.string.isRequired,
};


const IntegratedPlatform = () => {
  return (
    <div className="integrated-platform-main-container">
      <p className="integrated-platform-head">The Solution</p>
      <p className="integrated-platform-main-heading integrated-platform-main-heading-main">
        An integrated platform to do your due diligence
      </p>
      <p className="integrated-platform-main-para  integrated-platform-main-para-main ">
        With Vihara, investors are able to conduct thorough property research –
        neighborhood amenities, property market value, property condition, legal
        issues, and walkability index – by using one integrated platform. This
        enables investors to learn more about properties before placing bids and
        successfully winning in an auction.
      </p>
      <BasicImageSection
        imageLocation={StretegicBidding}
        width="100%"
        mainHeading="Using a Strategic Bidding Approach"
        mainPara="Having a strategic bidding approach enhances your likelihood of winning a property at the most favorable price in an auction. Before you start bidding, ensure that you're prepared to bid well in advance of the auction start date. If you're particularly interested, we suggest submitting a prior auction offer to expedite the sale agreement with the seller."
      />
    </div>
  );
};



const PrepearingOnlineAuction = () => {
  return (
    <div>
      <NavBackground />
      <PreparinHeroSection />
      <StrategicSection />
      <OnlineBiddingProcess />
      <PreparingQuoteContent
        bgColor="#00263e"
        textColor="#fff"
        para="“Instead of juggling through multiple vendors and sources, we now have a one-stop shop to learn about auction properties. That’s why I bid on Vihara.”"
        author="Akshay Gupta"
        designation="Real Estate Investor, California"
      />

      <div id="strategic-bidding">
        <IntegratedPlatform />
      </div>
      <PreparingQuoteContent
        bgColor="#f0f6fe"
        textColor="#00263e"
        para="“Knowing the property value before bidding is important so you can make an informed decision.”"
        author="Twinkle Khanna"
        designation="Real Estate Agent, Intero "
      />
      <div id="budget-setting">
        <BasicImageSectionInverted

          imageLocation={BudgetImage}
          mainHeading="Setting a Budget before bidding to ensure you’re ready"
          mainPara="Decide on a clear budget that covers more than just your highest bid, including extra costs like buyer's fees and potential repairs. Get pre-approved financing to be financially ready and act quickly when the right property comes up for auction, giving you an edge over others."
        />
      </div>
      <div className="basic-image-section" id="financial-readiness">
        <BasicImageSection
          imageLocation={StretegicBidding}
          mainHeading="Being financially ready before participating in an auction"
          mainPara="Financial readiness is crucial for success in an auction. It involves setting a clear budget that includes your maximum bid along with additional costs like buyer's fees, legal fees, and potential repairs. Being financially prepared gives you a competitive edge, enabling you to bid confidently and avoid last-minute financial hurdles"
        />
      </div>
      <div id="property-inspection">
        <BasicImageSectionInverted

          imageLocation={PropertyInspectionImage}
          mainHeading="Conduct Property Inspections before your auction"
          mainPara="Before the auction starts, thoroughly inspect the properties you're interested in. Use online resources on Vihara, virtual tours, and property descriptions to assess the condition, possible renovations, and neighborhood details. This detailed inspection helps you bid confidently, knowing the property's true value."
        />
      </div>
      <div className="basic-image-section" id="legal-due">
        <BasicImageSection
          imageLocation={LegislativeImage}
          mainHeading="Don’t overlook the legalities before bidding."
          mainPara="Don't forget the legal details. Do thorough research on the property and consult the legal pack with an expert. Understand the auction terms, deposit requirements, and timelines. This legal diligence protects your investment and ensures a smooth online auction process on Vihara."
        />
      </div>
      <PreparingQuoteContent
        bgColor="#f0f6fe"
        textColor="#00263e"
        para="“I’ve used several auction platforms before, but this is the first platform where property research is simplified.”"
        author="Twinkle Khanna"
        designation="Real Estate Agent, Intero "
      />
      <div className="preparing-online-section-sharing">
        <p>Share this story </p>
        <div className="preparing-online-section-sharing-icon">
          <FontAwesomeIcon size="xl" icon={faFacebook} />
          <FontAwesomeIcon size="xl" icon={faTwitter} />
          <FontAwesomeIcon size="xl" icon={faLinkedin} />
          <FontAwesomeIcon size="xl" icon={faEnvelope} />
        </div>
      </div>

    </div>
  );
};

export default PrepearingOnlineAuction;
