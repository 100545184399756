import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import MainAbout from '../Company/AboutUS/MainAbout/MainAbout';
import SubHead from '../Company/AboutUS/SubHead/SubHead';
import { GuidingPrincipleBox } from '../Company/Privacy/Privacy';
import { ForclosureCustomerResource, ForeclosureExperienceSection } from '../Resources/BuyingAForclosure/BuyingAForclosure';
import './LearnMorePages.css';

const LearnMorePages = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const data = queryParams.get('data');
  const auctionDayData = data ? JSON.parse(data) : null;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {auctionDayData && auctionDayData.length > 0 ? (
        <>
          <MainAbout image={auctionDayData[0].heroImage} title={auctionDayData[0].title} />
          <SubHead barText={auctionDayData[0].barText} />
          <div className='boxes-container-learn-more-pages'>
            {auctionDayData.slice(1).map((principle, index) => (
              <GuidingPrincipleBox
                text={auctionDayData[0].barText[index]}
                key={index}
                id={principle.id}
                imageSrc={principle.imageSrc}
                belowPara={principle.belowPara}
                htmlContent={principle.htmlContent}
                title={principle.title}
                description={principle.description}
              />
            ))}
          </div>
          <div style={{ marginTop: '100px' }} className='boxes-container-learn-more-pages'>
            <ForclosureCustomerResource />
          </div>
          <div style={{ marginTop: '60px' }} className='boxes-container-learn-more-pages'>
            <ForeclosureExperienceSection
              expTitle='Experience the future of real estate auctions.'
              expDesc='We make bidding on foreclosure property auctions simpler and easier for you.'
              expButtonText='Browse Homes'
              link='Foreclosure'
            />
          </div>
        </>
      ) : (
        <p>No data found</p>
      )}
    </div>
  );
};

export default LearnMorePages;
