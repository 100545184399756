import React from 'react';
import './LearnMore.css';
import LearnMoreVideo from  "../../HomePage/commonAssets/LearnMore.mp4"
import NavBackground from '../../includes/navBackground';
import CommonHeroSection from '../CommonHeroSection/CommonHeroSection';
import Intutive from "./LearnMoreComponents/IntuitiveAndEasy/Intutive"
import EnterpriseGrade from './LearnMoreComponents/Convenience/EnterpriseGrade';
import Investors from './LearnMoreComponents/Investors/Investors';
import BuySellers from './LearnMoreComponents/BuyersSellers/BuySellers';
import Resources from "../../HomeComponents/Resources/Resources"
import ContactUs from '../../HomeComponents/ContactUs/ContactUs';
const LearnMore = () => {

    const handleLearnMoreClick = () => {
        // Define what happens when the button is clicked
        console.log('Learning more...');
      };
    return (
        <div style={{textAlign:"center"}}>
            <NavBackground />
            <CommonHeroSection
                videoSrc={LearnMoreVideo}
                title="Experience the future of real estate auctions."
                description="Expansive listings, remote bids, transparent transactions and more. All on one platform."
                buttonText="Learn More Page Video"
                buttonAction={handleLearnMoreClick}
            />
            <Intutive />
            <EnterpriseGrade/>
            <Investors/>
            <BuySellers/>
            <Resources/>
            <ContactUs/>
        </div>
    )
}

export default LearnMore