import React from 'react'

const AdditionalLinks = () => {
  return (
    <div className="privacy-content-additional-section">
    <h2>Additional Links</h2>

    <h4>
        <a href="/privacy-statement">Privacy Statement</a>
    </h4>

    <h4>
        <a href="/website-terms-of-use">Website Terms of Use</a>
    </h4>
    <h4>
        <a href="#">Cookie Preferences</a>{" "}
    </h4>
    <h4>
        <a href="/privacy-choice">Privacy Choices</a>{" "}
    </h4>
    <h4>
        <a href="/participation-terms">Participation Terms </a>
    </h4>
    <h4>
        <a href="/accessibility">Accessibility</a>
    </h4>
    <h4>
        <a href="/contact-support">Contact Support</a>
    </h4>
</div>
  )
}

export default AdditionalLinks