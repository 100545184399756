import React, { useState, useEffect, useRef } from 'react';
import './CustomDropDown.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

const CustomDropdown = ({ options, placeholder, Data, setData }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const dropdownRef = useRef(null);
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionSelect = (option) => {
        setData(option);
        setIsOpen(false);
        setSearchTerm(option);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        // setData(e.target.value);
    };
    const handleAnyType = () => {
        if (placeholder === "Select State") setData("All States");
        else setData("All Types")
        setIsOpen(false);
        setSearchTerm(''); // Clear the search term when an option is selected
    }
    const filteredOptions = options.filter(option =>
        option.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const styles = {
        borderColor: isOpen ? "rgb(3, 132, 251)" : "",
        borderBottom: isOpen ? "0px" : "",
        outline: "none",
        borderBottomLeftRadius: isOpen ? "0px" : "",
        borderBottomRightRadius: isOpen ? "0px" : ""
    };

    return (
        <div className="event-calender-dropdown" ref={dropdownRef}>
            <input
                type="text"
                value={searchTerm}
                onClick={toggleDropdown}
                onChange={handleSearchChange}
                placeholder={placeholder}
                className="event-calender-dropdown-input"
                style={styles}
            />
            <div className="dropdown-icon" onClick={toggleDropdown}>
                <FontAwesomeIcon icon={faCaretDown} style={{ color: "grey" }} />
            </div>

            {isOpen && (
                <div className="event-calender-dropdown-menu">
                    <div
                        onClick={() => handleAnyType()}
                        className="event-calender-dropdown-item"
                    >
                        Any Type
                    </div>
                    {filteredOptions.map((option) => (
                        <div
                            key={option}
                            onClick={() => handleOptionSelect(option)}
                            className="event-calender-dropdown-item"
                        >
                            {option}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default CustomDropdown;
