import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import guide from "../../HomePageBlog/assets/guide.png"
import sales from "../../HomePageBlog/assets/sales.png"
import homes from "../../HomePageBlog/assets/homes.png"
import work from "../../HomePageBlog/assets/work.png"
import "./Resources.css"
function Resources() {
  const navigate = useNavigate();
  const handleClick = (blogname) => {
    window.location.href = `/blog/${blogname}`;
  }
  const [hoveredOnIcon, setHoveredOnIcon] = useState({ 1: false, 2: false, 3: false, 4: false, 5: false, 6: false, 7: false, 8: false });
  return (
    <div className="resources">
      <div className='resources-container'>
        <h3>Resources</h3>
        <h2 className='resources_heading'>Keep up with the latest news and Vihara updates.</h2>
        <div className='resources-container-main'>
          <div className='image-and-text-container'>
            <img className='resources-blog-images' src={guide} />
            <div className='text-container'>
              <span>Buying a Home through Auction: A Step-by-Step Guide</span>
              <a
                onClick={() => handleClick("Why-you-should-buy-a-Foreclosure-Home")}
                className='hyper-links-blog-linking' onMouseOver={() => setHoveredOnIcon({ 1: true })} onMouseLeave={() => setHoveredOnIcon({ 1: false })}>Read more <FontAwesomeIcon className='resources-my-icons' icon={hoveredOnIcon[1] ? faArrowRight : faAngleRight} /></a>
            </div>
          </div>
          <div className='image-and-text-container'>
            <img className='resources-blog-images' src={sales} />
            <div className='text-container'>
              <span>Why you should buy <br /> a REO Bank Owned Home</span>
              <a
                onClick={() => handleClick("Why-you-should-buy-a-REO-Bank-Owned-Home")}
                className='hyper-links-blog-linking' onMouseOver={() => setHoveredOnIcon({ 2: true })} onMouseLeave={() => setHoveredOnIcon({ 2: false })}>Read more <FontAwesomeIcon className='resources-my-icons' icon={hoveredOnIcon[2] ? faArrowRight : faAngleRight} /></a>
            </div>
          </div>
          <div className='image-and-text-container'>
            <img className='resources-blog-images' src={homes} />
            <div className='text-container'>
              <span>10 Ways to Remodel Your Foreclosure Property for Maximum Value</span>
              <a
                onClick={() => handleClick("10-Ways-to-Remodel-Your-Foreclosure-Property-for-Maximum-Value")}
                className='hyper-links-blog-linking' onMouseOver={() => setHoveredOnIcon({ 3: true })} onMouseLeave={() => setHoveredOnIcon({ 3: false })}>Read more <FontAwesomeIcon className='resources-my-icons' icon={hoveredOnIcon[3] ? faArrowRight : faAngleRight} /></a>
            </div>
          </div>
          <div className='image-and-text-container'>
            <img className='resources-blog-images' src={work} />
            <div className='text-container'>
              <span>An Update on the Future of <br /> Work at Vihara</span>
              <a className='hyper-links-blog-linking' href='#' onMouseOver={() => setHoveredOnIcon({ 4: true })} onMouseLeave={() => setHoveredOnIcon({ 4: false })}>Read more <FontAwesomeIcon className='resources-my-icons' icon={hoveredOnIcon[4] ? faArrowRight : faAngleRight} /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Resources
