import React, { useState } from 'react';
import './OtpInputContainer.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUser } from '../../../features/user/userSlice';
import axios from 'axios';
import { toast } from 'react-toastify';
// dispatch(setUser(response.data.user))
// localStorage.setItem("token", response.data.token);
const OtpInput = ({ Id, user, token }) => {
    const [otp, setOtp] = useState(new Array(4).fill(''));
    const navigate = useNavigate();
    const dispatch = useDispatch();
    console.log(Id, user, token);
    const handleChange = (element, index) => {
        if (isNaN(element.value)) return;
        let newOtp = [...otp];
        newOtp[index] = element.value;
        setOtp(newOtp);

        // Focus next input box
        if (element.nextSibling) {
            element.nextSibling.focus();
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(otp.join(''))
        try {
            const response = await axios.get(`https://2factor.in/API/V1/47c7e94a-6c3f-11ef-8b17-0200cd936042/SMS/VERIFY/${Id}/${otp.join('')}`);
            console.log(response.data.Status);
            if (response.data.Status === "Success") {
                toast.success('OTP Verified Successfully');
                dispatch(setUser(user));
                localStorage.setItem("token", token);
                navigate("/");
            }
        } catch (error) {
            console.log(error.message);
        }
    };
    const handleDoItLater = () => {
        dispatch(setUser(user));
        localStorage.setItem("token", token);
        navigate("/");
    };

    return (
        <div className="otp-input-container-otp-verification-container">
            <h3>Verify Your Phone Number</h3>
            <form onSubmit={handleSubmit} className="otp-input-container-otp-form">
                <div>
                    {otp.map((data, index) => (
                        <input
                            className="otp-input-container-otp-input"
                            type="text"
                            maxLength="1"
                            key={index}
                            value={data}
                            onChange={(e) => handleChange(e.target, index)}
                            onFocus={(e) => e.target.select()}
                        />
                    ))}
                </div>
                <button type="submit" className="otp-input-container-verify-button">Verify</button>
                <span className="otp-input-container-or-text">Or</span>
                <button type="button" className="otp-input-container-do-it-later-button" onClick={handleDoItLater}>Do It later</button>
            </form>
        </div>
    );
};

export default OtpInput;
