import React from 'react'

import "./NavBackground.css"

const NavBackground = () => {
  return (
    <div className="nav-common-background">

    </div>
  )
}

export default NavBackground