import React from 'react'
import Buys from '../Buys'
function Bankowned() {
  return (
    <>
       <Buys/>
    </>
  )
}

export default Bankowned
