import React from 'react';
import './NewsUpdates.css';

const pressReleases = [
  {
    title: "Buying a Home through Auction: A Step-by-Step Guide",
    link:"Why-you-should-buy-a-Foreclosure-Home",
    date: "May 20, 2024"
  },
  {
    title: "10 Ways to Remodel Your Foreclosure Property for Maximum Value",
    link:"10-Ways-to-Remodel-Your-Foreclosure-Property-for-Maximum-Value",
    date: "April 14, 2024 "
  },
  {
    title: "Why you should buy a REO Bank Owned Home",
    link:"Why-you-should-buy-a-REO-Bank-Owned-Home",
    date: "November 11, 2023 "
  },
  {
    title: "Samsara to Host Investor Day on June 27th, 2024",
    date: "June 11, 2024"
  },
  {
    title: "New Samsara Research Indicates the AI Revolution Has Arrived in Physical Operations, and Industry Leaders Are Already Reaping the Benefits",
    date: "June 11, 2024"
  }
];

const newsInThePress = [
  "Data Insights and Innovations for Modern Fleets",
  "Samsara CTO and co-founder - 'We're going through a transition and expanding the platform'",
  "Samsara's product strategy: Innovation through partnership",
  "Introducing Samsara's Philip van der Wilt",
  "Refrigerated fleet grows with Samsara tech"
];

const imageSrc = "https://images.ctfassets.net/bx9krvy0u3sx/6lp7stJsbQlftIf0Yo7Z7S/50435f220b98be60733b7b5f545c8c1c/Screen_Shot_2019-12-02_at_9.24.20_AM.png?w=1534&fm=webp&q=80";

const NewsUpdates = () => {
  return (
    <>
      <div className="news-update-container">
        <div className="news-update-press-releases">
          <h5>Vihara Articles</h5>
          <ul>
            {pressReleases.map((release, index) => (
              <a href={`/blog/${release.link}`} target="_blank">
                <li key={index}>
                  <h3>{release.title}</h3>
                  <p>{release.date}</p>
                </li>
              </a>
            ))}
          </ul>
          <div className='small-screen-news-update-view-all'>
            <a style={{ width: "60%" }} href="#">View All</a>
          </div>
        </div>
        <div className="news-update-in-the-news">
          <h5>Articles</h5>
          <ul>
            {newsInThePress.map((news, index) => (
              <a href="https://www.businesswire.com/news/home/20240627835580/en/Samsara-Launches-Enterprise-Grade-Asset-Tag-to-Provide-Increased-Visibility-for-Physical-Operations" target="_blank">
                <li key={index}>
                  <p>{news}</p>
                  <img className='news-image' src={imageSrc} alt="News" />
                </li>
              </a>
            ))}
          </ul>
          <div className='small-screen-news-update-view-all'>
            <a style={{ width: "60%" }} href="#">View All</a>
          </div>
        </div>
      </div>
      <div className='news-update-view-all'>
        <a style={{ width: "60%" }} href="#">View All</a>
        <a style={{ width: "35%" }} href="#">View All</a>
      </div>
    </>
  );
};

export default NewsUpdates;
