import React from 'react';
import './SavedSearches.css';
const SavedSearches = () => {
  return (
    <div className="portal-my-searches-container">
      <div className="portal-my-searches-header">
        <h2 className='portal-profile-header portal-profile-my-search-header'>My searches</h2>
      </div>
      <div className="portal-my-searches-content">
        <p>Save your searches so we can tell you about new listings that match what you're looking for.</p>
      </div>
    </div>
  );
};

export default SavedSearches;
