import React, { useState, useEffect } from 'react';
import './Subhead.css';

const SubHead = ({ barText }) => {
    const [isSticky, setIsSticky] = useState(false);

    const handleScroll = () => {
        const stickyThreshold = 380; // Adjust this value as needed
        setIsSticky(window.scrollY > stickyThreshold);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <div className={`subhead-main`}>
            {/* <button onClick={() => scroll('left')}>&lt;</button> Left arrow */}
            <div className="subhead-scrollable" >
                <ul className="subhead-list">
                    {barText ? barText.map((text, index) => {
                       return  <li><a href={`#${text}`}  key={index} > {text} </a></li>
                           /*href={`#${text.replace(/\s+/g, '')}`}*/
                    }) :
                        <>
                            <li><a href="#our_mission">Our Mission</a></li>
                            <li><a href="#quick_facts">Quick Facts</a></li>
                            <li><a href="#our_history">Our History</a></li>
                            <li><a href="#our_people">Our People </a></li>
                           <li><a href="#our_offices">Our Offices</a></li>
                        </>
                    }
                </ul>
            </div>
            {/* <button onClick={() => scroll('right')}>&gt;</button> Right arrow */}
        </div>
    );
};

export default SubHead;
