import React from 'react';
import './manageUser.css';

const ManageUser = () => {
    const userData = [
        { userName: 'john_doe', registrationDate: 'Jan 01, 2023', status: 'Active', action: 'Update', companyName: 'Tech Solutions' },
        { userName: 'jane_smith', registrationDate: 'Feb 15, 2023', status: 'Inactive', action: 'Delete', companyName: 'Innovatech' },
        { userName: 'sam_jones', registrationDate: 'Mar 10, 2023', status: 'Pending', action: 'Activate', companyName: 'Creative Inc.' },
        { userName: 'kate_brown', registrationDate: 'Apr 22, 2023', status: 'Active', action: 'Suspend', companyName: 'Green Energy' },
        { userName: 'tom_clark', registrationDate: 'May 05, 2023', status: 'Suspended', action: 'Reactivate', companyName: 'Urban Developments' },
        { userName: 'lisa_white', registrationDate: 'Jun 18, 2023', status: 'Active', action: 'Deactivate', companyName: 'Future Tech' },
        { userName: 'mike_davis', registrationDate: 'Jul 30, 2023', status: 'Pending', action: 'Approve', companyName: 'Riverdale Corp' },
    ];

    return (
        <div className="admin-panel-manage-user">
            <div className='admin-panel-manage-user-container'>
            <div className="admin-panel-filters">
                <div>
                    <label>User Name</label>
                    <input type="text" placeholder="User Name" />
                </div>
                <div>
                    <label>Business Name</label>
                    <input type="text" placeholder="Business Name" />
                </div>
                <div >
                    <label className="admin-select-option-label">Status...</label>
                    <select>
                        <option value="">Select Option</option>
                        <option value="Open">Open</option>
                        <option value="Accept">Accept</option>
                        <option value="Reject">Reject</option>
                        <option value="STA">STA</option>
                        <option value="Discard">Discard</option>
                    </select>
                </div>
            </div>
            <div className="admin-filter-button-container">
                <button className="admin-panel-reset-btn">Reset</button>
                <button className="admin-panel-search-btn">Search</button>
            </div>
            <h3>User/List</h3>
            <div className='admin-panel-table-container'>
                <table>
                    <thead>
                        <tr>
                            <th>User Name</th>
                            <th>Company Name</th>
                            <th>Registration Date</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userData.map((data) => (
                            <tr key={data.id}>
                                <td>{data.userName}</td>
                                <td>{data.companyName}</td>
                                <td>{data.registrationDate}</td>
                                <td>{data.status}</td>
                                <td>{data.action}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            </div>
        </div>
    );
};

export default ManageUser;
