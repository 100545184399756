import React from "react";
import "./PraticipationTerm.css";
import "../CommonConditions.css";
import NavBackground from "../../../includes/navBackground";
import AdditionalLinks from "../AdditionalLinks/AdditionalLinks";

const ParticipationTerms = () => {
  return (
    <div className="conditions-background">
      <NavBackground />
      <div className="privacy-term-main-container">
        <div className="privacy-content-updated-date">
          Updated on 2nd May 2024
        </div>
        <div className="privacy-content-main-heading">Participation Terms</div>
        <div className="privacy-content-main-section">
        <AdditionalLinks/>
          <div className="privacy-content-main-content-section">
            <p className="privacy-content-text-">
              These Participation Terms (&quot;Terms&quot;) pertain to every
              auction or other marketing or transaction event (each referred to
              as an &quot;Event&quot;) organized by Vihara.ai and/or its
              affiliates (together, &quot;Vihara.ai&quot;) on behalf of a seller
              (each, a &quot;Seller&quot;) of real property or secured note(s)
              (each, a &quot;Property&quot;, collectively,
              &quot;Properties&quot;). In these Terms, any potential buyer
              participating in any Event is termed a &quot;Participant&quot;.
              Additionally, in these Terms, the terms &quot;Auction.com&quot;,
              &quot;Seller&quot;, and &quot;Participant&quot; also encompass the
              respective principals, agents, and affiliates of each. The
              &quot;Winning Buyer&quot; is the Participant who (a) presents the
              highest net bid in an auction Event and is confirmed by Vihara.ai
              via email or telephone as the winning bidder, or (b) submits any
              offer during any Event that is accepted by the Seller. In these
              Terms, each bid or offer submitted in any Event is referred to as
              an &quot;Offer&quot;.
            </p>
            <p>
              Vihara.ai exclusively conducts Events on behalf of the Seller and
              does not act as an agent for any Participant in any capacity. By
              registering for an Event or submitting an Offer on www.vihara.ai
              or any other Vihara.ai website (&quot;Website&quot;), the
              Participant acknowledges reading and accepting these Terms. By
              submitting an Offer on a Property, the Participant is considered
              to have accepted any additional terms and conditions posted on the
              Property&lsquo;s details page on the Website (&quot;Property
              PageVihara.ai exclusively conducts Events on behalf of the Seller
              and does not act as an agent for any Participant in any capacity.
              By registering for an Event or submitting an Offer on
              www.vihara.ai or any other Vihara.ai website
              (&quot;Website&quot;), the Participant acknowledges reading and
              accepting these Terms. By submitting an Offer on a Property, the
              Participant is considered to have accepted any additional terms
              and conditions posted on the Property&lsquo;s details page on the
              Website (&quot;Property Page&quot;) at the time the Offer was
              submitted, and such terms and conditions shall take precedence and
              control over these Terms in case of any conflict.
            </p>

            <p>
              Vihara.ai&apos;s Website Terms of Use and Privacy Statement are
              integrated into these Terms by reference.
            </p>

            <div className="privacy-content-text-heading">
              Account Creation and Registration Process
            </div>
            <p>
              When registering for an Event, Participants might need to submit a
              deposit as a guarantee of adherence to these Terms
              (&quot;Cancellation Fee&quot;). The Cancellation Fee amount and
              acceptable payment methods will be indicated during registration
              or as otherwise mutually agreed upon by the Participant in
              writing. Vihara.ai reserves the right, at its sole discretion or
              at the Seller&lsquo;s request, to adjust, waive, or introduce
              additional participation prerequisites for specific Properties or
              Participants.
            </p>

            <div className="privacy-content-text-heading">Due Diligence</div>

            <p>
              <b>Purchase Documents:</b> Before submitting an Offer,
              Participants must carefully review the purchase and sale
              agreement, any relevant addenda, Seller&apos;s disclosure
              documentation, and all other transactional documents (collectively
              referred to as &quot;Purchase Documents&quot;), accessible on the
              Property Page. All Offers must align with the contents of the
              posted Purchase Documents. Unless explicitly stated otherwise on
              the Property Page, the Purchase Documents are not subject to
              negotiation.
            </p>

            <p>
              <b>Due Diligence:</b> Each Participant bears the responsibility to
              conduct thorough due diligence and investigate all aspects
              pertaining to each Property they are considering purchasing. This
              includes, but is not limited to, legal matters, physical condition
              and features, environmental concerns, economic factors,
              encumbrances, and any other relevant aspects. Participants must
              arrange any inspections in coordination with the Seller. In
              certain situations, the Seller may be unable to facilitate
              physical access for inspections, and Participants must proceed
              with Offers accordingly at their own risk. All Offers should be
              based solely on the Participant&apos;s independent due diligence
              and the information provided in the Purchase Documents.
            </p>
            <p>
              Property information has been compiled from various sources and
              has not been independently verified by Vihara.ai. This information
              is provided on an &quot;as is, as available&quot; basis, with all
              faults and imperfections. Participants may utilize this
              information for internal purposes and in a manner consistent with
              the customary practices of professionals within their licensed or
              regular business activities. Participants are advised to seek
              guidance from licensed real estate brokers, contractors,
              attorneys, financial advisors, tax advisors, or other relevant
              professionals.
            </p>
            <p>
              Participants are accountable for any liability, damages, and/or
              costs arising directly or indirectly from their inspection, visit,
              or investigation of the Property. Participants agree to indemnify,
              defend, and hold harmless the Seller and Vihara.ai from any such
              liability, damages, and/or costs, and to maintain appropriate
              insurance policies covering such liabilities, damages, and/or
              costs.
            </p>

            <div className="privacy-content-text-heading">Buying</div>

            <p>
              <b>Offer Irrevocability:</b> All Offers made during any Event are
              non-retractable, except in cases where an Offer withdrawal option
              is explicitly offered to Participants via the Website or as
              otherwise detailed herein.
            </p>

            <p>
              <b>Execution of Purchase Documents:</b> The final Purchase
              Documents will be electronically sent to the Winning Buyer for
              signature using the email address associated with the Winning
              Buyer&apos;s Vihara.ai account. The Winning Buyer must sign the
              Purchase Documents within one business day after receipt (unless a
              different time frame is specified in writing by Vihara.ai), with
              punctuality being essential. Failure by the Winning Buyer to sign
              the Purchase Documents promptly may result in Vihara.ai or the
              Seller declaring the Winning Buyer in default of these Terms and
              rejecting their Offer.
            </p>

            <p>
              <b>Payment of Earnest Money Deposit:</b> Each Property&apos;s
              Purchase Documents mandate the Winning Buyer to submit a deposit
              to the escrow/closing agent (&quot;Earnest Money Deposit&quot;).
              The exact amount of the Earnest Money Deposit is specified in the
              Purchase Documents or on the Property Page for each Property.
              Unless otherwise stated in the Purchase Documents, the Earnest
              Money Deposit must reach the escrow or closing agent no later than
              two business days after the Winning Buyer receives wire
              instructions, with punctuality being essential. Failure to timely
              submit the Earnest Money Deposit may result in Vihara.ai or the
              Seller declaring the Winning Buyer in default of these Terms and
              rejecting their Offer.
            </p>
            <p>
              <b>Verification of Funds and Additional Information: </b>Vihara.ai
              or the Seller may request Participants to furnish proof of readily
              available funds and/or additional information at any time.
              Participants must respond to such requests within one business
              day, with punctuality being essential. Failure to timely provide
              such proof of funds or additional information may result in
              Vihara.ai or the Seller rejecting the Winning Buyer&apos;s Offer
              and declaring them in default of these Terms. Winning Buyers
              intending to take title in the name of an entity (not as an
              individual) may need to supply entity formation documents and
              resolutions authorizing the purchase within the timeframe
              specified by Vihara.ai (which may be before or after the Event, at
              Vihara.ai’s sole discretion). Additional documentation may be
              required by the Seller and/or the escrow/closing agent prior to
              closing.
            </p>
            <p>
              <b>Buyer&apos;s Fee:</b> If the Property Page specifies the
              application of a buyer&apos;s premium or transaction fee
              (&quot;Buyer&apos;s Fee&quot;), the Winning Buyer will be
              obligated to pay the Buyer&apos;s Fee in addition to the amount of
              their Offer at closing. Unless otherwise specified for a specific
              Property, the Buyer&apos;s Fee will be added to the Winning
              Buyer&apos;s Offer amount to establish the total purchase price
              payable by the Winning Buyer.
            </p>

            <p>
              The Buyer&apos;s Fee, when designated by the Seller as applicable
              to any Property, constitutes an additional charge intended, among
              other things, to cover the Seller&apos;s expenses in the sales
              process, including but not limited to the Auction Fee and
              brokerage fees that may be paid, and/or to compensate for services
              rendered by Vihara.ai to the successful buyer.
            </p>

            <p>
              <b>Short Sale Properties:</b> Some Properties involve a sale in
              which the proceeds may fall short of the balance of debts secured
              by the Property (&quot;Short Sale&quot;). All Offers for Short
              Sale Properties are contingent upon the approval of the relevant
              lien holders (&quot;Short Sale Lender&apos;s Consent&quot;). If
              Short Sale Lender&apos;s Consent is not obtained, the transaction
              will be voided, and the Earnest Money Deposit will be refunded to
              the Participant.
            </p>

            <div className="privacy-content-text-heading">Auction Events</div>
            <p>
              <b>Reserve Price:</b> With the exception of Properties labeled as
              &quot;Absolute Auction&quot; or &quot;Minimum Bid Auction,&quot;
              all Properties featured in auction Events have a minimum selling
              price set by the Seller (&quot;Reserve Price&quot;). The starting
              bid does not equate to the Reserve Price. Unless prohibited by
              law, Vihara.ai may place counter bids on behalf of the Seller up
              to the Reserve Price (such bids do not lead to the sale of the
              Property). The Seller retains the discretion to accept a bid below
              the Reserve Price. Regardless of the Reserve Price, the Seller is
              not obligated to sell a Property until the Purchase Documents are
              signed by the Seller.
            </p>

            <p>
              <b>Proxy Bids:</b> Bidders can place Proxy Bids for specific
              properties online, where available. Vihara.ai&apos;s system will
              automatically bid on behalf of the bidder up to the Proxy Bid
              amount during the auction, responding to bids from other bidders
              and counterbids. If multiple Proxy Bids are identical, the
              first-entered Proxy Bid takes precedence. Bid results are emailed
              to bidders immediately after the auction ends. Proxy Bids remain
              undisclosed to other bidders or the Property seller.
            </p>

            <p>
              <b>Minimum Bid Auction Events:</b> Properties auctioned without a
              Reserve Price but with a minimum bid are listed in a &quot;Minimum
              Bid Auction&quot; and are sold to the highest bidder meeting or
              exceeding the minimum bid amount.
            </p>

            <p>
              <b>Absolute Auction Events:</b> Properties auctioned without a
              Reserve Price or minimum bid are listed in an &quot;Absolute
              Auction&quot; and are sold to the highest bidder.
            </p>
            <p>
              <b> Managed Bid Events:</b> Properties marketed in a Managed Bid
              Event invite Participants to submit a non-binding indicative bid
              (&quot;Indicative Bid&quot;) before the auction date. Participants
              must complete an Indicative Bid Form on the Property Page before
              the auction date. No deposit is required for an Indicative Bid,
              but Participant registration may be necessary. Submission and
              acceptance of an Indicative Bid do not legally bind either
              Participant or Seller. If the Seller proceeds with the Managed Bid
              Event, they review the Indicative Bids to determine which
              Participants may participate, at their discretion.
            </p>

            <p>
              <b>Direct Offer Event:</b> Properties marketed in a Direct Offer
              Event allow Participants to submit Offers, with the possibility to
              provide justification for the Offer amount in some cases. Sellers
              may accept, counter, or decline any Offer received within a
              specified time period. If no decision is made within the allotted
              time, the Offer expires automatically. Sellers may withdraw any
              Offer for any reason and at any time, including before the
              expiration of the Participant&apos;s acceptance period.
            </p>

            <p>
              <b>Extension of Auction Time:</b> Vihara.ai may extend the bidding
              time for any Property at its discretion to allow for additional
              bids.
            </p>

            <p>
              <b>Subject to Confirmation:</b> Sellers may designate certain
              Offers as &quot;Subject To Confirmation,&quot; in which case
              Winning Buyer&apos;s purchase is subject to Seller&apos;s approval
              within the specified time period. Winning Buyer receives a refund
              if the Seller does not approve the purchase.
            </p>

            <div className="privacy-content-text-heading">
              Broker/Agent Participation
            </div>
            <p>
              <b>Broker Participation:</b> We welcome the participation of real
              estate brokers and agents. Some Properties may offer a cooperating
              broker commission or referral fee. Please refer to the relevant
              Property Page for details on broker/agent participation and any
              associated commissions or fees. No commission or fee will be paid
              to any broker or agent representing the Participant unless
              explicitly stated in writing for the specific Property.
            </p>
            <p>
              For certain Events, a registered broker/agent may submit Offers on
              behalf of a pre-identified buyer to Vihara.ai. By submitting an
              Offer on behalf of a buyer, the broker/agent (i) confirms having
              obtained all necessary buyer authorizations and complied with all
              legal requirements for Offer submission, (ii) acknowledges that
              the Offer submitted binds both the broker/agent and the identified
              buyer to the same extent as if the buyer had submitted the Offer
              directly, and (iii) agrees to indemnify and hold Vihara.ai
              harmless from any breach of these representations or warranties.
            </p>

            <p>
              Participant agrees to indemnify and hold Vihara.ai harmless from
              any claims brought against Vihara.ai by any broker/agent
              representing the Participant.
            </p>

            <div className="privacy-content-text-heading">
              General Information
            </div>

            <p>
              <b>General:</b> Seller and Auction.com retain the authority to
              refuse, restrict, or impose conditions on the submission of Offers
              and/or access to the Website or any Event at any given time and
              for any reason (including, but not limited to, Participant&apos;s
              financial status, credit history, transaction record, and
              Cancellation Fee status). For instance, access to our site is
              typically restricted from locations outside the United States,
              Canada, Guam, Puerto Rico, and the U.S. Virgin Islands.
            </p>

            <p>
              Vihara.ai reserves the right to postpone or cancel an Event, alter
              the sequence of Properties, amend these Terms by providing notice
              on the Property Page, remove any Property from the Event, or
              reject any or all Offers.
            </p>

            <p>
              Press releases and other public communications concerning the
              Event and/or any Property offered or sold during an Event may be
              issued by Vihara.ai, Sellers, and/or their representatives.
            </p>

            <p>
              These Terms and the execution of each Event are governed by
              Florida law, except in the case of auction Events involving real
              property in California, where California law applies.
            </p>

            <p>
              Any information on websites, brochures, emails, postcards, or
              otherwise regarding the Properties does not constitute an offer to
              sell or a solicitation to buy any Property. Offers made or
              solicitations conducted during an Event are invalid where
              prohibited by law. If a sale would require registration or
              qualification under state securities laws, it cannot be finalized
              until all necessary requirements are met.
            </p>

            <p>
              Seller is not bound to sell unless and until the Purchase
              Documents are signed and delivered by Seller and the Earnest Money
              Deposit is received as stipulated herein. Once the Purchase
              Documents are fully executed, they govern the relationship between
              Winning Buyer and Seller, releasing Vihara.ai from any liability
              related thereto.
            </p>

            <div className="privacy-content-text-heading">
              Disclaimers; Participant Representations; Cancellation; Liquidated
              Damages
            </div>

            <p>
              Vihara.ai does not provide any express or implied representations
              or warranties regarding any Property or the legal sufficiency or
              tax implications of any document provided by Vihara.ai. Each
              Participant releases Vihara.ai and its employees, officers,
              directors, representatives, attorneys, brokers, and agents
              (&quot;Representatives&quot;) from any and all claims arising from
              or related to any errors, omissions, or other conditions affecting
              any Property, the documents used in evaluating or acquiring any
              Property, or the conducting of any Event. This release encompasses
              claims of which Participant may presently be unaware or does not
              presently suspect to exist, and Participant specifically waives
              the provisions of California Civil Code Section 1542 (and similar
              applicable state statutes):
            </p>

            <p className="terms-capital-text">
              A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS THAT THE CREDITOR OR
              RELEASING PARTY DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER
              FAVOR AT THE TIME OF EXECUTING THE RELEASE AND THAT, IF KNOWN BY
              HIM OR HER, WOULD HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT
              WITH THE DEBTOR OR RELEASED PARTY.
            </p>

            <p>
              Participant agrees to indemnify and hold Vihara.ai harmless from
              any liability, damages, and/or costs (including reasonable
              attorneys&apos; fees) arising from Participant&apos;s actions or
              inactions, any information or communications provided by
              Vihara.ai, or the condition or occupancy status of any Property.
            </p>

            <p className="term-red-text">
              <b>Cancellation; Liquidated Damages:</b> In the event of
              Participant&apos;s breach of these Terms, Vihara.ai and/or the
              Seller may reject Participant&apos;s Offer, sell the Property to
              another Participant, or place it in another Event. Participants
              will be responsible for paying Vihara.ai liquidated damages, which
              will be the greater of the cancellation fee shown at the time of
              registration or the cancellation fee agreed upon in writing by the
              Participant.
            </p>

            <p className="term-red-text">
              Vihara.ai is authorized to charge the credit card provided by
              Participant at registration or retain the amount deposited with
              Vihara.ai pursuant to a separate written agreement to satisfy all
              or part of the liquidated damages owed by Participant.
              Participants acknowledge that the actual damages resulting from
              their breach of these Terms would be difficult to calculate, and
              that the amount equal to the cancellation fee is a reasonable
              estimate of the resulting damages to Vihara.ai and is not a
              penalty or forfeiture.
            </p>

            <p>These Participation Terms were last updated May 2, 2024.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParticipationTerms;
