// import React from 'react'
import React, { useState } from 'react';


import '../CommonConditions.css'
import './PrivacyChoice.css'
import NavBackground from '../../../includes/navBackground.jsx'

const PrivacyChoice = () => {

    const [formData, setFormData] = useState({
        requestType: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        relationship: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form data submitted:', formData);
        alert('Form submitted. Check console for data.');
    };


    return (
        <div className="conditions-background">
            <NavBackground />
            <div className="privacy-term-main-container">
                <div className="privacy-content-updated-date">
                    Updated on 2nd May 2024
                </div>
                <div className="privacy-content-main-heading">Privacy Choices</div>
                <div className="privacy-content-main-section">
                    <div className="privacy-content-additional-section">
                        <h2>Additional Links</h2>

                        <h4>
                            <a href="/privacy-statement">Privacy Statement</a>
                        </h4>

                        <h4>
                            <a href="/website-terms-of-use">Website Terms of Use</a>
                        </h4>
                        <h4>
                            <a href="#">Cookie Preferences</a>{" "}
                        </h4>
                        <h4>
                            <a href="/privacy-choice">Privacy Choices</a>{" "}
                        </h4>
                        <h4>
                            <a href="/participation-terms">Participation Terms </a>
                        </h4>
                        <h4>
                            <a href="/accessibility">Accessibility</a>
                        </h4>
                    </div>
                    <div className="privacy-content-main-content-section">
                        <p className="privacy-centered-text">
                            Welcome to Vihara.ai’s Privacy Choices Form
                        </p>
                        <p>
                            Laws across the nation, including in California, are evolving to
                            offer consumers more options regarding their personal information.
                        </p>
                        <p>
                            For instance, eligible Californians have the right to opt out of
                            what the California Consumer Privacy Act (CCPA) defines as a
                            &quot;sale&quot; or &quot;sharing&quot; of personal information. These terms have
                            specific legal meanings, and the opt-out rights have exceptions,
                            so utilizing those rights will not limit all uses and disclosures
                            of your personal information.
                        </p>
                        <p>
                            These laws also grant eligible consumers the right to restrict the
                            use and disclosure of certain categories of data categorized as
                            &quot;sensitive personal information.&quot; This includes data such as
                            specific types of government-issued identification numbers or
                            precise geolocation data. Under this right, eligible individuals
                            can require a business to refrain from certain uses and
                            disclosures of &quot;sensitive personal information,&quot; but other
                            activities, such as using the information to facilitate requested
                            transactions or to comply with the law, remain permissible.
                        </p>
                        <p>
                            For further details and information on how we utilize and
                            safeguard your personal information, please refer to our Privacy
                            Statement.
                        </p>
                        <p>
                            Please ensure to follow the instructions and provide the requested
                            information for each option. If you already have a Vihara.ai
                            account, kindly ensure that the contact information provided above
                            matches the details listed in your Vihara.ai dashboard. If you
                            have utilized multiple accounts, email addresses, or phone numbers
                            with us, kindly submit separate requests for each, as your records
                            may not be linked in our databases.
                        </p>
                        <p>
                            To request to exercise one of these rights on behalf of another
                            individual, please click here. To request to exercise one of these
                            rights for yourself, please select it here:
                        </p>

                        <form onSubmit={handleSubmit} className="policy-choice-request-form">
                            <div className='privacy-choice-input-row'>
                                <div className='privacy-choice-input-col'>
                                    <label>
                                        First Name:
                                        <span>*</span>
                                    </label>
                                    <input type="text" name="lastName" value={formData.firstName} onChange={handleChange} required />
                                </div>
                                <div className='privacy-choice-input-col'>
                                    <label>
                                        Last Name:
                                        <span>*</span>
                                    </label>
                                    <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} required />
                                </div>
                            </div>
                            <div className='privacy-choice-input-row'>
                                <div className='privacy-choice-input-col'>
                                    <label>
                                        Email:
                                        <span>*</span>
                                    </label>
                                    <input type="email" name="email" value={formData.email} onChange={handleChange} required />

                                </div>
                                <div className='privacy-choice-input-col'>
                                    <label>
                                        Primary Phone Number:
                                        <span>*</span>
                                    </label>
                                    <input type="tel" name="phone" value={formData.phone} onChange={handleChange} required />
                                </div>
                            </div>
                            <div className='privacy-choice-input-row'>
                                <div className='privacy-choice-input-col'>
                                    <label>
                                        Address:
                                        <span>*</span>
                                    </label>
                                    <input type="text" name="address" value={formData.address} onChange={handleChange} />

                                </div>
                                <div className='privacy-choice-input-col'>
                                    <label>
                                        City:
                                        <span>*</span>
                                    </label>
                                    <input type="text" name="city" value={formData.city} onChange={handleChange} />

                                </div>
                            </div>
                            <div className='privacy-choice-input-row'>
                                <div className='privacy-choice-input-col'>
                                    <label>
                                        State:
                                        <span>*</span>
                                    </label>
                                    <input type="text" name="state" value={formData.state} onChange={handleChange} />

                                </div>
                                <div className='privacy-choice-input-col'>
                                    <label>
                                        Zip:
                                        <span>*</span>
                                    </label>
                                    <input type="text" name="zip" value={formData.zip} onChange={handleChange} />
                                </div>
                            </div>
                            <div className='privacy-choice-input-row'>
                                <div className='privacy-choice-input-col'>
                                    <label>
                                        Select the Request You Want to Submit:
                                        <span>*</span>
                                    </label>
                                    <select style={{ width: "100%" }} name="requestType" value={formData.requestType} onChange={handleChange} required>
                                        <option value="">--Choose an Option--</option>
                                        <option value="sell">Sell</option>
                                        <option value="share">Share</option>
                                    </select>
                                </div>
                                <div className='privacy-choice-input-col'>
                                    <label>
                                        Nature of Relationship to Vihara.ai:
                                        <span>*</span>
                                    </label>
                                    <input type="text" name="relationship" value={formData.relationship} onChange={handleChange} />

                                </div>
                            </div>
                                    <p className="privacy-choice-disclaimer">
                                        By submitting this form you are agreeing to Vihara&apos;s{" "}
                                        <a href="/website-terms-of-use">terms of service</a> and{" "}
                                        <a href="/privacy-statement">privacy policy</a>. Contact{" "}
                                        <a href="mailto:trisha@vihara.ai" className="trisha-email-link">trisha@vihara.ai</a> with
                                        any questions.
                                    </p>
                                    <p className="privacy-choice-disclaimer">
                                        I agree that Vihara may contact me about their products and
                                        services.
                                    </p>
                              
                            <button type="submit">Submit</button>
                            <p className="privacy-choice-get-support">
                                Need support?{" "}
                                <a href="mailto:trisha@vihara.ai">Go here instead.</a>
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PrivacyChoice