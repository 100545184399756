import React from "react";
import "./Privacy.css";
import "../CommonConditions.css";
import NavBackground from "../../../includes/navBackground";

const PrivacyStatments = () => {
  return (
    <div className="conditions-background">
      <NavBackground />
      <div className="privacy-term-main-container">
        <div className="privacy-content-updated-date">
          Updated on 2nd May 2024
        </div>
        <div className="privacy-content-main-heading">Privacy Statement</div>
        <div className="privacy-content-main-section">
          <div className="privacy-content-additional-section">
            <h2>Additional Links</h2>
            <h4>
              <a href="/privacy-statement">Privacy Statement</a>
            </h4>

            <h4>
              <a href="/website-terms-of-use">Website Terms of Use</a>
            </h4>
            <h4>
              <a href="#">Cookie Preferences</a>{" "}
            </h4>
            <h4>
              <a href="/privacy-choice">Privacy Choices</a>{" "}
            </h4>
            <h4>
              <a href="/participation-terms">Participation Terms </a>
            </h4>
            <h4>
              <a href="/accessibility">Accessibility</a>
            </h4>
          </div>
          <div className="privacy-content-main-content-section">
            <div className="privacy-content-text-heading">
              Scope of Privacy Statement
            </div>
            <p className="privacy-content-text-">
              This Privacy Statement (“Privacy Statement”) describes how RL Auction Inc. and its affiliates and subsidiaries (collectively, “Vihara,” “Vihara.ai,”  “we,” “us,” “our,” and/or the “Company”) handle your (“Your” or “You”) personal information in connection with the following (collectively, the “Services”):
            </p>
            <ul>
              <li>The Company’s websites (including www.vihara.ai and any other website where this Privacy Statement is posted);
              </li>
              <li>The Company’s live auction events; </li>
              <li>Vihara.ai tools, documents, resources, emails, and applications (including mobile applications), and other services.
              </li>
            </ul>
            <p>
              By using our Services, you expressly consent to the collection, storage, use and disclosure of your information as described in this Privacy Statement.

            </p>
            <p>
              To review our California Notice at Collection, please click here.
            </p>
            <p>To review our California Personnel Privacy Statement and Notice at Collection, please click here.</p>

            <div className="privacy-content-text-heading">
              Commitment to Privacy
            </div>
            <p>
              The Company has a strong commitment to gathering and providing information tailored to your needs while providing excellent customer service to all of our customers, including respecting concerns about privacy. We understand that you may have questions about whether we collect information, and how we use the information that is collected. This Privacy Statement underlines the steps we take to respect all of your privacy concerns. If you have any comments, questions or any privacy concerns after reviewing this Privacy Statement, please contact us as written in the “Contact Us” section.


            </p>

            <div className="privacy-content-text-heading">
              Collection of your Information
            </div>

            <p>
              Information You Provide. You are allowed to provide us with information in a variety of situations. For instance, you may provide information by creating an account with Vihara through the services (“www.vihara.ai Account”), updating your www.vihara.ai Account profile, registering for a marketing or transaction event, participating in a marketing or transaction event, filling online forms, or transacting with us. This information is not limited to, and may include identifiable information such as your first name, last name, email address, telephone number, mailing address, username, password, financial information, billing information, and other information exchanged in connection with any real estate transactions and related transactions (“Personal Information”).

            </p>
            <p>
              In certain cases, identifiable information may include government-issued identification documents such as a driver license number, financial account numbers, demographic information such as sex, geolocation data, or any other information that may be defined at Sensitive Personal Information.

            </p>
            <p>
              Information from Other Sources. We may pull publicly available information from other sources such as property records, or from third parties such as financial institutions who provide customer personal information in regards to providing services and ad tech companies or data brokers that provide us with information such as contact information, demographic information, geolocation data, or device identifiers.

            </p>
            <p>
              Automatically Collected Information. We may automatically collect information about the mobile devices, computers and/or similar devices that you use to access the Services provided, or that you use to interact with ads, emails, messages, or other electronic content that we provide you. For instance, we may collect and/or store information such as your screens size, IP address, operating system, browser type, the location of your wireless device, the state and/or country from which you accessed the Services, language,  unique device identifiers such as but not limited to mobile advertising identifiers (UDID or an IDFA on Apple devices like the iPad, iPhone or the Android Ad ID), the links you click through, the Services you use and the equipment you use and how you interact with them, the pages you view, the websites you visit immediately before and/or after visiting our website, any error logs, the date and time of your visit to certain pages and the amount of time you stay on them, and/or other software and hardware information. We do not record any keystrokes or in-progress typing for marketing purposes. However, we do reserve the right to collect information for anti-fraud or other cybersecurity purposes, and may provide autocomplete or autosave functionalities for your ease. We and our vendors may use “cookies” or similar online tools like web beacons and web pixels to collect this information. Cookies are defined as small files that a website holds on a user’s device. The Services use these cookies for purposes such as recognizing the device, storing user preferences, and keeping the information you enter on pages together, and as listed in our Cookie Preferences tool.
            </p>

            <p>
              For instance, when you use the Services, we or our third-party vendors that operate our analytics or ad technologies may use the information that is collected through cookies or similar technologies to perform analytics regarding your use of the Services (and other apps, services, and websites tracked by these third parties) and/or to ensure that ads that are appropriate are presented to you through the Services and on third-party websites, services, and apps. The ad technologies also help control how many times you see a presented ad, deliver ads that are related to your interests, and assess the effectiveness of the ad campaigns.
            </p>

            <p>
              Some of the technology that is written about above is used by us or our partners to aggregate information collected about you over time, across two or more online services or websites. Additionally, we may also aggregate the information collected about you with information from third parties that may be identifiable to you. We may use all this information to enhance the Services, market the Services, or for other purposes listed in this Statement.
            </p>
            <p>
              Most website browsers automatically accept cookies, but you can set your browser to block cookies by reading the instructions for your particular browser on how to block them. Please note that if you decide to block all the cookies in your browser, this may interfere with your ability to perform some transactions, use certain functionalities, and access content on the Services. Please review Section 6 of this Privacy Statement titles “Your Data Privacy Choices” for additional information on how you can manage cookie-related collection of information.
            </p>

            <p>
              Vihara.ai Account. Information that is specified in the preceding paragraph, such as what content you view, access, click on or search for on the Services, the devices from which you access the content and the amount of time for which you view the content, may be associated with your Vihara.ai account. Vihara.ai may use your account details across all the Services that
              Vihara.ai offers.

            </p>

            <p>
              User-Initiated Communication. On some occasions, certain portions of the Services may allow you to send emails or other types of messages to us, or third parties, and to participate in discussion groups or boards. We are allowed to, and have the right to adapt, publish, reproduce, modify, create works from copy, distribute, and display such emails, messages or other forms of communications to these discussion groups through the world in any form that is pursuant and aligned to the Website Terms of Use.
            </p>

            <p>
              Amongst other things, this right allows us to read your messages with other users to enforce our Terms of Use. You may be contacted whenever you choose to initiate these kinds of communication with us, or anyone else. Communication as such that is sent may be identified by your actual name and/or username, when viewed by others. Our discussion groups or bulletin boards are open to the public eye, which means that your postings may be viewed by anyone, and may be indexable by search engines, and are not protected by us or any other entity. Please be mindful and use your discretion when deciding to post and whom to contact and message. We are not held liable for third party content, including but not limited to communications that are made by you on these public forums, nor should such content be considered to be made on behalf of the Company, unless noted by us. We reserve the right in our sole discretion to edit, monitor, or delete any postings from our discussion groups and bulletin boards. These rights do not in any circumstances obligate us to conduct such deletions or edits, and it does not cause us to be liable for any such deletions or edits.
            </p>

            <p>
              The Services may also have plugins for other third-party websites, including but not limited to Facebook, Youtube, Twitter, LinkedIn, and Instagram. We recommend that you review the privacy policies associated with these websites prior to clicking on these plugins.
            </p>
            <p>
              Telephone, Video Conferencing, and Chat Conversations. We have the right to record any conversations that our employees have with you, including but not limited to video, chat, or telephone conversations. By engaging in any of these conversations with our agents or employees, you consent to our act of recording and/or storing such conversations. We do this regularly to monitor the quality of service and accuracy of the information our employees and agents provide you, to ensure that there is compliance with the Terms of Use and applicable law, and to ensure that your instructions are heard. Agents and employees of the Company are not authorized to waive this or any provisions of our policies and procedures.
            </p>

            <div className="privacy-content-text-heading">
              Use of Your Information

            </div>

            <p>
              We may use your information for the purpose for which we collected it or for which you provided it, and for other uses that we deem appropriate. For example, we may use your information for, but not limited to:
            </p>

            <ul>
              <li>Create and maintain your Vihara.ai account, your account profile, communication preferences, and registration information;
              </li>
              <li>
                Enhance the user experience;
              </li>
              <li>
                Perform any analytics and research;
              </li>
              <li>
                Determine your eligibility for any marketing events, gifts, prizes, services, and features of the Services;
              </li>
              <li>
                To customize and deliver content and advertising services that you see on the Services or elsewhere;
              </li>
              <li>Send you any gifts or prizes that you may have earned;
              </li>
              <li>
                Enforce the Service’s Terms of Use or other contractual rights;

              </li>
              <li>
                Protect our property and rights and the property and rights of others;

              </li>
              <li>
                Comply with the law;
              </li>
              <li>
                Make any deductions about connections among different internet-connected devices used by your of family members;

              </li>
              <li>
                To contact you via email or phone call (subject to legally required consent):
                <ul>
                  <li>To facilitate marketing events for which you are registered; </li>
                  <li>To communicate with you in relation to you vihara.ai account;</li>
                  <li>To send you any information that may be of interest to you;</li>
                  <li>Regarding any new opportunities that are related to marketing or transaction events or our other Services; </li>
                  <li>To notify you with the latest developments and features of the Services; and </li>
                  <li>For other transactional, marketing, or research/informational purposes.</li>
                </ul>
              </li>
              <li>Administer, enroll you in, or facilitate participation in educational or entertainment activities; surveys and questionnaires; promotions, or any other services, events or activities sponsored by third parties or us, or offered with our Services;</li>
              <li>Respond to and fulfill requests for Services, including the qualification to make bids, or other inquiries that are similar.</li>
            </ul>

            <div className="privacy-content-text-heading">
              Disclosure of Your Information

            </div>
            <p>We are allowed to disclose your information in the following manners:</p>
            <p>Information that is collected via the Services can be made available to subsidiaries and companies that are affiliated with the Services that are under common ownership or control within the Company family. </p>
            <p>If a bid or offer is made on a particular asset on our Services, the seller and its representatives and affiliates, and any other parties related to the transaction, can be given access to parts of your personal information. For instance, we may share some auction-related records and history with a foreclosing lender and their services providers like law firms and trustees.</p>
            <p>We are allowed to exchange personal information with the following:
            </p>
            <ul>
              <li>Joint marketing partners; </li>
              <li>Data processors, technical service providers, processors, customer service, support services, and other service provider businesses; </li>
              <li>Financial servicing institutions like companies engages in mortgage lending, banking, consumerfinance, insurance, and securities, and </li>
              <li>Other participants in auctions or related transactions.
              </li>
            </ul>
            <p>Some of our service providers and their partners like providers of analytics and advertising services mentioned in the “Collection of Your Information” section above in the Privacy Statement, collect information from your device(s).
            </p>
            <p>We may provide a copy of information about you to other companies who we believe may have products or services that may be of interest to you so that they can market to you. Typically, information sharing in not a practice as of the effective date of this Privacy Statement, but we do reserve the right to do this in the future, and we will comply with any applicable legal requirement to (i) provide you with an opportunity to opt out on the “Preference Center” page that is in your www.vihara.ai Account profile, or by contacting us as written in the “Contacting Us” section. We may also (ii) take any additional steps to provide you an opportunity to control whether or not your data is involved.</p>
            <p>We may share your information with any person or company where we believe that such disclosure is essential or appropriate to (a) comply with the law or in response to a court order, government request, subpoena, or any other legal process; (b) product documents in regard to litigation, mediation, government investigations, internal investigations, arbitration, adjudication, or other legal or administrative process; (c) protect the rights, interests, or property of the Company or others; (d) enforce the Terms of Use on the Services, (e) provide users of the Services with the services requested; (f) provide you with special promotions or offers; (g) allow other companies that acquire the Company or some of its assets to continue to serve you; or (h) operate the Company’s systems. </p>
            <p>We may disclose the information you provide with any person or entity after we receive your consent. </p>
            <p>We may use and disclose information that is non-personal about you or use of the Services, including but not limited to any de-identified or aggregated data, or statistical data without limitation. </p>
            <div className="privacy-content-text-heading">
              Your Data Privacy Choices

            </div>
            <p>Controls Related to Cookies and Other Automated Data Collection. You can manage our use of cookies for any browser on www.vihara.ai and our other participating websites by using the browser on that device to click this link. This will launch a consent tool where you can make adjustments to your preferences about how cookies and similar technologies are used on that site. The choice you make will only apply to that browser on that device. It is recommended that you repeat the process with each browser and device you use to access our participating websites. Because we may sometimes store preferences in a cookie, you should also repeat this process if you (i) clear cookies or (ii) use a browser that automatically clears cookies after a set time. This is the best method to control cookies on sites that offer this option.</p>
            <p>Additionally, you can review and use the privacy options from the following vendors: </p>
            <ul>
              <li>You can visit Adobe’s website to learn more about how Adobe handles information that is collected through use of its services.</li>
              <li>Google lets you install a Google Analytics Opt-out browser Add-on for each web browser so you can use theri browser-based activities.
              </li>
              <li>Mobile device users can use the AppsFlyer opt-out to control data collection. </li>
              <li>Your browser may also offer a Global Privacy Control signal (GPC). Vihara.ai typically treats a GPC-enabled browser as having opted out of what the California Consumer Privacy Act calls a “sale” of any California personal information collected on that website using cookies. You may override that treatment for a GPC-enabled browser by using the cookie controls that are in our website footer.</li>
            </ul>
            <p>It is recommended that you visit the following third party websites if you’re interested in learning more about tailored ads and choices to prevent third parties from delivering these ads: the <a href="">Network Advertising Initiative Consumer Opt-Out Page</a> or the <a href="">Digital Advertising Alliance’s Consumer Opt-Out Page.</a> </p>

            <p>Controls Related to Communication from Us. When you create a Vihara.ai account or register for any marketing or transaction event on one of our Company websites, you agree to receive mail, emails or other promotional or marketing material, email, phone, and/or text communications. For instance, in regular intervals we may send you emails or news updates notifying you about new features, promotions, products, or services related to offerings from us, surveys, opportunities, or other Services we provide. In addition, if you created a Vihara.ai account and registered for marketing or a transaction on one of our Company websites, we may directly send mail or call you for such matters.
            </p>
            <p>Text Message Sign-Ups. If you provide your mobile number or text JOIN to a number that we provide you, you are choosing to subscribe to receive Vihara.ai Update Alerts and Vihara.ai Event Notifications via text message from us to the phone number provided by you, and you are authorizing our use of your phone number to send you text messages/SMS with marketing messages or updates about properties you may be interested in. You may incur fees from these messages depending on your data plan. If you have opted-in to receive text messages or telephone call marketing, you also consent to our use of an automatic telephone dialing system for calls and texts. </p>
            <p>Vihara.ai Communications. You may alter preferences for promotional communications at any time you choose on the “Preference Center” page that is located in your Vihara.ai Account profile on www.vihara.ai, or by contacting us at <a href="">trisha@vihara.ai</a>.
            </p>
            <ul>
              <li>If you wish to opt-out of direct mail or email Vihara.ai Communications, you may opt out by contacting us as written in the “Contacting Us” section.
              </li>
              <li>If you wish to opt out of promotional calls, you may opt out of receiving these communications by telling the representative who calls you with such Vihara.ai Communications that you don’t wish to receive these calls, or by contacting us as written in the “Contacting Us” section.</li>
              <li>If you wish to opt-out of receiving text messages, you may opt out by replying with the word “STOP” to a text message you receive from us or by calling us at 916-813-4649. You may request for help by replying with the word “HELP” to a text message from us by calling 916-813-4649.</li>
            </ul>

            <p>We are more than happy to help and comply with such requests to opt out of or unsubscribe from promotional communications, but we reserve the maximum amount of time allowed by law to process these kinds of requests. You cannot opt out of certain transactional phone calls or texts.</p>
            <p>Data Privacy Requests. If you no longer wish to use the Services, you may contact us as written in the “Contacting Us” section.</p>
            <p>If information that you provided is inaccurate or has changed, you agree to update your information by updating the account information located in your Vihara.ai Account profile.</p>
            <p>Individuals that live in countries outside the United States have certain legal rights to access information we hold about them, and to obtain its correction, deletion, or amendment in addition to the self-service option written in the previous sentence, and to object to the processing of personal information or withdraw previously provided consent to such processing. Please note that withdrawal of consent does not affect the lawfulness of data processing based on consent prior to the withdrawal, and in certain circumstances, we are allowed to continue to process personal data on legal grounds other than consent, such as being compliant with the law. You may contact us as written below to exercise these rights.
            </p>
            <p>State-Based Privacy Rights. Vihara.ai is subject to various California privacy-related statutes that are described below. California residents can refer to the “Notice for California Residents” section written below for a detailed description of their rights under the lows and how they may exercise the rights. Other states like Virginia, Connecticut, Colorado, and Utah have passed similar privacy statutes that take effect in 2023, but Vihara.ai does not meet the conditions that may trigger the applicability when they take effect. </p>
            <div className="privacy-content-text-heading">
              Notice for California Residents

            </div>
            <p>This section outlines details concerning the California Consumer Privacy Act of 2018 (“CCPA”), as modified by the California Privacy Rights Act of 2020 (“CPRA”; referred to hereafter as CCPA modified by CPRA). It pertains specifically to personal information regarding California residents governed by CCPA. Information regarding individuals outside California is not addressed in this section. Additionally, it excludes personal information handled solely in our role as a “service provider” to other businesses, directing inquiries about such data to those businesses directly.
            </p>
            <p>The CCPA includes exemptions for information regulated by other laws, like the Graham-Leach-Bliley Act (“GLBA”). Consequently, non-public personal information obtained from financial institutions during service provision may not fall under CCPA or this section of the Privacy Policy.</p>
            <p>In this segment, the terms “personal information” and “sensitive personal information” adhere to CCPA definitions. A “Consumer” refers to a Californian connected to personal or sensitive personal information.</p>
            <p>Collection and Disclosure of Personal Information and Sensitive Personal Information. Over the past 12 months leading up to this Privacy Statement&apos;s effective date, we collected various types of personal and sensitive personal information, as detailed in the preceding &quot;Collection of Your Information&quot; section. The following table summarizes the categories collected, parties with whom this information was shared, and our retention policies:
            </p>

            <table className="privacy-content-table">
              <thead>
                <tr>
                  <th>Categories of Personal Information or Sensitive Information that is Collected</th>
                  <th>Categories of Third Parties that Information was Disclosed to in the Last Twelve Months</th>
                  <th>Examples of How Long we Intend to Store the Information for about Californians
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><b>Identifiers </b>such as first name, last name, phone number, email address, home address, office address</td>
                  <td>
                    Affiliate and subsidiary entities, sellers receiving bids or offers, as well as other involved parties in facilitated auctions and transactions; financial service providers; marketing and advertising services; transactional support providers; technical service providers; and governmental entities.

                  </td>
                  <td> We retain names and email addresses collected during account creation for the duration we deem the account active, typically lasting a minimum of seven years.</td>
                </tr>
                <tr>
                  <td>
                    Vihara.ai account login and password

                  </td>
                  <td>Technical service providers; affiliates; subsidiaries. </td>
                  <td>
                    Vihara.ai account login and password is retained up until the account is considered.

                  </td>
                </tr>
                <tr>
                  <td><b>Demographic information,</b> such as date of birth or marital status that are reflected in government identification documents
                  </td>
                  <td>Affiliate and subsidiary entities, sellers receiving bids or offers, as well as other involved parties in facilitated auctions and transactions; financial service providers; marketing and advertising services; transactional support providers; technical service providers; and governmental entities.</td>
                  <td>Demographic information for bidders on completed property records is retained with our legal and contractual requirements for sales. This information is typically retained for at least seven years or perpetually. For instance, notes and mortgages are retained for seven years after termination, and deeds are retained perpetually.</td>
                </tr>
                <tr>
                  <td><b>Driver’s license or other identification cards,</b> which includes demographic data such as gender, date of birth, or other similar information reflected on state identifications</td>
                  <td>Affiliate and subsidiary entities, sellers receiving bids or offers, as well as other involved parties in facilitated auctions and transactions; financial service providers; marketing and advertising services; transactional support providers; technical service providers; and governmental entities.</td>
                  <td>Vihara.ai employs a service provider for the bidder verification process, which requires bidders to upload such documentation to verify identity and authenticity. This information is typically stored by the service provider on our behalf, compliant with our data retention practices. </td>
                </tr>
                <tr>
                  <td><b>Commercial information,</b> like records of transactions made with Vihara.ai</td>
                  <td>Affiliate and subsidiary entities, sellers receiving bids or offers, as well as other involved parties in facilitated auctions and transactions; financial service providers; marketing and advertising services; transactional support providers; technical service providers; and governmental entities.</td>
                  <td>Business-related data, encompassing transaction logs and property documentation, is preserved in compliance with our contractual and legal commitments. Typically, this data is upheld for a minimum duration of seven years, though the retention period may vary based on the specific nature of the data. For instance, transaction notes and mortgage records are typically kept for seven years after termination, whereas property deeds are retained indefinitely.</td>
                </tr>
                <tr>
                  <td><b>Financial information</b>, like loan numbers or bank accounts</td>
                  <td>Affiliate and subsidiary entities, sellers receiving bids or offers, as well as other involved parties in facilitated auctions and transactions; financial service providers; transactional support providers; technical service providers; and governmental entities</td>
                  <td>Financial details of consumers during the bidding process and following successful property acquisitions are typically stored for at least seven years.</td>
                </tr>
                <tr>
                  <td><b>Audio or visual information,</b> such as from customer service call records or conference conversations </td>
                  <td>Affiliated companies and subsidiaries, technical service providers, as well as participants in auctions and other transactions we facilitate.</td>
                  <td>Customer service phone call recordings kept for training and verification are typically stored for three years before automatic deletion.</td>
                </tr>
                <tr>
                  <td><b>Professional or employment related information</b>, such as an employer or other company affiliations</td>
                  <td><b>Affiliate and subsidiary entities, sellers receiving bids or offers, as well as other involved parties in facilitated auctions</b> and transactions; financial service providers; marketing and advertising services; transactional support providers; technical service providers; and governmental entities.</td>
                  <td>Professional or employment related information is typically retained for at least three to seven years. Retainment depends on the type of information. </td>
                </tr>
                <tr>
                  <td><b>General geolocation information</b>, such as the location (ex. Latitude and longitude) of a wireless device</td>
                  <td>Affiliate and subsidiary entities; marketing and advertising services; transactional support providers; and technical service providers</td>
                  <td>This information is typically retained for at least seven years. </td>
                </tr>
                <tr>
                  <td><b>Precise geolocation information,</b> such as the precise location (ex. Latitude and longitude) of a wireless device</td>
                  <td>Affiliate and subsidiary entities; marketing and advertising services; transactional support providers; and technical service providers</td>
                  <td>This information is typically retained for at least seven years. </td>
                </tr>
                <tr>
                  <td><b>Internet or other network or device activity</b>, such as browsing history</td>
                  <td>Affiliate and subsidiary entities, sellers receiving bids or offers, as well as other involved parties in facilitated auctions and transactions; financial service providers; marketing and advertising services; transactional support providers; technical service providers; and governmental entities.</td>
                  <td>This information is typically retained for at least seven years.</td>
                </tr>
                <tr>
                  <td><b>Other information consumers provide us</b>, such as survey information</td>
                  <td>Affiliate and subsidiary entities; marketing and advertising services; transactional support providers; and technical service providers.</td>
                  <td>This information is typically retained for at least seven years. </td>
                </tr>
                <tr>
                  <td><b>Inferences</b> drawn from the data above</td>
                  <td>Affiliate and subsidiary entities; marketing and advertising services; transactional support providers; and technical service providers.</td>
                  <td>This information is generated by the Vihara.ai team, and is retained for the same time as the underlying record. </td>
                </tr>


              </tbody>
            </table>

            <p>Retention and Deletion. We retain and delete acquired personal information according to our business practices, contractual obligations with client financial institutions, and relevant laws, often for up to seven years. </p>
            <p>In the twelve months preceding the effective date of this Privacy Statement, we engaged in the “selling” and “sharing” of what the CCPA defines as “identifiers” (such as IP addresses) and “internet or other electronic network activity information” (like data regarding an individual’s online browsing activities) to third-party collaborators, including marketing and analytics providers. This activity persists today.</p>
            <p>To the best of our knowledge, we do not “sell” the personal information of individuals under the age of 16.</p>
            <p>The Right to Opt Out of “Sales” and “Sharing.”Californians possess the right to instruct us not to “sell” or “share” specific personal information, as outlined in the CPRA. You can exercise this right by following the instructions on our “Your Privacy Choices” page. Alternatively, you may contact us at (916) 813-4649 to complete the opt-out process, providing your email address, state, and optionally, your name, telephone number, and address. Vihara.ai also respects the Global Privacy Control opt-out signal feature in certain browsers, as detailed in the “Your Data Privacy Choices” section. It&apos;s important to note that opting out of “sales” and “sharing” only restricts certain types of personal information disclosures.</p>
            <p>We retain the right to take reasonable measures to validate your request&apos;s authenticity.</p>
            <p>Use of California Personal Information. We and our service providers utilize the personal information categories we gather from you for various business purposes, including:</p>
            <ul>
              <li>Establishing and managing your Vihara.ai Account, along with your account profile, registration details, and communication preferences.
              </li>
              <li>Improving the user experience.
              </li>
              <li>Conducting research and analytics.</li>
              <li>Tailoring and delivering personalized content and advertising across our Services and beyond.</li>
              <li>Addressing and fulfilling your requests for Services, including qualifying bids or offers, and other inquiries.</li>
              <li>Assessing your eligibility for specific marketing or transactional events, services, gifts, prizes, and unique features of our Services.</li>
              <li>Contacting you via call, email, or other means for various purposes, such as facilitating registered marketing or transactional events, sharing information about new opportunities or services, discussing topics of interest, managing your Vihara.ai Account, informing you about the latest Services developments and features, and for other marketing, transactional, informational, or research-related endeavors.</li>
              <li>Administering, registering, or facilitating your participation in recreational, educational, or entertainment activities; surveys or questionnaires; promotions or sweepstakes; or any other services, events, or activities sponsored by us or third parties, or connected with our Services.</li>
              <li>Sending you prizes and gifts.</li>
              <li>Enforcing our Terms of Use and other contractual rights.</li>
              <li>Safeguarding our rights and property, as well as those of others.</li>
              <li>Ensuring compliance with applicable laws.</li>
            </ul>

            <p>For more details, please refer to the “Use of Your Information” section above.
            </p>
            <p>Use of California Personal Information. We and our service providers may utilize the personal information categories we gather from and about you for various business purposes, including but not limited to:</p>

            <ul>
              <li>Establishing and maintaining your Vihara.ai Account, managing your account profile, registration details, and communication preferences.</li>
              <li>Improving the overall user experience.</li>
              <li>Conducting research and analytics to enhance our services.
              </li>
              <li>Tailoring and delivering personalized content and advertising across our platform and other channels.
              </li>
              <li>Responding to and fulfilling your requests for services, including qualifying bids or offers, and addressing inquiries.
              </li>
              <li>Evaluating your eligibility for specific marketing or transactional events, services, gifts, prizes, and exclusive features.</li>
              <li>Contacting you via various channels to facilitate registered marketing or transactional events, inform you about new opportunities or services, share information on topics of interest, manage your Vihara.ai Account, notify you about the latest platform developments and features, and for other marketing, transactional, informational, or research-related purposes.</li>
              <li>Administering, registering, or facilitating your participation in recreational, educational, or entertainment activities; surveys or questionnaires; promotions or sweepstakes; or any other services, events, or activities sponsored by us or third parties, or offered in connection with our platform.</li>
              <li>Sending you prizes and gifts as part of promotional activities.
              </li>
              <li>Enforcing our Terms of Use and other contractual obligations.</li>
              <li>Safeguarding our rights and property, as well as those of others.
              </li>
              <li>Ensuring compliance with relevant laws and regulations.
              </li>
            </ul>

            <p>
              Please read the “Use of Your Information” section for additional information.

            </p>
            <p>
              Additional California Data Privacy Rights. Under the CCPA, California residents enjoy additional data privacy rights, with some exceptions:

            </p>
            <ul>
              <li><b>Right to Know:</b> You can request that we provide you with details on the categories of personal information collected or disclosed about you in the past 12 months, including the sources of such information, the purpose for collection or sale, and the categories of third parties with whom we shared it.</li>
              <li><b>Right to Access:</b> You have the right to request access to and/or a copy of specific information held about you from the last 12 months.</li>
              <li><b>Right to Correct:</b> You may request correction of any inaccurate personal information.
              </li>
              <li><b>Right to Delete:</b> You can request the deletion of personal information you provided to us.</li>
              <li><b>Right to Limit Use and Disclosure of Sensitive Personal Information:</b> We may collect and utilize your Sensitive Personal Information for permitted purposes as outlined by the CCPA. If we use or disclose this information for purposes beyond those allowed by the CCPA, you have the right to receive notice and request limitation of such use or disclosure.</li>
              <li><b>Right of Nondiscrimination:</b> You have the right not to face discrimination for exercising these data privacy rights.
              </li>
            </ul>

            <p>
              However, certain information and business practices may be exempt from such requests under applicable law. For instance, we may need certain types of information to provide you with our services or to comply with the law. If you request deletion of specific information, we may refuse based on these reasons or grant it under conditions where you may no longer access or use our services.
            </p>
            <p>
              You might be entitled to receive details about any financial incentives we provide to you, if applicable. To ensure prompt handling of your requests regarding these rights, please visit the “Your Privacy Choices” link on our homepage or complete the “Privacy Request Form.” Alternatively, you can reach out to us via our number at (916)-813-4649.

            </p>
            <p>
              Prior to fulfilling your request, we may need to verify your identity, which could involve confirming details such as your name, email address, mailing address, and phone number. If you wish to have an authorized agent act on your behalf, we require a legally valid power of attorney signed by you, in accordance with California Probate Code sections 4121 to 4130, or an acceptable written authorization. In some cases, we may request further verification of your identity and confirmation of the agent’s authority. To submit a request through your authorized agent, please visit the “Your Privacy Choices for Authorized Agents” or “Privacy Request Form for Authorized Agents” section.
            </p>

            <div className="privacy-content-text-heading">
              Notice for Nevada Residents

            </div>

            <p>
              Under Nevada law, some Nevada consumers are allowed to opt out of the “sale” of “personally identifiable information” for monetary consideration to a person for that person to sell the information to additional persons. We do not engage in this kind of activity as of the effective date of this Privacy Statement, but if you are a Nevada resident and have purchased any kind of products or services from us or had any sort of transaction, you may submit a request to opt of any future “sales” under Nevada law by emailing a request to <a href="">trisha@vihara.ai</a>. Please note that we may take steps to verify your identity of the request. Once your request has been verified, we will maintain your request in the event that our practices change.
            </p>

            <div className="privacy-content-text-heading">
              Security and Account Information

            </div>
            <p>
              We have added physical, technical, administrative, and electronic security measures in place to reduce the risk of any inappropriate misuse, loss, and alteration of personal information. However, because no security measures are perfect or impenetrable, we cannot promise that your information will remain secure. We appreciate the efforts you have put in safeguarding your and others’ privacy. We highly recommend you to notify us immediately if you bellevue that any personal information you shared with us is being used contrary to this Privacy Statement.
            </p>

            <div className="privacy-content-text-heading">
              Children’s Information

            </div>

            <p>
              The Services that we provide are not directed toward persons that are under 18 years of age. We do not market nor collect any personal information from persons that are under the age of 18. If you are under 18, you are not allowed to submit any personal information to us or the Services. For instance, you may not create an account on www.vihara.ai nor utilize anyone else’s account. When you provide information to us via the Services, you confirm that you are 18 years of age or older. If a parent or guardian believes that the Services has stored information of a child that is under the age of 13, please immediately contact us as described in the “Contacting Us” section below to request that we remove such information from our database immediately.
            </p>

            <div className="privacy-content-text-heading">
              Third Party Links and Services

            </div>
            <p>The Services may have links that go to third-party websites, including but not limited to social media networking sites and identity verification sites. When you use these features, your information may be collected or shared, depending on the feature. Please note that we are not responsible for the privacy practices or content of any other websites or services, including those to which we link in our website. We do not represent nor endorse any third-party websites or services. The information that you choose to provide to or that is collected by these third party websites or services is not covered by our Privacy Statement. We strongly recommend that you read such third parties’ privacy statements to stay informed.</p>

            <div className="privacy-content-text-heading">

              Amendments to Privacy Statement


            </div>
            <p>
              We may update and amend this Privacy Statement at any time by posting the amended terms on the Services. Any changes that are made to this Privacy Statement is immediately effective upon posting, unless a different effective date is written in the update. Your continued use of the Services as per the effective date of this Privacy Statement, including any changes, means that you accept the most updated revision. If we make any updates to the Privacy Statement, we will post the most updated version on <a href="">www.vihara.ai</a> and notify you to the extent that is required by law using the main email address that is registered on your <a href="">www.vihara.ai</a> account.
            </p>

            <div className="privacy-content-text-heading">
              International Transfers of Information

            </div>

            <p>
              Some of the disclosures and uses that are written in this Privacy Statement can involve the transfer and sharing of your personal information to other countries in the world that may have different levels of privacy protection than the country you reside in. When you submit your personal information, you consent to such transfers of information. When you use our Services, or when you submit your personal information to us, you consent to the storage and processing of your personal information in the United States of America and other jurisdictions.

            </p>
            <div className="privacy-content-text-heading">
              Contacting Us
            </div>
            <p>
              Rights under the California and Nevada law underline that rights requests can only be submitted as described in Section 7 and Section 8. If you have any other concerns or questions about this Privacy Statement, you may contact us by sending us an email to trisha@vihara.ai. Please provide your name, number, mailing address, and email address so we can service you as quickly as possible. You may also reach us at (916) 813-4649 or write to us at Privacy–Legal Department, 1335 S Milpitas Blvd, Milpitas, CA 95035.

            </p>




          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyStatments;
