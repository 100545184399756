import React from 'react'
import "./BuyersSellers.css"
function BuySellers() {
    return (
        <div className='sellers-containers'>
            <h1>The Vihara platform has streamlined how auctions are held for our buyers and sellers.</h1>
            <div className='circle-container'>
                <div className='circle-one'>
                    <h3>Buyers</h3>
                </div>
                <div className='circle-two'>
                    <h3>Vihara</h3>
                </div>
                <div className='circle-three'>
                    <h3>Sellers</h3>
                </div>
            </div>
            <div className='description-container'>
                <div className='description-1'>
                    <h3>Buyers</h3>
                    <p>
                        Our buyers leverage our digital auctioneering platform to bid on properties. With Vihara, 
                        buyers have access to property details, a wide inventory of properties,
                         and instant customer support, all on one platform.
                        Under Sellers, please change the sentence to 
                        “Our sellers have access to the country’s leading auction programs and
                         valuable data intelligence and insights to facilitate asset movement. 
                        Through digital and mobile technology, we enhance the visibility of assets on our platform.
                    </p>
                </div>
                <div className='description-2'>
                    <h3>Sellers</h3>
                    <p>
                        Our sellers have access to the country&apos;s leading auction programs and valuable data
                        intelligence and insights to facilitate asset movement.
                        Through digital and mobile technology, we enhance the visibility of assets on our platform.
                        Discover more about asset disposition on the Vihara.ai Help Center.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default BuySellers
