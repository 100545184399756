import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Menu,
    MenuItem,
    Checkbox,
    ListItemText,
    useMediaQuery,
    Grid,
    Input,
    Select,
    TextField,
    InputLabel,
    FormControl,
} from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { useFilterContext } from '../../../../ContextProvider/Context';
import "../BuyNavbar.css";
const PropertyDropDown = ({ label, options, selectedValues, onSelect }) => {
    const { beds, setBeds, baths, setBaths, minSquareFeet, setMinSquareFeet, maxSquareFeet, setMaxSquareFeet } = useFilterContext();
    const isScreenSmall = useMediaQuery('(max-width:1150px)');
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (option) => {
        const currentIndex = selectedValues.indexOf(option);
        const newSelectedValues = [...selectedValues];
        if (currentIndex === -1) {
            newSelectedValues.push(option);
        } else {
            newSelectedValues.splice(currentIndex, 1);
        }
        onSelect(newSelectedValues);
    };

    const customFontFamily = {
        fontFamily: '"Roboto", Helvetica, Arial, sans-serif',
    };

    return (
        <>
            <Button
                onClick={handleClick}
                variant="outlined"
                style={{
                    padding: '4px 9px',
                    fontSize: '16px',
                    borderColor: '#000000DE',
                    fontWeight: '300',
                    color: '#000000DE',
                    textTransform: "none",
                    width: isScreenSmall ? '98%' : '130px',
                    ...customFontFamily,
                    display:"flex",
                    justifyContent:"space-between"
                }}
            >
                <span style={{ minWidth: "90px",textAlign:"left" }}>{label}</span> <FontAwesomeIcon style={{ marginLeft: '5px', color: "#000000DE" }} icon={faCaretDown} />
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                style={{ marginTop: '10px', ...customFontFamily }}
            >
                <div style={{ padding: '10px', paddingRight: "0px" }}>
                    <Grid container spacing={1} sx={{ width: "256px", ...customFontFamily }}>
                        <Grid item xs={4.1} style={{ marginBottom: "15px" }}>
                            <FormControl variant="standard" fullWidth>
                                <div className='property-dropdown-type-text' style={{ paddingLeft: "0px", marginBottom: "5px" }}>
                                    Beds
                                </div>
                                <Select
                                    label="Beds"
                                    value={beds}
                                    onChange={(e) => setBeds(e.target.value)}
                                    style={customFontFamily}
                                >
                                    <MenuItem value={"Any"} style={customFontFamily}>Any</MenuItem>
                                    <MenuItem value={1} style={customFontFamily}>1+ Bed</MenuItem>
                                    <MenuItem value={2} style={customFontFamily}>2+ Beds</MenuItem>
                                    <MenuItem value={3} style={customFontFamily}>3+ Beds</MenuItem>
                                    <MenuItem value={4} style={customFontFamily}>4+ Beds</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4.1}>
                            <FormControl variant="standard" fullWidth>
                                <div className='property-dropdown-type-text' style={{ paddingLeft: "0px", marginBottom: "5px" }}>
                                    Baths
                                </div>
                                <Select
                                    label="Baths"
                                    value={baths}
                                    onChange={(e) => setBaths(e.target.value)}
                                    style={customFontFamily}
                                >
                                    <MenuItem value={"Any"} style={customFontFamily}>Any</MenuItem>
                                    <MenuItem value={1} style={customFontFamily}>1+ Bath</MenuItem>
                                    <MenuItem value={2} style={customFontFamily}>2+ Baths</MenuItem>
                                    <MenuItem value={3} style={customFontFamily}>3+ Baths</MenuItem>
                                    <MenuItem value={4} style={customFontFamily}>4+ Baths</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <div className='property-dropdown-type-text'>
                            Square Feet
                        </div>
                        <div style={{ marginLeft: "5px", marginRight: "10px" }}>
                            <Select
                                value={minSquareFeet}
                                onChange={(e) => setMinSquareFeet(e.target.value)}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Min Square Feet' }}
                                IconComponent={() => null} // This removes the dropdown icon
                                className='buy-navbar-property-dropdown-input'
                            >
                                <MenuItem

                                    sx={{
                                        '&:hover': {
                                            backgroundColor: 'rgba(25, 118, 210, 0.08)', // Custom hover background color
                                        },
                                    }}
                                    value="">
                                    <em>Min</em>
                                </MenuItem>
                                <MenuItem
                                    sx={{
                                        '&:hover': {
                                            backgroundColor: 'rgba(25, 118, 210, 0.08)', // Custom hover background color
                                        },
                                    }}
                                    value={1000}>1000 sq ft</MenuItem>
                                <MenuItem
                                    sx={{
                                        '&:hover': {
                                            backgroundColor: 'rgba(25, 118, 210, 0.08)', // Custom hover background color
                                        },
                                    }}
                                    value={1500}>1500 sq ft</MenuItem>
                                <MenuItem
                                    sx={{
                                        '&:hover': {
                                            backgroundColor: 'rgba(25, 118, 210, 0.08)', // Custom hover background color
                                        },
                                    }}
                                    value={2000}>2000 sq ft</MenuItem>
                                <MenuItem
                                    sx={{
                                        '&:hover': {
                                            backgroundColor: 'rgba(25, 118, 210, 0.08)', // Custom hover background color
                                        },
                                    }}
                                    value={500}>2500 sq ft</MenuItem>
                                {/* Add more options as needed */}
                            </Select>
                        </div>
                        <div>
                            <Select
                                value={maxSquareFeet}
                                onChange={(e) => setMaxSquareFeet(e.target.value)}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Max Square Feet' }}
                                IconComponent={() => null} // This removes the dropdown icon
                                className='buy-navbar-property-dropdown-input'
                            >
                                <MenuItem
                                    sx={{
                                        '&:hover': {
                                            backgroundColor: 'rgba(25, 118, 210, 0.08)', // Custom hover background color
                                        },
                                    }}
                                    value="">
                                    <em>Max</em>
                                </MenuItem>
                                <MenuItem
                                    sx={{
                                        '&:hover': {
                                            backgroundColor: 'rgba(25, 118, 210, 0.08)', // Custom hover background color
                                        },
                                    }}
                                    value={1000}>1000 sq ft</MenuItem>
                                <MenuItem
                                    sx={{
                                        '&:hover': {
                                            backgroundColor: 'rgba(25, 118, 210, 0.08)', // Custom hover background color
                                        },
                                    }}
                                    value={1500}>1500 sq ft</MenuItem>
                                <MenuItem
                                    sx={{
                                        '&:hover': {
                                            backgroundColor: 'rgba(25, 118, 210, 0.08)', // Custom hover background color
                                        },
                                    }}
                                    value={2000}>2000 sq ft</MenuItem>
                                <MenuItem
                                    sx={{
                                        '&:hover': {
                                            backgroundColor: 'rgba(25, 118, 210, 0.08)', // Custom hover background color
                                        },
                                    }}
                                    value={500}>2500 sq ft</MenuItem>
                                {/* Add more options as needed */}
                            </Select>
                        </div>
                    </Grid>
                </div>
                <div className='property-dropdown-type-text'>
                    Type
                </div>
                {options.map((option) => (
                    <MenuItem
                        key={option}
                        onClick={() => handleMenuItemClick(option)}
                        style={{ paddingTop: "0px", paddingBottom: "0px", ...customFontFamily, height: "35px", paddingLeft: "0px" }}
                    >
                        <Checkbox checked={selectedValues?.indexOf(option) > -1} />
                        <div className='more-filter-dropddown-below-text'><span>{option}</span></div>
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

export default PropertyDropDown;
