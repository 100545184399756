import React from 'react'
import './Investment.css'
import { useState } from 'react';
function InvestementCalculator() {
    const [value, setValue] = useState(50); // Default value

    const handleChange = (event) => {
        setValue(event.target.value);
    };
    return (
        <div  className='investement-calculator-container'>
            <h2>Investment Calculator</h2>
            <div className='investment-calculator-function-details'>
                <div className='investment-calculator-function' >
                    <div className='purchase-row'>
                        <div className='purchase-container'>Purchase</div>
                        <div className="investment-calculator-slider-container investemtn-calculator-purchase-text">
                            <span>Resale AVM: $194,700</span>
                        </div>
                        <div></div>
                    </div>
                    <div className='financing-row'>
                        <div className='finance-container'>Finance</div>
                        <div className="investment-calculator-slider-container">
                            <label htmlFor="offerAmount" className="investment-calculator-slider-label">Offer Amount</label>
                            <input
                                type="range"
                                id="offerAmount"
                                min="0"
                                max="100"
                                value={value}
                                onChange={handleChange}
                                className="investment-calculator-slider"
                            />
                        </div>
                        <div>
                            <input
                                type="text"
                                placeholder='$194,700'
                                className='investment-calculator-input'
                            />
                        </div>
                    </div>
                    <div className='expenses-row'>
                        <div className='expenses-container'>Rental</div>
                        <div className="investment-calculator-slider-container">
                            <label htmlFor="offerAmount" className="investment-calculator-slider-label">Rehab Cost</label>
                            <input
                                type="range"
                                id="offerAmount"
                                min="0"
                                max="100"
                                value={value}
                                onChange={handleChange}
                                className="investment-calculator-slider"
                            />
                        </div>
                        <div className='investment-calculator-rehab-cost-input-container'>
                            <input
                                type="text"
                                placeholder='$194,700'
                                className='investment-calculator-input investement-calculator-rehab-cost-first-input'
                            />
                            <input
                                type="text"
                                placeholder='$19'
                                className='investment-calculator-input-rehab-cost'
                            />
                        </div>
                    </div>
                    <div className='rental-row'>
                        <div className='rental-container'>Rental Estimate</div>
                        <div className="investment-calculator-slider-container">
                            <label htmlFor="offerAmount" className="investment-calculator-slider-label">Closing Cost</label>
                            <input
                                type="range"
                                id="offerAmount"
                                min="0"
                                max="100"
                                value={value}
                                onChange={handleChange}
                                className="investment-calculator-slider"
                            />
                        </div>
                        <div>
                            <input
                                type="text"
                                placeholder='$194,700'
                                className='investment-calculator-input'
                            /></div>
                    </div>
                    <div>
                        * The investment calculator and its calculations are for information purposes only.
                        You acknowledge that these are estimates only and that (a) there are uncertainties
                        inherent in making such estimates, forecasts, plans and projections and that you
                        are familiar with such uncertainties, and (b) You will take full responsibility
                        for conducting your own evaluation of the adequacy and accuracy of such estimates,
                        forecasts, plans and projections and (c) You shall have no Claim against Altisource
                        and its affiliates with respect to such estimates, forecasts,
                        plans or projections. Accordingly,
                        Altisource and its affiliates are making no representation or warranty with respect to such estimates,
                        forecasts, plans and projections (including such underlying assumptions).
                    </div>
                </div>
                <div className='investment-calculator-function-details-numbers' >
                    <div className='investment-calculator-function-details-numbers-capital-investment'>
                        <div><span>Capital Investment</span><span>$344,760</span></div>
                        <div><span>Needed at Closing</span> <span>$344,760</span> </div>
                    </div>
                    <div>
                        <div><span>Capital Investment</span><span>$344,760</span></div>
                        <div><span>Needed at Closing</span> <span>$344,760</span> </div>
                    </div>
                    <div>
                        <div><span>Capital Investment</span><span>$344,760</span></div>
                        <div><span>Needed at Closing</span> <span>$344,760</span> </div>
                    </div>
                    <div>
                        <div><span>Capital Investment</span><span>$344,760</span></div>
                        <div><span>Needed at Closing</span> <span>$344,760</span> </div>
                    </div>
                    <div className='investibility'>
                        <div>INVESTABILITY</div>
                        <div><span>Gross Yield</span><span>10.72%</span></div>
                        <div><span>Monthly Cash Flow</span> <span>$410</span> </div>
                        <div><span>Net Yield</span> <span>$344,760</span> </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InvestementCalculator
