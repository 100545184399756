import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Menu,
    MenuItem,
    Checkbox,
    ListItemText,
    useMediaQuery,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDoorOpen, faHandHolding, faSignHanging } from '@fortawesome/free-solid-svg-icons';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import "../BuyNavbar.css"
const ConditionDropDown = ({ label, options, selectedValues, onSelect }) => {
    const isScreenSmall = useMediaQuery('(max-width:1150px)');
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (option) => {
        const currentIndex = selectedValues.indexOf(option);
        const newSelectedValues = [...selectedValues];
        if (currentIndex === -1) {
            newSelectedValues.push(option);
        } else {
            newSelectedValues.splice(currentIndex, 1);
        }
        onSelect(newSelectedValues);
    };

    const customFontFamily = {
        fontFamily: '"Roboto", Helvetica, Arial, sans-serif',
    };

    return (
        <>
            <Button
                onClick={handleClick}
                variant="outlined"
                style={{
                    padding: '4px 9px',
                    fontSize: '16px',
                    borderColor: '#000000DE',
                    fontWeight: '300',
                    color: '#000000DE',
                    textTransform: "none",
                    width: isScreenSmall ? '98%' : '130px',
                    display: "flex",
                    justifyContent: "space-between",
                    ...customFontFamily,
                }}
            >
                <span style={{ minWidth: "90px",textAlign:"left" }}>{label}</span> <FontAwesomeIcon style={{ marginLeft: '5px', color: "#000000DE" }} icon={faCaretDown} />
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                style={{ marginTop: '10px', ...customFontFamily, width: "350px" }}
            >
                <div
                    onClick={() => handleMenuItemClick("vacant")}
                    className="condition-dropddown-buynavbar"
                    style={{ marginTop: "15px" }}
                >
                    <Checkbox checked={selectedValues?.indexOf("vacant") > -1} />
                    <div className='condition-dropddown-icon-text'><span> vacant</span>
                        <p>
                            Foreclosed homes are not always empty. No eviction is needed. </p>
                    </div>
                </div>
                <div
                    onClick={() => handleMenuItemClick("Interior Access Available")}
                    className="condition-dropddown-buynavbar"
                >
                    <Checkbox checked={selectedValues?.indexOf("Interior Access Available") > -1} />
                    <div className='condition-dropddown-icon-text'><span> Interior Access Available</span>
                        <p>
                            You can schedule a visit to view the property
                        </p></div>
                </div>
                <div
                    onClick={() => handleMenuItemClick("Eligible for Financing")}
                    className="condition-dropddown-buynavbar"
                >
                    <Checkbox checked={selectedValues?.indexOf("Eligible for Financing") > -1} />
                    <div className='condition-dropddown-icon-text'><span> Eligible for Financing</span>
                        <p>
                            These properties can be financed with residential mortgage.
                        </p></div>
                </div>
                <p className='condition-dropdown-end-para'><strong>Note:</strong> Some properties on Vihara are sold as “Cash only” and “As-is” so use these filters to reduce your risk. </p>
            </Menu>
        </>
    );
};
export default ConditionDropDown;
