import React, { useState } from 'react';
import './header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faBars } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const AdminPanelHeader = ({ toggleSidebar }) => {
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const admin = useSelector((state) => state.admin.admin);

  const handleImageClick = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  return (
    <header className="admin-panel-header">
      <div className="admin-panel-search-bar">
        <FontAwesomeIcon onClick={() => toggleSidebar(true)} icon={faBars} className="admin-panel-bar-icon fa-lg" />
        <FontAwesomeIcon icon={faSearch} className="admin-panel-search-icon fa-lg" />
        <input type="text" placeholder="Search..." />
        <div className="admin-panel-avatar">
          <img 
            src="https://png.pngtree.com/png-vector/20190710/ourmid/pngtree-user-vector-avatar-png-image_1541962.jpg" 
            alt="avatar" 
            onClick={handleImageClick} 
            className="clickable-avatar"
          />
        </div>
        {isDropdownVisible && (
          <div className="admin-name-dropdown">
            <p>{admin.name}</p>
          </div>
        )}
      </div>
    </header>
  );
};

AdminPanelHeader.propTypes = {
  toggleSidebar: PropTypes.func.isRequired,
};

export default AdminPanelHeader;
