import React from 'react';
import './heroSectionNews.css';
import newsImage from '../assets/newsroom image.png'; // Adjust the path according to your file structure

const HeroSectionNews = () => {
  return (
    <div className="news-page-newsroom">
      <div className="news-page-news-feature">
        <div className="news-page-news-feature-text">
          <h2 className="news-page-news-feature-title">FEATURE</h2>
          <h1 className="news-page-news-feature-heading">The Next AI Implementation: The Vihara Platform</h1>
          <p className="news-page-news-feature-description">
          AI is growing past the office, revolutionizing platforms and enhancing the bidding process.
          </p>
        </div>
        <div className="news-page-news-feature-image">
          <img src={newsImage} alt="AI Transformation" />
        </div>
      </div>
    </div>
  );
};

export default HeroSectionNews;
