import React from "react";
import "../CommonConditions.css";
import NavBackground from "../../../includes/navBackground";

const Accesssibility = () => {
  return (
    <div className="conditions-background">
      <NavBackground />
      <div className="privacy-term-main-container">
        <div className="privacy-content-updated-date">
          Updated on 2nd May 2024
        </div>
        <div className="privacy-content-main-heading">
          Accessibility Statement
        </div>
        <div className="privacy-content-main-section">
          <div className="privacy-content-additional-section">
            <h2>Additional Links</h2>

            <h4>
              <a href="/privacy-statement">Privacy Statement</a>
            </h4>

            <h4>
              <a href="/website-terms-of-use">Website Terms of Use</a>
            </h4>
            <h4>
              <a href="#">Cookie Preferences</a>{" "}
            </h4>
            <h4>
              <a href="/privacy-choice">Privacy Choices</a>{" "}
            </h4>
            <h4>
              <a href="/participation-terms">Participation Terms </a>
            </h4>
            <h4>
              <a href="/accessibility">Accessibility</a>
            </h4>
          </div>
          <div className="privacy-content-main-content-section">
            <div className="privacy-content-text-heading">Introduction</div>

            <p>
              The Internet stands as a vast repository supporting the freedom of
              information, benefiting both the general user community and
              individuals with disabilities. Recognizing this, we prioritize
              providing equal service to people with disabilities and strive to
              enhance their browsing experience.
            </p>
            <p>
              In our commitment to ensuring accessibility for all, we have
              invested in website usability, believing that every individual
              deserves a life characterized by equality, respect, comfort, and
              independence.
            </p>
            <p>
              Furthermore, we endeavor to ensure that all digital
              communications, including emails, received from Auction.com are
              accessible to individuals with disabilities.
            </p>
            <div className="privacy-content-text-heading">
              How Does Website Accessibility Work?
            </div>
            <p>
              The website includes an accessibility menu, accessible by clicking
              on the designated icon. Upon selecting a menu item, users must
              wait for the page to load.
            </p>
            <div className="privacy-content-text-heading">
              What do the Menu Items Include?{" "}
            </div>
            <ul>
              <li>Keyboard-based navigation option</li>
              <li>Adaptation of the website for NVDA assistive technology</li>
              <li>Font enlargement with four levels of magnification</li>
              <li>Freezing moving elements and halting blinking effects</li>
              <li>Altering color contrast against dark backgrounds</li>
              <li>Altering color contrast against light backgrounds</li>
              <li>Adaptation for color-blind users</li>
              <li>Font changes for improved readability</li>
              <li>Enlarged cursor with black or white color options</li>
              <li>Display enlargement up to 200%</li>
              <li>Emphasis on website links and headings</li>
              <li>Presentation of alternative text for graphics</li>
              <li>Accessibility declaration</li>
              <li>Submission of accessibility feedback</li>
            </ul>
            <div className="privacy-content-text-heading">Exclusions</div>
            <p>
              Despite our efforts to implement accessibility across all
              webpages, some pages may not be fully compatible with
              accessibility solutions. We remain committed to enhancing the
              website&apos;s accessibility to the best of our ability, guided by
              our belief in inclusivity and our moral obligation to ensure
              usability for all.
            </p>
            <div className="privacy-content-text-heading">
              Contact and Feedback{" "}
            </div>
            <p>
              We value feedback from our users. If you encounter any
              accessibility challenges while browsing the website, we encourage
              you to provide feedback.
            </p>
            <p>
              Please reach out to our Accessibility Coordinator at{" "}
              <a href="mailto:trisha@vihara.ai">trisha@vihara.ai</a> for
              inquiries and feedback regarding accessibility.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accesssibility;
