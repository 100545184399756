const eventsData = [
    { id: "E-10021", dateRange: "May 22nd to May 28th", state: "Louisiana", eventName: "Louisiana Bayou Auction", eventType: "Foreclosure", assets: 40 },
    { id: "E-10022", dateRange: "May 23rd", state: "Alaska", eventName: "Alaska Wilderness Sale", eventType: "REO Bank Owned", assets: 30 },
    { id: "E-10023", dateRange: "May 24th to May 30th", state: "Hawaii", eventName: "Hawaii Beachfront Property Auction", eventType: "Foreclosure", assets: 20 },
    { id: "E-10024", dateRange: "May 25th", state: "Montana", eventName: "Montana Mountain Retreat Auction", eventType: "Foreclosure", assets: 15 },
    { id: "E-10025", dateRange: "May 26th to June 1st", state: "Wyoming", eventName: "Wyoming Ranch Foreclosure Sale", eventType: "Foreclosure", assets: 10 },
    { id: "E-10026", dateRange: "May 27th", state: "Utah", eventName: "Utah Desert Property Auction", eventType: "REO Bank Owned", assets: 5 },
    { id: "E-10027", dateRange: "May 28th to June 3rd", state: "Idaho", eventName: "Idaho Woodland Auction", eventType: "Foreclosure", assets: 25 },
    { id: "E-10028", dateRange: "May 29th", state: "Kansas", eventName: "Kansas Prairie Auction", eventType: "Foreclosure", assets: 30 },
    { id: "E-10029", dateRange: "May 30th to June 5th", state: "Alabama", eventName: "Alabama Coastal Property Sale", eventType: "REO Bank Owned", assets: 20 },
    { id: "E-10030", dateRange: "May 31st", state: "Mississippi", eventName: "Mississippi Riverfront Auction", eventType: "Foreclosure", assets: 15 },
    { id: "E-10031", dateRange: "June 1st to June 7th", state: "Tennessee", eventName: "Tennessee Valley Auction", eventType: "Foreclosure", assets: 10 },
    { id: "E-10032", dateRange: "June 2nd", state: "Kentucky", eventName: "Kentucky Bluegrass Estate Sale", eventType: "REO Bank Owned", assets: 5 },
    { id: "E-10033", dateRange: "June 3rd to June 9th", state: "West Virginia", eventName: "West Virginia Hilltop Auction", eventType: "Foreclosure", assets: 25 },
    { id: "E-10034", dateRange: "June 4th", state: "Virginia", eventName: "Virginia Beach House Sale", eventType: "Foreclosure", assets: 30 },
    { id: "E-10035", dateRange: "June 5th to June 11th", state: "North Dakota", eventName: "North Dakota Prairie Property Auction", eventType: "REO Bank Owned", assets: 20 },
    { id: "E-10036", dateRange: "June 6th", state: "South Carolina", eventName: "South Carolina Plantation Auction", eventType: "Foreclosure", assets: 15 },
    { id: "E-10037", dateRange: "June 7th to June 13th", state: "Rhode Island", eventName: "Rhode Island Waterfront Sale", eventType: "REO Bank Owned", assets: 10 },
    { id: "E-10038", dateRange: "June 8th", state: "Connecticut", eventName: "Connecticut Mansion Auction", eventType: "Foreclosure", assets: 5 },
    { id: "E-10039", dateRange: "June 9th to June 15th", state: "Delaware", eventName: "Delaware Coastal Property Auction", eventType: "Foreclosure", assets: 25 },
    { id: "E-10040", dateRange: "June 10th", state: "Maryland", eventName: "Maryland Waterfront Estate Auction", eventType: "REO Bank Owned", assets: 30 },
    // Add more entries as needed
    
];

export default eventsData;




// Document your code here

/**
 *
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 */