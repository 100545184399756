import React from 'react';
import './AuctionList.css';

const AdminPanelAuctionList = () => {
  const auctionData = [
    { id: 1, name: 'Los Villas', dateTimeOfAuction: 'Jan 01, 11:59pm', endDateOfAuction: 'Jan 01, 12:00am', status: 'STA' },
    { id: 2, name: 'Sunset Heights', dateTimeOfAuction: 'Feb 15, 2:00pm', endDateOfAuction: 'Feb 15, 3:00pm', status: 'Open' },
    { id: 3, name: 'Mountain View', dateTimeOfAuction: 'Mar 10, 10:00am', endDateOfAuction: 'Mar 10, 11:00am', status: 'Accept' },
    { id: 4, name: 'Ocean Breeze', dateTimeOfAuction: 'Apr 22, 5:00pm', endDateOfAuction: 'Apr 22, 6:00pm', status: 'Reject' },
    { id: 5, name: 'City Lights', dateTimeOfAuction: 'May 05, 9:00am', endDateOfAuction: 'May 05, 10:00am', status: 'Discard' },
    { id: 6, name: 'Green Acres', dateTimeOfAuction: 'Jun 18, 3:00pm', endDateOfAuction: 'Jun 18, 4:00pm', status: 'STA' },
    { id: 7, name: 'Riverdale', dateTimeOfAuction: 'Jul 30, 1:00pm', endDateOfAuction: 'Jul 30, 2:00pm', status: 'Open' },
  ];

  return (
    <div className="admin-panel-auction-list">
      <div className='admin-panel-auction-list-container'>
      <div className="admin-panel-filters">
        <div>
          <label>Property Name</label>
          <input type="text" placeholder="Property Name" />
        </div>
        <div>
          <label>Description like location beds...</label>
          <input type="text" placeholder="Description like location beds..." />
        </div>
        <div >
          <label className="admin-select-option-label">Status...</label>
          <select>
            <option value="">Select Option</option>
            <option value="Open">Open</option>
            <option value="Accept">Accept</option>
            <option value="Reject">Reject</option>
            <option value="STA">STA</option>
            <option value="Discard">Discard</option>
          </select>
        </div>
      </div>
      <div className="admin-filter-button-container">
        <button className="admin-panel-reset-btn">Reset</button>
        <button className="admin-panel-search-btn">Search</button>
      </div>
      <h3>Auction/List</h3>
      <div className='admin-panel-table-container'>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Date Time of Auction</th>
            <th>End Date of Auction</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {auctionData.map((auction) => (
            <tr key={auction.id}>
              <td>{auction.name}</td>
              <td>{auction.dateTimeOfAuction}</td>
              <td>{auction.endDateOfAuction}</td>
              <td>{auction.status}</td>
              <td>...</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </div>
    </div>
  );
};

export default AdminPanelAuctionList;
