import React, { useState, useRef, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import PropTypes from 'prop-types';
import "./EnterpriseGrade.css";

function AnimatedNumberS({ number }) {
    const [inView, setInView] = useState(false);
    const ref = useRef();

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                setInView(true);
                observer.disconnect();
            }
        });
        observer.observe(ref.current);
        return () => observer.disconnect();
    }, []);

    const props = useSpring({
        number: inView ? number : 0,
        from: { number: 0 },
        config: { duration: 1500 },
    });

    return <animated.h1 className='box-number' ref={ref}>{props.number.to(n => Math.floor(n))}</animated.h1>;
}

AnimatedNumberS.propTypes = {
    number: PropTypes.number.isRequired,
};

function EnterpriseGrade() {
    return (
        <div className='enterprise-grade-container'>
            <h1>Convenience, flexibility, and scalability.</h1>
            <p>When listings, biddings, and transactions are all integrated, the entire process to bid becomes simpler.</p>
            <div className="enterprise-grade-stats">
                <div className="box">
                    <div>
                    <AnimatedNumberS number={4} />
                    </div>
                    <span>Integrations in our partner ecosystem</span>
                </div>
                <div className="box">
                    <AnimatedNumberS number={3} />
                    <span>Assets connected by some of the world’s leading organizations</span>
                </div>
                <div className="box">
                    <AnimatedNumberS number={5} />
                    <span>Types of properties available to bid on</span>
                </div>
            </div>
        </div>
    );
}

export default EnterpriseGrade;
