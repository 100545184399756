import React, { useState } from 'react';
import './resetPass.css';
// import logo from './assets/vihara-logo.png'; // Adjust the path as necessary
import { Images } from '../../../../constants/constant';
import NavBackground from '../../../includes/navBackground';
import { useEffect } from 'react';
import axios from 'axios';
const ResetPassword = () => {
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [emailAndPhone, setEmailAndPhone] = useState(null);
    const [submitError, setSubmitError] = useState(false);
    const [submitSucess, setSubmitSucess] = useState(false);
    useEffect(() => {
        async function getAllEmail() {
            console.log("in property sample");
            try {
                const response = await axios.get("https://viharabackend.onrender.com/api/v1/user/getEmails");
                console.log(response.data.contacts);
                setEmailAndPhone(response.data.contacts);
            }
            catch (error) {
                console.log(error);
            }
        }
        getAllEmail();
    }, [])
    const handleClick = async(e) => {
        e.preventDefault();
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email regex
        if (!email) {
            setEmailError("Email address is required")
            return;
        }
        if (!emailPattern.test(email)) {
            setEmailError("Please enter a valid email address");
            return;
        }
        const existingRecord = emailAndPhone?.find(obj => obj.email === email);
        console.log(existingRecord);
        if (!existingRecord) {
            setSubmitError(true);
            setSubmitSucess(false);
            return;
        } 
        try {
            const response = await axios.post("https://viharabackend.onrender.com/api/v1/user/forgot/password",{
                email
            });
            setSubmitSucess(true);
            setSubmitError(false);
        } catch (err) {
            console.log(err);
            setSubmitError(true);
        }
    }
    const handleClose=()=>{
        window.location.href="/"
    }
    const validateEmail = (e) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email regex
        if (!email) {
            setEmailError('Email is required');
            return;
        }
        if (!emailPattern.test(email)) {
            setEmailError("Please enter a valid email address");
            return;
        }
    };
    return (
        <>
            <NavBackground />
            <div className="reset-password-container">
                <img src={Images.logoOnDark} alt="Vihara Logo" className="logo" />
                <h2>Reset Password</h2>
                <form onSubmit={handleClick}>
                    {
                        submitSucess === true ?
                            <>
                                <div style={{ marginBottom: "20px", marginTop: "20px" }} className='reset-password-wrong-email-text'>
                                    Check your inbox at {email} and follow the instructions to reset your password
                                </div>
                                <button  onClick={handleClose} style={{ marginBottom: "20px" }} className="reset-button">
                                    Close
                                </button>
                            </> : ""
                    }
                    {submitError === true ?
                        <>
                            <div style={{ width: "100%", marginBottom: "10px" }} className='authentication-faild-on-submit'>
                                The email address you have provided does not exist in our system. Please check again.
                            </div>
                            <div style={{ width: "100%", marginBottom: "20px" }}
                                className='reset-password-wrong-email-text'>
                                Please enter the email address associated with your account
                                and we'll send you a link so you can reset your password.
                                Don't remember email ID? Contact customer care at <span style={{ color: "#007bff" }}> (855) 882-1314.</span>
                            </div>
                        </> : ""}
                    <div className="reset-password-input-group">
                        <label className='sign-up-input-label'><span>Email:</span> <span>*</span> </label>
                        <input
                            onChange={(e) => setEmail(e.target.value)}
                            onBlur={validateEmail}
                            type='text'
                            id="email"
                            name="email"
                            className={`${emailError ? 'input-error' : ''}`}
                            style={{ marginBottom: "10px" }}
                        />
                        {emailError ? <div className='email-error-test-reset'>{emailError}</div> : ""}
                    </div>
                    <button type='submit' className="reset-button">
                        Reset Password
                    </button>
                </form>
                <a href="/login" className="back-to-signin">Back to <span style={{textDecoration:"underline"}}>Sign in</span></a>
            </div>
        </>
    );
};

export default ResetPassword;
