import React, { useState } from "react";
import PropTypes from "prop-types";
import "./faqs.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
const CommonAcordianSection = ({ accData, QuestionFontSize, QuestionBold, CategoryFontWeight, width }) => {
  const [openIndex, setOpenIndex] = useState(null);
  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  return (
    <div className="faqs-page" style={{ width: width ? width : "" }}>
      <div className="faqs-container">
        {accData.map((category, catIndex) => (
          <div key={catIndex} className="faq-category">
            {category.category !== "" && <h2>{category.category}</h2>}
            {category.questions.map((item, index) => (
              <div key={index} className="faq-item">
                <div
                  className="faq-question"
                  onClick={() => toggleFAQ(catIndex + "v" + index)}
                  style={{ fontWeight: QuestionBold }}
                >
                  {item.question}
                  <span>
                    {openIndex === catIndex + "v" + index ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
                  </span>
                </div>
                {openIndex === catIndex + "v" + index && (
                  <div
                    className="faq-answer"
                    dangerouslySetInnerHTML={{ __html: item.answer }}
                  ></div>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};


CommonAcordianSection.propTypes = {
  accData: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string.isRequired,
      questions: PropTypes.arrayOf(
        PropTypes.shape({
          question: PropTypes.string.isRequired,
          answer: PropTypes.string.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
};

export default CommonAcordianSection