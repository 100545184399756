// BidHistoryModal.js
import React from 'react';
import './BidHistoryModal.css';
import PropTypes from 'prop-types';
const BidHistoryModal = ({ onClose, bids }) => {
    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <div className="modal-header">
                    <div>Bid History</div>
                    <button className="close-button" onClick={onClose}>×</button>
                </div>
                <div className="modal-body">
                    <p className="address">144 S 3RD ST 505 | San Jose, CA 95112</p>
                    <ul className="bid-list">
                        {bids.map((bid, index) => (
                            <li key={index} className='your-bid'>
                                <span className="bid-rank">{bids.length - index}.</span>
                                <span className="bid-amount">${bid.amount.toLocaleString()}</span>
                                {bid.you && <span className="bid-you">(you)</span>}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};
BidHistoryModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    bids: PropTypes.arrayOf(PropTypes.shape({
        amount: PropTypes.number.isRequired,
        you: PropTypes.bool.isRequired,
    })).isRequired,
};
export default BidHistoryModal;
