import React from 'react'
import Buys from '../Buys'
function Forclosure() {
  return (
    <>
        <Buys/>
    </>
  )
}

export default Forclosure
