import React from 'react';
import "./CommonHero.css";
import PropTypes from 'prop-types';



const CommonHeroSection = ({ videoSrc, title, description, buttonText, buttonAction,faqImage }) => {
  console.log(title);
  return (
    <div className="common-hero-section">
    <svg xmlns="http://www.w3.org/2000/svg" width="90" height="449" viewBox="0 0 90 449" fill="none" className="learn-more-graphic">
      <g clipPath="url(#clip0_1062_1928)">
        <rect width="662" height="945" rx="331" transform="matrix(1 0 0 -1 -573 844)" stroke="#0384FB"></rect>
      </g>
      <defs>
        <clipPath id="clip0_1062_1928">
          <rect width="90" height="449" fill="white"></rect>
        </clipPath>
      </defs>
    </svg>
      <div  className="common-hero-overlay-content">
      <h1 dangerouslySetInnerHTML={{ __html: title }}></h1>
        <p>{description}</p>
        {/* <button className="common-hero-watch-button"  onClick={buttonAction}>{buttonText}</button> */}
      </div>
      {faqImage&&<img src={faqImage} className ="common-hero-background-video"/>}
      {videoSrc&&<video className ="common-hero-background-video"  autoPlay loop muted>
        <source src={videoSrc} type="video/mp4" />
      </video> }
    </div>
  );
}

export default CommonHeroSection;
` `
CommonHeroSection.propTypes = {
    videoSrc: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    buttonAction: PropTypes.func.isRequired
  };
