import React from 'react'
import "./NewsBottom.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faLinkedin, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
function NewsBottom() {
    return (
        <div className="news-boxes-container">
            <div className="news-boxes-content">
                <div className="news-boxes-box">
                    <div className="news-boxes-icon">
                        <img src="https://images.ctfassets.net/bx9krvy0u3sx/2lCbTjE7uwEgildua5Qh67/851e28faea5ca2b6d0ad7924c05dcf72/Samsara-Chart-Icon.png?w=1000&h=1000&fm=webp&q=80" alt="About Samsara" />
                    </div>
                    <h3>About Vihara</h3>
                    <p>Learn more about Vihara and its platform</p>
                </div>
                <div className="news-boxes-box">
                    <div className="news-boxes-icon">
                        <img src="https://images.ctfassets.net/bx9krvy0u3sx/2lCbTjE7uwEgildua5Qh67/851e28faea5ca2b6d0ad7924c05dcf72/Samsara-Chart-Icon.png?w=1000&h=1000&fm=webp&q=80" alt="Brand Assets" />
                    </div>
                    <h3>Partnerships</h3>
                    <p>How Vihara partners with organizations to make bidding easier.</p>
                </div>
                <div className="news-boxes-box">
                    <div className="news-boxes-icon">
                        <img src="https://images.ctfassets.net/bx9krvy0u3sx/YsKnYJVr78KdNcwfD3w8Y/0d6d170f575e3f3551d95171289a257f/8-reporting.svg?w=1534&q=80" alt="Investor Relations" />
                    </div>
                    <h3>DEI Report</h3>
                    <p>How Vihara creates a culture for all</p>
                </div>
            </div>
            <div className="news-boxes-footer">
                    <div className="news-boxes-social-media">
                    <p>Press and Media Inquiries Contact</p>
                    <a href="mailto:trisha@vihara.ai">trisha@vihara.ai</a>
                    </div>
                    <div>
                    <p className='bottom-follow-vihara'>Follow Vihara</p>
                    <div className='news-bottom-icon'>
                        <FontAwesomeIcon icon={faTwitter} />
                        <FontAwesomeIcon icon={faFacebook} />
                        <FontAwesomeIcon icon={faLinkedin} />
                        <FontAwesomeIcon icon={faInstagram} />
                        <FontAwesomeIcon icon={faYoutube} />
                    </div>
                    </div>
            </div>
        </div>
    )
}

export default NewsBottom
