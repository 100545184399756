import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import FilterDrawer from './EventCalendarDrawer';
import eventsData from './events';
import "./EventCalender.css";
import NavBackground from '../../includes/navBackground';
import CommonHeroSection from '../../HomePage/CommonHeroSection/CommonHeroSection';
import video from "../../HomePage/commonAssets/Calendarvideo.mp4";
import { useMediaQuery } from '@mui/material';
import EventDetailsSmallerScreen from './EventDetailsSmallerScreen';
import states from './utils/States'; // Ensure the path is correct
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomDropdown from './CustomDropDown';
function EventCalendar() {
    const isScreenSmall = useMediaQuery('(max-width:930px)');
    const [events, setEvents] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [eventsPerPage] = useState(10);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [selectedState, setSelectedState] = useState('All States');
    const [selectedAssetType, setSelectedAssetType] = useState('All Types');
    const [selectedDate, setSelectedDate] = useState(null);
    const [hidePastEvents, setHidePastEvents] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const stateNames=states.map((state)=>state.name);
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
      };
    
      const handleStateSelect = (state) => {
        setSelectedState(state);
        setIsOpen(false);
      };
    const handleFilterButtonClick = () => {
        setIsDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setIsDrawerOpen(false);
    };

    useEffect(() => {
        const currentDate = new Date();
        const filteredEvents = eventsData.filter(event => {
            const eventDate = new Date(event.date); // assuming 'date' is your date field in event data
            if (hidePastEvents) {
                return eventDate >= currentDate;
            }
            return true;
        }).filter(event =>
            (event.state === selectedState || selectedState === 'All States') &&
            (event.eventType === selectedAssetType || selectedAssetType === 'All Types')
        );
        setEvents(filteredEvents);
        setCurrentPage(1);  // Reset to page 1 to avoid out-of-range issues
    }, [selectedState, selectedAssetType, selectedDate, hidePastEvents]);

    useEffect(() => {
        const filteredEvents = eventsData.filter(event =>
            (event.state === selectedState || selectedState === 'All States') &&
            (event.eventType === selectedAssetType || selectedAssetType === 'All Types')
        );
        setEvents(filteredEvents);
        setCurrentPage(1);  // Reset to page 1 to avoid out-of-range issues
    }, [selectedState, selectedAssetType, selectedDate]);

    const totalPages = Math.ceil(events.length / eventsPerPage);
    console.log(events);  // Check filtered events
    console.log(currentPage);  // Check current page number
    console.log(totalPages);  // Check total number of pages

    const renderPagination = () => {
        if (totalPages === 1) { return null; }  // No pagination if only one page or less

        let items = [];
        let leftSide = currentPage - 2;
        if (leftSide <= 1) {
            leftSide = 1;
        }
        let rightSide = currentPage + 2;
        if (rightSide > totalPages) {
            rightSide = totalPages;
        }

        for (let number = leftSide; number <= rightSide; number++) {
            items.push(
                <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                    <button onClick={() => setCurrentPage(number)} className="page-link">
                        {number}
                    </button>
                </li>
            );
        }
        if (leftSide > 1) {
            items.unshift(
                <li key="left-ellipses" className="page-item">
                    <span className="page-link">...</span>
                </li>
            );
        }
        if (rightSide < totalPages) {
            items.push(
                <li key="right-ellipses" className="page-item">
                    <span className="page-link">...</span>
                </li>
            );
        }
        return items;
    };

    const handleLearnMoreClick = () => {
        // Define what happens when the button is clicked
        console.log('Learning more...');
    };
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredStates = states.filter((state) =>
        state.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    return (
        <div>
            <NavBackground />
            <CommonHeroSection
                videoSrc={video}
                title="Stay updated with auctions near you."
                description="Gain visibility to upcoming auctions by using our Event Calendar."
                buttonText="LEARN MORE PAGE VIDEO"
                buttonAction={handleLearnMoreClick}
            />
            <div className='event-calender-main-container'>
                <h1>Real Estate Auction Calendar</h1>
                {isScreenSmall ?
                    <>
                        <button className='filter-button' onClick={handleFilterButtonClick}>
                            View Filters
                        </button>
                        <FilterDrawer
                            isOpen={isDrawerOpen}
                            onClose={handleDrawerClose}
                            selectedState={selectedState}
                            setSelectedState={setSelectedState}
                            selectedAssetType={selectedAssetType}
                            setSelectedAssetType={setSelectedAssetType}
                            hidePastEvents={hidePastEvents}
                            setHidePastEvents={setHidePastEvents}
                        />
                    </>
                    :
                    <div className='event-calender-header-main'>
                        <div className="date-picker-wrapper">
                            <FontAwesomeIcon icon={faCalendar} className="calendar-icon" />
                            <DatePicker
                                className="event-calendar-date-picker"
                                selected={selectedDate}
                                onChange={date => setSelectedDate(date)}
                                dateFormat="MM/dd/yyyy"
                                placeholderText="MM/DD/YYYY"
                            />
                        </div>
                        {/* <select className='eventer-calender-select' value={selectedState} onChange={(e) => setSelectedState(e.target.value)}>
                            <option value="All States">All States</option>
                            {states.map(state => (
                                <option key={state.abbrev} value={state.name}>{state.name}</option>
                            ))}
                        </select> */}
                        <CustomDropdown options={stateNames} placeholder={"Select State"} Data={selectedState} setData={setSelectedState} /> 
                        <CustomDropdown options={["Foreclosure","REO Bank Owned","Short Sales"]}   Data={selectedAssetType} setData={setSelectedAssetType} placeholder={"Asset Type"} /> 
                        <CustomDropdown options={["In Person","Online","Direct Offer"]} Data={selectedAssetType} setData={setSelectedAssetType}  placeholder={"Buying Type"} /> 
                        <div className='event-calender-label-hide-past-event'>
                            <input
                                className='event-calender-hide-past-event'
                                type="checkbox"
                                checked={hidePastEvents}
                                onChange={(e) => setHidePastEvents(e.target.checked)}
                            /> Hide past events
                        </div>
                    </div>
                }
                {isScreenSmall ? events.map((event, index) => {
                    return <EventDetailsSmallerScreen key={index} event={event} />
                }) :
                    <table>
                        <thead>
                            <tr className='event-calender-table-header'>
                                <th>Dates - Type</th>
                                <th>Event Name</th>
                                <th>Asset Type - Total Assets</th>
                            </tr>
                        </thead>
                        <tbody>
                            {events.map((event, index) => (
                                <tr className='event-calender-table-data' key={index}>
                                    <td>{event.dateRange}</td>
                                    <td className='event-calender-event-name'>{event.eventName}</td>
                                    <td>{`${event.eventType} - ${event.assets}`}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                }
                {totalPages > 1 && (
                    <nav>
                        <ul className="pagination">
                            {renderPagination()}
                        </ul>
                    </nav>
                )}
                <div class="event-calendar-pagination-container">
                    <span class="event-calendar-pagination-info">Showing 1 - 25 of 443 results</span>
                    <div class="event-calendar-pagination-controls">
                        <button class="event-calendar-pagination-button" disabled>&laquo;</button>
                        <button class="event-calendar-pagination-button event-calendar-active">1</button>
                        <button class="event-calendar-pagination-button">2</button>
                        <button class="event-calendar-pagination-button">3</button>
                        <button class="event-calendar-pagination-button">4</button>
                        <button class="event-calendar-pagination-button">5</button>
                        <button class="event-calendar-pagination-button">...</button>
                        <button class="event-calendar-pagination-button">18</button>
                        <button class="event-calendar-pagination-button">&raquo;</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EventCalendar;