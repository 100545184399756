import React, { useState } from 'react';
import { Drawer, Button, TextField, Checkbox, FormControlLabel, Typography, IconButton, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import google from "../../assets/images/vihara/google.png"
import apple from "../../assets/images/vihara/appleImage.png"
import "./AlertDrawer.css"
const AlertDrawer = ({ isDrawerOpen, closeDrawer }) => {
    const [contentState, setContent] = useState("register");
    return (
        <Drawer
            anchor="right"
            open={isDrawerOpen}
            onClose={closeDrawer}
        >
            <Box
                sx={{ width: 350, padding: 3 }}
                role="presentation"
            >
                <div style={{ marginBottom: "20px" }}>
                    <IconButton style={{ position: "absolute", right: 8, top: 8 }} onClick={closeDrawer}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
                    <div className='alert-drawer-title'>
                        <FontAwesomeIcon style={{ color: "#2a69a3", fontSize: "30px" }} icon={faBell} /> Alerts
                    </div>
                </Box>
                {contentState === "register" ?
                    <><div className='alert-drawer-register-title'>
                        Register
                    </div>
                        <div className='register-subtitle-text' >
                            Register with Vihara to "favorite" properties, submit bids 24/7, and receive instant updates.
                        </div>
                        <input
                            className="alert-drawer-sign-up-input"
                            type="text"
                            placeholder='First Name'
                        />
                        <input
                            className="alert-drawer-sign-up-input"
                            type="text"
                            placeholder='Last Name'
                        />
                        <input
                            className="alert-drawer-sign-up-input"
                            type="text"
                            placeholder='Email'
                        />
                        <input
                            className="alert-drawer-sign-up-input"
                            type="password"
                            placeholder='Password'

                        />
                        <input
                            className="alert-drawer-sign-up-input"
                            placeholder='Business Phone'
                            type="tel"

                        />
                        <div className='alert-drawer-sign-up-subscribe-check-box'>
                            <input type="checkbox" />
                            <span>Subscribe for text messages / alerts</span>
                        </div>
                        <div className='sign-up-input-group'>
                            <button style={{ width: "100%" }} className="signup-button" type="submit">Register</button>
                        </div>
                        <div className='signup-links' style={{ display: "flex", gap: "5px", flexWrap: "wrap", borderBottom: "0px" }}>
                            <span> By clicking on Register, you accept the</span>
                            <span style={{ cursor: "pointer", color: "rgb(17, 139, 244)", fontWeight: "bold" }} onClick={() => window.location.href = "website-terms-of-use"} >
                                Vihara Terms & Conditions
                            </span> and
                            <span style={{ cursor: "pointer", color: "rgb(17, 139, 244)", fontWeight: "bold" }} onClick={() => window.location.href = "privacy-statement"} >
                                Privacy Policy
                            </span>.
                        </div>
                        <div className="signup-links">
                            <span>Already have an account?</span> <a className="signup-link" onClick={() => setContent("login")} style={{ textDecoration: "none", fontWeight: "bold", color: "rgb(17, 139, 244)" }}> Log in here.</a>
                        </div>
                    </> :
                    <>
                        <div className='alert-drawer-register-title'>
                            Sign In
                        </div>
                        <div className='register-subtitle-text' >
                            Sign In to access all your notifications, bids, offers, saved search histories, messages and more.
                        </div>
                        <input
                            className="alert-drawer-sign-up-input"
                            type="text"
                            placeholder='Email adress'
                        />
                        <input
                            className="alert-drawer-sign-up-input"
                            type="password"
                            placeholder='Password'

                        />
                        <div className='sign-up-input-group'>
                            <button style={{ width: "100%" }} className="signup-button" type="submit">Sign in</button>
                        </div>
                        <div className="signup-links">
                            <span>New user?</span> <a className="signup-link" onClick={() => setContent("register")} style={{ textDecoration: "none", fontWeight: "bold", color: "rgb(17, 139, 244)" }}> Register</a>
                        </div>
                    </>
                }
                <div className='sign-in-with-google-account-text'>Or sign in with your Google account</div>
                <div style={{ width: "100%" }} className='continue-with-google'>
                    <img src={google} />
                    <span>Continue with Google</span>
                </div>
                <div className='sign-in-with-google-account-text'>Or sign in with your Apple account</div>
                <div style={{ width: "100%" }} className='continue-with-google'>
                    <img src={apple} />
                    <span>Continue with Apple</span>
                </div>
            </Box>
        </Drawer>
    );
};

export default AlertDrawer;
