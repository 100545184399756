import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import './Login.css';
import NavBackground from '../../includes/navBackground';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setUser } from '../../../features/user/userSlice';
import Google from "../../../assets/images/vihara/google.png"
import Apple from "../../../assets/images/vihara/appleImage.png"
import { useEffect } from 'react';
import axios from 'axios';
const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [passwordError, setPasswordError] = useState('');
    const [isEmailValid,setIsValid]=useState(false);
    const [emailError, setEmailError] = useState('');
    const [submitError, setSubmitError] = useState(false);
    const [emailAndPhone, setEmailAndPhone] = useState(null);
    useEffect(() => {
        async function getAllEmail() {
            console.log("in property sample");
            try {
                const response = await axios.get("https://viharabackend.onrender.com/api/v1/user/getEmails");
                console.log(response.data.contacts);
                setEmailAndPhone(response.data.contacts);
            }
            catch (error) {
                console.log(error);
            }
        }
        getAllEmail();
    }, [])
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const googleAuth = () => {
        window.open(
            "https://viharabackend.onrender.com/auth/google/callback",
            "_self"
        );
    };
    const handlePassword = (e) => {
        setPasswordError('');
        setPassword(e.target.value);
    }
    const validatePassword=()=>{
        if(!password){
            setPasswordError('Password is required')
        }
    }
    const handleEmail = (e) => {
        setEmailError('');
        setEmail(e.target.value);
    }
    const validateEmail = (e) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email regex
        if(!email){
            setEmailError('Email is required');
            return;
        }
        if (!emailPattern.test(email)) {
            return;
        } else {
            setEmailError(''); 
            const existingRecord = emailAndPhone?.find(obj => obj.email === e.target.value);
            console.log(existingRecord);
            if (!existingRecord) {
                setEmailError('Authentication failed. Invalid email address, password combination.');
            }
            else{
                setIsValid(true);
            }
        }
    };
    const handleLogin = async (e) => {
        e.preventDefault();
        if (!password||!email) {
            if(!password){
                setPasswordError("Password is required");
            }
            if(!email){
                setEmailError("Email is required");
            }
            return;
        }
        try {
            const response = await axios.post('https://viharabackend.onrender.com/api/v1/user/login', {
                email,
                password
            });
            console.log(response.data.user);
            dispatch(setUser(response.data.user))
            localStorage.setItem('token', response.data.token);
            navigate("/");
        } catch (error) {
            console.error("Login failed", error);
            if (error.response.data.error === "Invalid Email") {
                // setEmailError("Incorrect Email");
                setSubmitError(true);
            }
            if (error.response.data.error === "Invalid Password") {
                // setPasswordError("Incorrect Password");
                setSubmitError(true);
            }
        }
    };

    return (
        <div className="login-container">
            <Helmet>
                <title>Login to Vihara AI - Access Your Real Estate Auction Account</title>
                <meta name="description" content="Access your Vihara AI account to participate in AI-driven real estate auctions. Log in now for property listings and bids." />
                <meta name="keywords" content="Login Vihara AI, account access, real estate auctions, user login" />
            </Helmet>
            <NavBackground />
            <div className="login-signup-container">
                <div className="login-form-container">
                    {/* <div className="login-logo"><img src={Images.logoOnDark} alt="Vihara AI Logo" /></div> */}
                    <div className='welcome-to-vihara-auth-login-page'>Welcome to Vihara!</div>
                    <div className='sign-in-details-sign-in-page'>
                        Sign in to access alerts, bids, offers, saved properties, messages and more.
                        Don’t have an account? <span onClick={() => window.location.href = "/sign-up"} style={{ cursor: "pointer", color: "#118bF4", textDecoration: "underline" }}>Sign Up</span></div>
                    {submitError === true ? <div className='authentication-faild-on-submit'>Authentication failed. Invalid email address, password combination.</div> : ''}
                    <form className="login-form" onSubmit={handleLogin}>
                        <div  className="login-page-input-container">
                            <label style={{ width: "100%" }} className='sign-up-input-label'><span>Email:</span> <span>*</span> </label>
                            <div style={{marginBottom:"0px"}} className={`password-container ${emailError ? 'input-error' : ''}`}>
                                <input
                                    value={email}
                                    onChange={handleEmail}
                                    placeholder=""
                                    onBlur={validateEmail}
                                />

                                <FontAwesomeIcon
                                    icon={faCheck}
                                    style={{display:isEmailValid?"block":"none"}}
                                    className="password-icon"
                                />
                            </div>
                        </div>
                        <div className='email-error-text'>{emailError}</div>
                        <div  className="login-page-input-container">
                            <label style={{ width: "100%" }} className='sign-up-input-label'>
                                <span>Password:</span> <span>*</span>
                            </label>
                            <div style={{marginBottom:"0px"}} className={`password-container ${passwordError ? 'input-error' : ''}`}>
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    value={password}
                                    onChange={handlePassword}
                                    onBlur={validatePassword}
                                    placeholder=""
                                />
                                <FontAwesomeIcon
                                    icon={showPassword ? faEye : faEyeSlash}
                                    className="password-icon"
                                    onClick={toggleShowPassword}
                                />
                            </div>
                        </div>
                        <div className='email-error-text'>{passwordError}</div>
                        <button className="login-button" type="submit">Sign in</button>
                        <div className='gray-line-sign-page'></div>
                    </form>
                    <div style={{ marginTop: "10px", textAlign: "left" }} className='sign-in-with-google-account-text'>Or sign in with your Google account </div>
                    <div className='continue-with-google-sign-in' onClick={googleAuth} >
                        <img src={Google} />
                        <span>Continue with Google</span>
                    </div>
                    <div style={{ marginTop: "10px", textAlign: "left"}} className='sign-in-with-google-account-text'>Or sign in with your Apple account </div>
                    <div className='continue-with-google-sign-in' >
                        <img src={Apple} />
                        <span>Continue with Apple</span>
                    </div>
                    <div className="login-links">
                        <a className="login-link" href="/sign-up">Sign Up</a>
                        <a className="login-link" href="/reset-password">Forgot Password</a>
                    </div>
                </div>
                <div className="login-image-container">
                    {/* Image or Background set via CSS */}
                </div>
            </div>

        </div>
    );
};

export default Login;
